<template>
	<div
		class="swiper slider slider--promos detail-promos-slider"
		@touchstart="onSlideTouchStart"
		@touchend="onSlideTouchEnd"
		@click="navigateViaBullets($event)"
	>
		<div class="swiper-wrapper slider__wrapper detail-promos-slider__wrapper">
			<swiper
				:modules="modules"
				:pagination="pagination"
				:navigation="false"
				:loop="false"
				:space-between="22"
				:slides-per-view="1"
				:autoplay="autoplay"
				:allow-touch-move="false"
				@swiper="onSwiper"
				@slideChange="onSlideChange"
				@slideChangeTransitionEnd="afterSlideTransition"
			>
				<swiper-slide
					v-for="slide in slides"
				>
					<button
						class="detail-promos-slider__btn detail-promos-slider__btn--prev"
						@click="prevSlide"
					></button>
					<div
						class="detail-promos-slider__text static app-container"
						v-html="slide.detailText"
                        v-if="slide.detailText.length"
					></div>
                    <img
                        class="detail-promos-slider__img"
                        v-if="slide.detailImg.length"
                        :src="slide.detailImg"
                        alt=""
                    >
					<button
						class="detail-promos-slider__btn detail-promos-slider__btn--next"
						@click="nextSlide"
					></button>
				</swiper-slide>
			</swiper>
		</div>

        <router-link
            v-if="link"
            class="detail-promos-slider__link btn"
            :to="link"
        >
            Подробнее
        </router-link>
	</div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper';

export default {
	props: {
		slides: {
			type: Array,
		},
        link: {
            type: String
        },
	},
	data: () => ({
		modules: [Pagination, Navigation, Autoplay],
		swiperObj: null,
		autoplay: {
			delay: 5000,
			stopOnLastSlide: true,
			disableOnInteraction: false,
		},
		pagination: {
			renderBullet: function () {
				return '<span class="swiper-pagination-bullet"><span class="swiper-pagination-bullet__bg"></span></span>';
			}
		},
		currentAnimation: null,
		autoCloseTimeout: null,
	}),
	methods: {
		onSwiper: function(swiper) {
			this.swiperObj = swiper;
			this.afterSlideTransition();
		},
		onSlideChange: function() {
			this.currentAnimation.pause();
			clearTimeout(this.autoCloseTimeout);
		},
		onSlideTouchStart: function() {
			this.swiperObj.autoplay.pause();
			this.currentAnimation.pause();
		},
		onSlideTouchEnd: function() {
			this.swiperObj.autoplay.resume();
			this.currentAnimation.play();
		},
		prevSlide: function() {
			if (this.swiperObj.activeIndex == 0)
				this.loadingProgress = 0;
			else
				this.swiperObj.slidePrev();
		},
		nextSlide: function() {
			if (this.swiperObj.activeIndex == (this.swiperObj.slides.length - 1))
				this.$emit('allSlidesShown', true);
			else
				this.swiperObj.slideNext();
		},
		afterSlideTransition: function() {
			let ref = this,
				bullets = ref.$el.querySelectorAll('.swiper-pagination-bullet'),
				activeBullet = ref.$el.querySelector('.swiper-pagination-bullet-active'),
				activeBulletBg = activeBullet.querySelector('.swiper-pagination-bullet__bg'),
				activeBulletRect = activeBullet.getBoundingClientRect(),
				activeBulletWidth = activeBulletRect.right - activeBulletRect.left;

			if (ref.currentAnimation !== null)
				ref.currentAnimation.cancel();

			bullets.forEach(function(bullet, index) {
				let activeSlideIndex = ref.swiperObj.activeIndex,
					bulletBg = bullet.querySelector('.swiper-pagination-bullet__bg');

				if (activeSlideIndex > index)
					bulletBg.style.width = '100%';
				else if (activeSlideIndex < index)
					bulletBg.style.width = '0';
			});

			ref.currentAnimation = activeBulletBg.animate([
				{
					width: 0,
				},
				{
					width: activeBulletWidth + 'px',
				},
			], {
				duration: 5000,
				easing: 'linear',
				iterations: 1,
				fill: 'forwards',
			});  
			
			if (ref.swiperObj.activeIndex == (ref.swiperObj.slides.length - 1)) {
				ref.autoCloseTimeout = setTimeout(function() {
					ref.$emit('allSlidesShown', true);
				}, 5000);
			}
		},
		navigateViaBullets: function(e) {
			let bullet = null,
				index = null;

			if (e.target.classList.contains('swiper-pagination-bullet')) {
				bullet = e.target;
			} else if (e.target.classList.contains('swiper-pagination-bullet__bg')) {
				bullet = e.target.parentNode;
			}

			if (bullet !== null) {
				let allBullets = bullet.parentNode.children, 
					allBulletsLength = allBullets.length;
					
				while (allBulletsLength--) {
					if (allBullets[allBulletsLength] == bullet) 
						index = allBulletsLength;
				}

				if (index !== null) {
					this.swiperObj.slideTo(index);
				}
			}
		}
	},
	emits: ['allSlidesShown'],
	components: {
		Swiper,
		SwiperSlide
	}
}
</script>