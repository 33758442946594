<template>
  <section class="template intro">
    <div class="app-container">
      <div class="intro__content">
        <img class="intro__head-logo" src="/img/logo-intro.svg" alt="Логотип «Все Дома»" width="317" height="91">

        <div class="intro__confirm-instructions" v-if="timer > 0">
          <p>
            Подтвердите владение номером<br>
            <span class="intro__confirm-instructions-phone" id="phone">+7&nbsp;&nbsp;{{ phone }}</span>
          </p>
          <p>
            Позвоните нашему боту.<br> 
            Вызов будет сброшен, разговаривать не придется.<br>  
            Это бесплатно.
          </p>
        </div>

        <div class="intro__confirm-instructions intro__confirm-instructions--expired" v-if="timer === 0">
          <p>
            Время истекло.<br> 
            Сгенерируйте номер и позвоните снова.
          </p>
        </div>

        <button class="intro__recreate-phone-btn btn btn--accent" v-if="timer === 0" @click="recreatePhone">Сгенерировать номер</button>

        <!-- не уверен, как в Vue прописывать ссылки на tel, допиши, пожалуйста: -->
        <router-link
          class="intro__confirm-phone"
          :class="timer === 0 ? ' intro__confirm-phone--expired' : ''"
          :to="timer > 0 ? 'tel:+7{{ generatedCallPhone }}' : ''"
        >
          +7&nbsp;&nbsp;{{ timer > 0 ? generatedCallPhone : '...  ... ..  -  ..' }}
        </router-link>

        <div class="intro__confirm-bottom">
          <router-link class="intro__confirm-cancel-btn btn" :class="timer === 0 ? ' btn--disabled' : ''" to="tel:+7{{ generatedCallPhone }}">Позвонить</router-link>
          
          <p class="intro__confirm-timer-wrapper">
            <span class="intro__confirm-timer-text">Ожидаем звонок </span>
            <span class="intro__confirm-timer-value">{{ timerFormatted }}</span>
          </p>

          <router-link class="intro__confirm-cancel-btn btn btn--non-target" to="/intro">Отмена</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  data: () => ({
    timer: 179,
    callPhone: '921-947-97-@@',
  }),
  computed: {
    phone() {
      return (this.$route.query.phone).replace(/  +/g, '  ').replace(/ ([^ ]*)$/, ' -  $1');
    },
    timerComputed: {
      get() {
        return this.timer;
      },
      set(value) {
        this.timer = value;
      }
    },
    timerFormatted() {
      return this.formatTime(this.timerComputed);
    },
    generatedCallPhone() {
      return this.formatCallPhone(this.callPhone);
    }
  },
  methods: {
    countdown() {
      if (this.timerComputed > 0) {
        setTimeout(() => {
          this.timerComputed -= 1;
          this.countdown();
        }, 1000);
      }
    },
    formatTime: function(timer) {
      let secNum = parseInt(timer, 10),
          minutes = Math.floor(secNum / 60),
          seconds = secNum - (minutes * 60);

      if (minutes < 10)
        minutes = '0' + minutes;
      if (seconds < 10)
        seconds = '0' + seconds;

      return minutes + ':' + seconds;
    },
    formatCallPhone: function(phone) {
      let formattedPhone = phone,
          randomNumberPart = Math.floor(Math.random() * 90 + 10); //TODO: рандомную генерацию последних цифр заменить на приходящую с бэкенда

      formattedPhone = ((formattedPhone).replace(/-+/g, '  ').replace(/ ([^ ]*)$/, ' -  $1')).replace(/@@([^ ]*)$/, randomNumberPart + '$1');

      return formattedPhone;
    },
    recreatePhone: function() { //TODO: доделать создание номера и сброс таймера:
      console.log('reset timer and recreate a phone number');
    }
  },
  created () {
    this.countdown();
  },
}
</script>