<template>
  <div class="app-container">
    <section class="catalog">
      <router-link
        class="catalog__section"
        v-for="item in sections"
        :to="item.link"
        :style="`background-image: url(` + item.img + `)`"
      >
        <span class="catalog__section-name">
          {{ item.name }}
        </span>
      </router-link>
    </section>
  </div>
</template>
<script>
export default {
  data: () => ({
    
  }),
  props: {
    sections: {
      type: Array
    },
  },
}
</script>