<template>
  <footer class="footer">
    <div class="app-container">
      <ul class="footer__menu">
        <li class="footer__menu-item" v-for="item in menuItems">
          <!-- может, стоит зарефакторить условие, когда выводить ссылку, а когда нет, -->
          <!-- в сети пишут, что такое решается конструкцией вида <component :is="condition ? 'div' : 'span'"></component> -->
          <!-- но с router-link такой фокус у меня не прошел (с <a> работало) -->
          <router-link
            v-if="item.link !== this.$router.currentRoute.value.path && item.map !== true"
            class="footer__menu-link"
            :to="item.link"
          >
            <img class="footer__menu-link-icon" :src="item.icon" :alt="item.title" width="26" height="26">
            <span class="footer__menu-link-text">{{ item.title }}</span>
          </router-link>

          <span
            v-if="item.link === this.$router.currentRoute.value.path && item.map !== true"
            class="footer__menu-link footer__menu-link--active"
          >
            <img class="footer__menu-link-icon" :src="item.icon" :alt="item.title" width="26" height="26">
            <span class="footer__menu-link-text">{{ item.title }}</span>
          </span>

          <!-- Это тоже можно как-то оптимизировать, наверное: -->
          <button
            v-if="item.map === true"
            class="footer__menu-link"
            @click="callMap"
          >
            <img class="footer__menu-link-icon" :src="item.icon" :alt="item.title" width="26" height="26">
            <span class="footer__menu-link-text">{{ item.title }}</span>
          </button>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
export default {
  data: () => ({
    menuItems: [
      {
        title: 'Домой',
        link: '/',
        icon: '/img/icon-menu-home.svg',
      },
      {
        title: 'Каталог',
        link: '/catalog',
        icon: '/img/icon-menu-catalog.svg',
      },
      {
        title: 'Адреса',
        // link: '/map',
        map: true,
        icon: '/img/icon-menu-address.svg',
      },
      {
        title: 'Активность',
        link: '/activity',
        icon: '/img/icon-menu-activity.svg',
      },
      {
        title: 'Профиль',
        link: '/profile',
        icon: '/img/icon-menu-profile.svg',
      },
    ],
  }),
  emits: ['call-map'],
  methods: {
    callMap: function() {
        this.$emit('call-map', true);
    },
  },
}
</script>