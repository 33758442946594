<template>
    <svg class="donut-chart" height="160" width="160" viewBox="0 0 160 160">
		<g v-for="(value, index) in sortedValues">
			<circle
                :cx="cx"
                :cy="cy"
                :r="radius"
                :stroke="colors[index]"
                :stroke-width="strokeWidth"
                :stroke-dasharray="adjustedCircumference"
                :stroke-dashoffset="calculateStrokeDashOffset(value, circumference)"
                fill="transparent"
                :transform="returnCircleTransformValue(index)"
                stroke-linecap="round"
            />
		</g>
	</svg>
</template>

<script>
export default {
    data: () => ({
        angleOffset: -90,
        chartData: [],
        colors: [],
        cx: 80,
        cy: 80,		      
        radius: 60,
        sortedValues: [],
        strokeWidth: 30, //3 ~ 30
    }),
    props: {
        statsData: {
            type: Object
        },
    },
    computed: {
        initialValues() {
            let array = [];

            for (let category in this.statsData.spent.categories) {
                this.colors.push(this.statsData.spent.categories[category].color);
                array.push(this.statsData.spent.categories[category].sum);
            }

            return array;
        },
        sortInitialValues() {           
            // return this.sortedValues = this.initialValues.sort((a,b) => b - a)            
            // return this.sortedValues = this.initialValues;
            return this.sortedValues = this.distributeRangeGivenMinimum(this.initialValues, 0.1111);
        },
        calculateChartData() {
            this.sortedValues.forEach((dataVal, index) => {
                let data = {
                    degrees: this.angleOffset,
                }

                this.chartData.push(data)
                this.angleOffset = this.dataPercentage(dataVal) * 360 + this.angleOffset;   
            })
        },
        dataTotal() {
            return this.sortedValues.reduce((acc, val) => acc + val);
        },
        circumference() {
            return 2 * Math.PI * this.radius;
        },
        // adjust the circumference to add small white gaps
        adjustedCircumference() {
            return this.circumference - 4; // 0 ~ 15
        },
    },
    methods: {
        calculateStrokeDashOffset(dataVal, circumference) {
            let strokeDiff = this.dataPercentage(dataVal) * circumference;

            return circumference - strokeDiff + 30; //5 ~ 30
        },
        degreesToRadians(angle) {
            return angle * (Math.PI / 180);
        },
        dataPercentage(dataVal) {
            return dataVal / this.dataTotal;
        },
        returnCircleTransformValue(index) {
            return `rotate(${this.chartData[index].degrees}, ${this.cx}, ${this.cy})`;
        },
        distributeRangeGivenMinimum(range, min) {
            let sum = 0,
                percents = [],
                newRange = [],
                amtToSubtract = 0,
                timesUnderMin = 0;

            for (let i = 0; i < range.length; i++) {
                sum += range[i];
            }

            for (let i = 0; i < range.length; i++) {
                percents.push(range[i] / sum);
            }

            for (let j = 0; j < range.length; j++) {
                if (percents[j] < min) {
                    amtToSubtract += (min - percents[j]);
                    timesUnderMin++;
                    percents[j] = min;
                }
            }

            let timesOverMin = percents.length - timesUnderMin;

            do {
                var prevTimesOverMin = timesOverMin,
                    distSubAmt = amtToSubtract / timesOverMin;

                for (let k = 0; k < range.length; k++) {
                    if (percents[k] > min && percents[k] - distSubAmt < min) {
                        amtToSubtract -= (percents[k] - min);
                        timesOverMin--;
                        percents[k] = min;
                    }
                }
            } while (timesOverMin != prevTimesOverMin);

            if (timesOverMin > 0) {
                for (let l = 0; l < range.length; l++) {
                    if (percents[l] > min) {
                        percents[l] = percents[l] - (amtToSubtract / timesOverMin);
                    }
                }
            }

            // let perSum = 0;
            // for (let x = 0; x < range.length; x++) {
            //     perSum += percents[x];
            // }
            // console.log(perSum);

            // return percents;

            for (let m = 0; m < range.length; m++) {
                newRange[m] = Math.round(percents[m] * sum);
            }

            return newRange;
        },
    },
    mounted() {
        this.sortInitialValues;
        this.calculateChartData;
    },
    watch: {
        statsData: function() {
            this.chartData = [];
            this.colors = [];
            this.sortedValues = [];

            this.sortInitialValues;
            this.calculateChartData;
        },
    },
}
</script>