<template>
    <section class="offline-qr">
        <div class="offline-qr__content">
            <div class="offline-qr__text-block offline-qr__text-block--top">
                <p class="offline-qr__text-block-text">
                    Отсутствует <br>
                    соединение с интернетом
                </p>
            </div>

            <div class="offline-qr__main-block">
                <div class="offline-qr__main-block-logo-wrapper">
                    <img
                        class="offline-qr__main-block-logo"
                        alt="Логотип «Все Дома»"
                        src="/img/logo-offline.svg"
                    >
                </div>

                <button
                    class="offline-qr__btn offline-qr__btn--open"
                    @click="qrShown = !qrShown"
                ></button>

                <div class="offline-qr__main-block-inner" v-if="qrShown">
                    <div class="offline-qr__main-block-qr-wrapper">
                        <img
                            class="offline-qr__main-block-qr"
                            alt="QR-код"
                            src="/img/qr.png"
                        >
                    </div>

                    <button
                        class="offline-qr__btn offline-qr__btn--close"
                        @click="qrShown = !qrShown"
                    >Свернуть</button>
                </div>
            </div>

            <div class="offline-qr__text-block offline-qr__text-block--bottom">
                <p class="offline-qr__text-block-text" v-if="!qrShown">
                    Нажмите на кнопку, <br>
                    чтобы предъявить QR код на кассе
                </p>
                <p class="offline-qr__text-block-text" v-if="qrShown">
                    Предъявите QR код на кассе
                </p>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data: () => ({
        qrShown: false,
    }),
}
</script>