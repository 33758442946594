<template>
  <main class="main main--intro">
    <IntroReg></IntroReg>
  </main>
</template>

<script>
import IntroReg from "../components/app/IntroReg";

export default {
  data: () => ({

  }),
  components: {IntroReg}
}
</script>
