<template>
  <form
    class="form form--review"
    :class="formClass"
    action="#"
    method="post"
    @submit="validateForm"
    ref="form"
  >
    <label
      v-for="field in formFields"
      :key="field.model"
      class="form__label"
      :class="field.type === 'file' ? 'form__label--review-file' : (field.type === 'textarea' ? 'form__label--textarea' : '')"
    >
      <span
        v-if="field.type !== 'file' && field.type !== 'textarea'"
        class="form__label-text"
      >
        {{ field.textName }}
      </span>

      <div
        class="form__input-wrapper"
        v-if="field.type !== 'file' && field.type !== 'textarea'"
      >
        <input class="form__input"
          :type="field.type === 'email' ? 'email' : (field.type === 'phone' ? 'tel' : 'text')"
          :v-model="field.model"
          :placeholder="field.placeholder"
          :data-type="field.type"
          @input="clearError($event, field.model)"
          v-mask="field.type === 'date' ? '##.##.####' : (field.type === 'phone' ? '+7 ### ### ## ##' : '')"
          :value="field.type === 'phone' ? (userPhone.length ? userPhone : '') : ''"
        >
        <span class="form__input-error" v-if="this[field.model + 'Error']">
          {{ field.errorText }}
        </span>
      </div>

      <div
        class="form__textarea-wrapper"
        v-if="field.type === 'textarea'"
      >
        <span class="form__textarea-heading">{{ field.textName }}</span>

        <div class="form__textarea-call-btn-container">
          <button
            class="form__textarea-call-btn btn btn--accent"
            @click="this[field.model + 'AreaOn'] = !this[field.model + 'AreaOn']"
          >
            {{ field.placeholder }}
          </button>
        </div>
      </div>

      <input
        class="form__input form__input--file hidden-input"
        v-if="field.type === 'file'"
        :v-model="field.model"
        type="file"
        @change="showFileName($event, field.model)"
        :data-type="field.type"
      >
      <span
        class="form__review-file-text"
        v-if="field.type === 'file'"
      >
        {{ this[field.model + 'FileName'] ? this[field.model + 'FileName'] : field.placeholder }}
      </span>
    </label>

    <div
      class="form__textarea-edit-screen-wrapper"
      :class="this[field.model + 'AreaOn'] ? 'form__textarea-edit-screen-wrapper--open' : ''"
      v-for="field in formFields"
    >
      <div
        class="form__textarea-edit-screen"
        v-if="field.type === 'textarea' && this[field.model + 'AreaOn']"
      >
        <textarea
          class="form__textarea"
          :v-model="field.model"
          :data-type="field.type"
        ></textarea>

        <button
          class="form__textarea-close-edit btn btn--accent"
          @click="this[field.model + 'AreaOn'] = !this[field.model + 'AreaOn']"
        >
          Сохранить изменения
        </button>
      </div>
    </div>

    <div class="form__buttons">
      <button
        class="form__button btn"
        type="submit"
      >
        Отправить
      </button>
    </div>
  </form>
</template>
<script>
import {mask as maskDirective } from 'vue-the-mask';
import $ from 'jquery';

export default {
  props: {
    formClass: {
      type: String
    },
    userPhone: {
      type: String
    },
    formFields: {
      type: Object,
    },
  },
  data: function() {
    let dataObj = {};

    $.each(this.formFields, function(key, value) {
      if (value.type !== 'file') {
        dataObj[key] = '';

        if (value.isRequired)
          dataObj[key + 'Error'] = false;

        if (value.type === 'textarea')
          dataObj[key + 'AreaOn'] = false;
      } else {
        dataObj[key] = false;
        dataObj[key + 'FileName'] = false;
      }
    });

    return dataObj;
  },
  directives: {
    mask: function (e, b) {
      if (!b.value) {
        return;
      }

      maskDirective(e, b);
    }
  },
  methods: {
    clearError: function(e, fieldName) {
      this[fieldName + 'Error'] = false;
    },
    formatPhone: function(phone) {
      let phoneRaw = phone,
          formatted = '',
          chunkSize = 3,
          chunks = 4;

      for (chunks; chunks > 0; chunks--) {
        if (chunks <= 2)
          chunkSize = 2;

        formatted += phoneRaw.substring(chunkSize, 0) + '  ';
        phoneRaw = phoneRaw.substring(phoneRaw.length, chunkSize);
      }
      return formatted;
    },
    showFileName(e, fieldName) {
      let fileName = e.target.files[0].name;

      this[fieldName + 'FileName'] = fileName;
    },
    validateForm(e) {
      e.preventDefault();
      let self = this,
          valid = true,
          emailFields = e.target.querySelectorAll('[data-type="email"]'),
          dateFields = e.target.querySelectorAll('[data-type="date"]'),
          phoneFields = e.target.querySelectorAll('[data-type="phone"]');

      emailFields.forEach(function (field) {
        let fieldName = field.getAttribute('v-model');

        if (self.formFields[fieldName].isRequired) {
          if (!self.validateEmail(field.value)) {
            valid = false;
            self[fieldName + 'Error'] = true;
          }
        }
      });

      dateFields.forEach(function (field) {
        let fieldName = field.getAttribute('v-model');

        if (self.formFields[fieldName].isRequired) {
          if (!self.validateDate(field.value)) {
            valid = false;
            self[fieldName + 'Error'] = true;
          }
        }
      });

      phoneFields.forEach(function (field) {
        let fieldName = field.getAttribute('v-model');

        if (self.formFields[fieldName].isRequired) {
          if (!self.validatePhone(field.value)) {
            valid = false;
            self[fieldName + 'Error'] = true;
          }
        }
      });

      if (valid)
        e.target.submit();
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    validateDate(date) {
      //31 февраля проходит, надо доработать:
      return /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g.test(date);
    },
    validatePhone(phone) {
      if (phone.length === 16 && /^\d+$/.test(phone))
        return true;
      else
        return false;
    },
  },
  computed: {
    phoneFormatted() {
      return this.formatPhone(this.userPhone);
    }
  },
  mounted() {
    let self = this;

    $.each(self.formFields, function(key, value) {
      if (value.type === 'list') {
        let degreesCounter = 0,
            blockId = '#' + value.model + 'Select';
            // placeholder = value.placeholder;

        self.select2 = $(blockId)
        .find('select')
        .select2({
          // placeholder: placeholder,
          width: '100%',
          minimumResultsForSearch: -1,
          dropdownParent: $(self.$el.querySelector(blockId)),
        }).on('select2:open select2:close', e => {
          let type = e.type,
              arrow = $(e.currentTarget).closest('.form__input--select').find('.select2-selection__arrow'),
              rotateDegrees = {
                'select2:open': 270,
                'select2:close': 90,
              };

          degreesCounter += rotateDegrees[type];
          arrow.css('transform', 'rotate(' + degreesCounter + 'deg)');
        }).val('none').trigger('change');;
      }
    });
  }
}
</script>