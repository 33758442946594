<template>
  <main class="main main--intro">
    <IntroTemplate></IntroTemplate>
  </main>
</template>

<script>
import IntroTemplate from "../components/app/IntroTemplate";

export default {
  data: () => ({

  }),
  components: {IntroTemplate}
}
</script>
