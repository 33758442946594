<template>
    <div
        class="activity-menu"
        @touchstart="followFingerStart"
        @touchmove="followFinger($event)"
        v-touch:swipe="swipeHandler"
        ref="activityMenu"
    >
        <ul
            class="activity-menu__menu"
            :class="additionalClass"
        >
            <li
                class="activity-menu__item"
                v-for="(item, index) in items"
                :key="item.id"
            >
                <input
                    class="activity-menu__item-radio hidden-input"
                    type="radio"
                    name="activityMenu"
                    :value="item.id"
                    :id="item.id"
                    :checked="currentFilter === item.id ? true : (index === 0 ? true : false)"
                    @change="moveIndicator($event, item.id)"
                >
                <label
                    class="activity-menu__btn"
                    :for="item.id"
                >
                    <span class="activity-menu__btn-text">{{ item.text }}</span>
                </label>
            </li>
        </ul>

        <div class="activity-menu__indicator"></div>
    </div>
</template>
<script>
export default {
    data: () => ({
        elementToClick: false,
        lastMoveSuccessfull: false,
    }),
    props: {
        additionalClass: {
            type: String
        },
        currentFilter: {
            type: String
        },
        items: {
            type: Array
        },
    },
    methods: {
        moveIndicator: function(e = false, id = false) {
            let indicator = this.$el.querySelector('.activity-menu__indicator'),
                indicatorRect = indicator.getBoundingClientRect(),
                menuBlock = this.$el.querySelector('.activity-menu__menu'),
                windowWidth = window. innerWidth,
                indicatorLeft,
                indicatorTop,
                checkedOptionInput,
                checkedOptionLabel,
                menuRect,
                labelRect,
                labelLeft,
                labelTop;

            if (id) {
                this.updateFilter(id);

                checkedOptionInput = this.$el.querySelector('.activity-menu__item-radio[id="' + id + '"]');
            } else {
                checkedOptionInput = this.$el.querySelector('.activity-menu__item-radio:checked');
            }

            setTimeout(function() {
                checkedOptionLabel = checkedOptionInput.parentNode.querySelector('.activity-menu__btn-text');
                menuRect = menuBlock.getBoundingClientRect();
                labelRect = checkedOptionLabel.getBoundingClientRect();
                labelTop = labelRect.top - menuRect.top;
                indicatorTop = indicatorRect.top - menuRect.top;
                labelLeft = labelRect.left - menuRect.left;
                indicatorLeft = indicatorRect.left - menuRect.left;

                indicator.animate([
                    {
                        top: indicatorTop + 'px',
                        left: (indicatorLeft + 20) + 'px',
                        width: indicatorRect.width + 'px',
                        height: indicatorRect.height + 'px',
                    },
                    {
                        top: (labelTop - 7) + 'px',
                        left: (labelLeft + 11) + 'px',
                        width: (labelRect.width + 18) + 'px',
                        height: (labelRect.height + 14) + 'px',
                    },
                ], {
                    duration: 200,
                    easing: 'ease-in-out',
                    iterations: 1,
                    fill: 'forwards',
                });
            }, 1);
        },
        followFinger: function(e) {
            let clientX = e.clientX || e.changedTouches[0].clientX,
                clientY = e.clientY || e.changedTouches[0].clientY,
                currentElement = document.elementFromPoint(clientX, clientY),
                input,
                element;

            if (currentElement.classList.contains('activity-menu__btn') || currentElement.parentNode.classList.contains('activity-menu__btn')) {
                if (currentElement.parentNode.classList.contains('activity-menu__btn')) {
                    this.elementToClick = currentElement.parentNode;
                } else {
                    this.elementToClick = currentElement;
                }

                input = this.elementToClick.previousElementSibling;
            }

            if (typeof this.elementToClick !== 'boolean' && typeof input === 'object' && !input.checked) {
                element = this.elementToClick;
                element.click();
                this.elementToClick = false;
                this.lastMoveSuccessfull = true;
            }
        },
        followFingerStart: function() {
            this.lastMoveSuccessfull = false;
        },
        swipeHandler: function(e) {
            if (!this.lastMoveSuccessfull) {;
                let menu = this.$refs.activityMenu,
                    allOptions = menu.querySelectorAll('input[type="radio"]'),
                    checkedOption = menu.querySelector('input[type="radio"]:checked'),
                    newOption,
                    newIndex;

                if (e === 'right') {
                    newIndex = +1;
                } else if (e === 'left') {
                    newIndex = -1;
                } else if (e === 'top') {
                    newIndex = -3;
                } else if (e === 'bottom') {
                    newIndex = +3;
                }

                for (let i = 0; i < allOptions.length; i++) {
                    if (allOptions[i] == checkedOption) {
                        newOption = allOptions[i+newIndex];
                        break;
                    }
                }

                if (newOption !== undefined) {
                    newOption.nextElementSibling.click();
                }
            }
        },
        updateFilter: function(id) {
            this.$emit('set-menu', id);
        },
    },
    emits: ['set-menu'],
    mounted() {
        this.moveIndicator();
    },
}
</script>