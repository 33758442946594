<template>
    <form
        class="form"
        :class="formClass"
        action="#"
        method="post"
        @submit="validateForm"
        ref="form"
    >
        <div class="form__head">
            <span class="form__head-name">{{ userName }}</span>
            <span class="form__head-phone">+7&nbsp;{{ phoneFormatted }}</span>
        </div>

        <label
            v-for="field in formFields"
            :key="field.model"
            class="form__label"
            :class="field.type === 'file' ? (this[field.model + 'Preview'] ? 'form__label--file form__label--file-uploaded' : 'form__label--file') : field.type === 'list' ? 'form__label--list' : ''"
        >
                <span
                v-if="field.type !== 'file'"
                class="form__label-text"
            >
                {{ field.textName }}
            </span>

            <div
                class="form__input-wrapper"
                v-if="field.type !== 'file' && field.type !== 'list'"
            >
                <input class="form__input"
                    :type="field.type === 'email' ? 'email' : (field.type === 'phone' ? 'tel' : 'text')"
                    :v-model="field.model"
                    :placeholder="field.placeholder"
                    :data-type="field.type"
                    @input="clearError($event, field.model)"
                    v-mask="field.type === 'date' ? '##.##.####' : (field.type === 'phone' ? '+7 ### ### ## ##' : '')"
                >
                <span class="form__input-error" v-if="this[field.model + 'Error']">
                    {{ field.errorText }}
                </span>
            </div>

            <div class="form__input-wrapper"
                v-if="field.type === 'list'"
                :id="field.model + 'Select'"
            >
                <div class="form__selects-hidden hidden-input">
                    <input
                        v-for="(item, key) in field.items"
                        :key="item.id"
                        type="radio"
                        :v-model="field.model"
                        :value="item.id"
                        :checked="key == 0 ? 'checked' : ''"
                    >
                </div>

                <div class="form__selects-visible" @click="togglePseudoSelect">
                    <span class="form__selects-current form__selects-current--awaits-interaction">
                        {{ field.items[0].text }}
                    </span>
                    <span class="form__selects-current-arrow"></span>

                    <div class="form__selects-list">
                        <span
                            class="form__selects-item"
                            :class="key == 0 ? 'form__selects-item--hidden' : ''"
                            v-for="(item, key) in field.items"
                            :key="item.id"
                            @click="selectItem($event, item.id)"
                        >
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>

            <img 
                class="form__file-input-preview"
                :src="this[field.model + 'Preview']"
                alt="Превью загруженной фотографии"
                v-if="field.type === 'file' && this[field.model + 'Preview']"
            >
            <input
                class="form__input form__input--file hidden-input"
                v-if="field.type === 'file'"
                :v-model="field.model"
                type="file"
                accept="image/png, image/jpeg"
                @change="showPicture($event, field.model)"
                :data-type="field.type"
            >
        </label>

        <div class="form__buttons">
            <button
                class="form__button btn"
                :class="submitBtnIsAccent ? 'btn--accent' : ''"
                type="submit"
            >
                Сохранить изменения
            </button>

            <router-link
                class="form__button btn btn--non-target"
                to="/intro"
                v-if="showReturnBtn"
            >
                Отмена
            </router-link>
        </div>
    </form>
</template>
<script>
import {mask as maskDirective } from 'vue-the-mask';
// import Select2 from 'vue3-select2-component';
import $ from 'jquery';

export default {
    props: {
        formClass: {
            type: String
        },
        // наверное, при регистрации стоит передавать телефон через params, а не не props, как передается номер после ввода из /intro в /intro-confirm
        // пока оставил так, потому что его передачу с первого экрана сюда будешь делать сам по-своему, сейчас передается hard-coded телефон из компонента уровнем выше чисто для верстки
        userPhone: {
            type: String
        },
        userName: {
            type: String,
            default: 'Аккаунт',
        },
        formFields: {
            type: Object,
        },
        showReturnBtn: {
            type: Boolean,
        },
        submitBtnIsAccent: {
            type: Boolean,
        },
    },
    data: function() {
        let dataObj = {};

        $.each(this.formFields, function(key, value) {
            if (value.type !== 'file') {
                dataObj[key] = '';

                if (value.isRequired)
                dataObj[key + 'Error'] = false;
            } else {
                dataObj[key] = false;
                dataObj[key + 'Preview'] = false;
            }
        });

        return dataObj;
    },
    directives: {
        mask: function (e, b) {
            if (!b.value) {
                return;
            }

            maskDirective(e, b);
        }
    },
    methods: {
        clearError: function(e, fieldName) {
            this[fieldName + 'Error'] = false;
        },
        formatPhone: function(phone) {
            let phoneRaw = phone,
                formatted = '',
                chunkSize = 3,
                chunks = 4;

            for (chunks; chunks > 0; chunks--) {
                if (chunks <= 2)
                    chunkSize = 2;

                formatted += phoneRaw.substring(chunkSize, 0) + ' ';
                phoneRaw = phoneRaw.substring(phoneRaw.length, chunkSize);
            }
            return formatted;
        },
        showPicture(e, fieldName) {
            let file = e.target.files[0];
            
            this[fieldName + 'Preview'] = URL.createObjectURL(file);
        },
        validateForm(e) {
            e.preventDefault();
            let self = this,
                valid = true,
                emailFields = e.target.querySelectorAll('[data-type="email"]'),
                dateFields = e.target.querySelectorAll('[data-type="date"]'),
                phoneFields = e.target.querySelectorAll('[data-type="phone"]');

            emailFields.forEach(function (field) {
                let fieldName = field.getAttribute('v-model');

                if (self.formFields[fieldName].isRequired) {
                    if (!self.validateEmail(field.value)) {
                        valid = false;
                        self[fieldName + 'Error'] = true;
                    }
                }
            });

            dateFields.forEach(function (field) {
                let fieldName = field.getAttribute('v-model');

                if (self.formFields[fieldName].isRequired) {
                    if (!self.validateDate(field.value)) {
                        valid = false;
                        self[fieldName + 'Error'] = true;
                    }
                }
            });

            phoneFields.forEach(function (field) {
                let fieldName = field.getAttribute('v-model');

                if (self.formFields[fieldName].isRequired) {
                    if (!self.validatePhone(field.value)) {
                        valid = false;
                        self[fieldName + 'Error'] = true;
                    }
                }
            });

            if (valid)
                e.target.submit();
        },
        validateEmail(email) {
            return String(email)
                .toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
        },
        validateDate(date) {
            //31 февраля проходит, надо доработать:
            return /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g.test(date);
        },
        validatePhone(phone) {
            if (phone.length === 16 && /^\d+$/.test(phone))
                return true;
            else
                return false;
        },
        togglePseudoSelect(e) {
            e.stopPropagation();

            let inputWrapper = e.target.closest('.form__input-wrapper'),
                arrow = inputWrapper.querySelector('.form__selects-current-arrow'),
                pseudoSelectBlock,
                degreesCounter;

            if (e.target.classList.contains('form__selects-visible'))
                pseudoSelectBlock = this;
            else
                pseudoSelectBlock = e.target.closest('.form__selects-visible');

            degreesCounter = arrow.style.transform === '' ? 0 : Number(arrow.style.transform.replace(/\D/g, ''));

            if (pseudoSelectBlock.classList.contains('form__selects-visible--open')) {
                pseudoSelectBlock.classList.remove('form__selects-visible--open');

                degreesCounter += 270;
                arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';
            } else {
                pseudoSelectBlock.classList.add('form__selects-visible--open');
                
                degreesCounter += 90;
                arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';
            }
        },
        // closePseudoSelect(select) {
        //     let inputWrapper = select.closest('.form__input-wrapper'),
        //         arrow = inputWrapper.querySelector('.form__selects-current-arrow'),
        //         degreesCounter;

        //     degreesCounter = arrow.style.transform === '' ? 0 : Number(arrow.style.transform.replace(/\D/g, ''));

        //     if (select.classList.contains('form__selects-visible--open')) {
        //         select.classList.remove('form__selects-visible--open');

        //         degreesCounter += 270;
        //         arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';
        //     }
        // },
        selectItem(e, id) {
            let item = e.target,
                inputWrapper = item.closest('.form__input-wrapper'),
                allItems = inputWrapper.querySelectorAll('.form__selects-item'),
                currentDisplayField = inputWrapper.querySelector('.form__selects-current'),
                radioToCheck = inputWrapper.querySelector('input[value="' + id +'"]');

            allItems.forEach(function(iterationItem) {
                iterationItem.classList.remove('form__selects-item--hidden');
            });
            item.classList.add('form__selects-item--hidden');

            if (currentDisplayField.classList.contains('form__selects-current--awaits-interaction'))
                currentDisplayField.classList.remove('form__selects-current--awaits-interaction');

            currentDisplayField.innerText = item.innerText;

            radioToCheck.checked = true;
        }
    },
    computed: {
        phoneFormatted() {
            return this.formatPhone(this.userPhone);
        }
    },
    mounted() {
        this.$el.addEventListener('click', (e) => {
            let form = this.$refs.form,
                openedPseudoSelects = form.querySelectorAll('.form__selects-visible--open');

            if (openedPseudoSelects.length) {
                openedPseudoSelects.forEach(function(select) {
                    let selectWrapper = select.closest('.form__input-wrapper');

                    if (!selectWrapper.contains(e.target) && e.target !== selectWrapper) {
                        // так правильнее, но почему-то отсюда вызов метода не сработал:
                        // this.closePseudoSelect(select);

                        let inputWrapper = select.closest('.form__input-wrapper'),
                            arrow = inputWrapper.querySelector('.form__selects-current-arrow'),
                            degreesCounter;

                        degreesCounter = arrow.style.transform === '' ? 0 : Number(arrow.style.transform.replace(/\D/g, ''));

                        if (select.classList.contains('form__selects-visible--open')) {
                            select.classList.remove('form__selects-visible--open');

                            degreesCounter += 270;
                            arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';
                        }
                    }
                });
            }
        });
    }
}
</script>