<template>
    <OfflineQr></OfflineQr>

    <!-- <section class="error">
        <div class="error__content">
            <h1 class="error__content-heading">
                Технические котоладки...
            </h1>
            <div class="error__content-text">
                <p>
                    Мы оперативно чиним возникшую проблему, совсем скоро приложение будет снова возможно использовать!
                </p>
            </div>
        </div>
    </section> -->
</template>

<script>
import OfflineQr from '../components/app/OfflineQr.vue';

export default {
    data: () => ({

    }),
    mounted() {
        const cssMediaFileName = 'media.css',
              head = document.body.parentElement.firstElementChild,
              link = document.createElement('link');

        link.setAttribute('href', '/css/' + cssMediaFileName);

        window.addEventListener('resize', function(e) {
            let windowRealWidth = window.outerWidth,
                windowRealHeight = window.outerHeight;

            if (windowRealWidth < 400) {
                head.appendChild(link);
            } else {
                head.removeChild(link);
            }
        }, true);
    },
    components: { OfflineQr }
}
</script>

