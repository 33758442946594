<template>
    <Preloader :class="pageLoading === false ? 'preloader--loaded' : ''"></Preloader>

    <Header
        :headerClass="headerClass"
        :pageName="pageName"
        :isIndex="isIndex"
        :returnLink="returnLink"
    ></Header>

    <main class="main main--index">
        <div class="app-container">
            <!-- .coffee-widget стоит выделить в отдельный компонент, когда я его верстал, еще не понимал, как emit работает -->
            <div class="coffee-widget">
                <div class="coffee-widget__qr-block">
                    <button class="coffee-widget__qr-trigger" @click="modalQR = true">
                    <span class="coffee-widget__qr-trigger-arrow" ref="qrArrow"></span>
                    </button>
                </div>

                <div class="coffee-widget__top-block">
                    <h2 class="coffee-widget__top-block-greetings">
                        Привет, <span class="coffee-widget__top-block-greetings-name">{{ userCounterName }}</span>!
                    </h2>
                    <router-link class="coffee-widget__top-block-counter-wrapper" to="#">
                        <p class="coffee-widget__top-block-text-counter">
                            <span class="coffee-widget__top-block-text-counter-count">{{ userCounterCount }}</span>
                            <span class="coffee-widget__top-block-text-counter-divider">/</span>
                            <span class="coffee-widget__top-block-text-counter-total">{{ userCounterTotal }}</span>
                            <span class="coffee-widget__top-block-text-counter-desc">чашек кофе</span>
                        </p>

                        <div class="coffee-widget__top-block-visual-counter">
                            <div
                                class="coffee-widget__top-block-visual-counter-current"
                                ref="visualCounterLine"
                            ></div>
                        </div>

                        <p class="coffee-widget__top-block-remains-counter">
                            <span class="coffee-widget__top-block-remains-counter-num">1 </span>
                            <span class="coffee-widget__top-block-remains-counter-word">чашка </span>
                            <span class="coffee-widget__top-block-remains-counter-res">до кофе за 1 рубль</span>
                        </p>
                    </router-link>
                </div>

                <div class="coffee-widget__bottom-block">
                    <p class="coffee-widget__bottom-block-text-wrapper">
                        <span>
                            <b>Все Дома</b>
                        </span>
                        <span>булочная</span>
                    </p>

                    <router-link class="coffee-widget__bottom-block-points-wrapper" to="#">
                        <span class="coffee-widget__bottom-block-points">209</span>
                        <span class="coffee-widget__bottom-block-points-text">баллов</span>
                    </router-link>
                </div>
            </div>
        </div>

        <SliderPromos
            :slides="slidesPromos"
            :sliderHeading="sliderPromosHeading"
            :isClosing="detailPromoClosing"
            @detail-promo="showDetailPromo"
        ></SliderPromos>

        <SliderFav
            :sliderHeading="favProductHeading"
            sliderHeadingLink="/activity?tab=favorite"
            :sliderInitialState="favProductInitialState"
        ></SliderFav>

        <SliderProducts
            :slides="slidesNew"
            :sliderHeading="sliderNewHeading"
            :isClosing="detailProductClosing"
            @detail-product="showDetailProduct"
        ></SliderProducts>
    </main>

    <Footer
        @call-map="showMap"
    ></Footer>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--qr"
        v-model="modalQR"
        contents-height="90vh"
        border-top-radius="30px"
        ref="qr"
    >
        <div class="app-container qr">
            <img class="qr__qr-img" src="/img/qr.png" alt="QR-код" width="238" height="238">

            <div class="qr__text-block">
                <h2 class="qr__heading">Предъявите на кассе</h2>

                <p class="qr__text">
                    Для начисления / списания<br>
                    баллов поднесите QR код к сканеру
                </p>
            </div>

            <router-link class="qr__btn btn" to="#">Добавить в Wallet</router-link>
        </div>
    </swipe-modal>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--promo"
        v-model="detailPromo"
        contents-height="93.78vh"
        border-top-radius="30px"
        ref="detailPromoModal"
    >
        <div class="detail-promo">
            <div class="detail-promo__head">
                <div class="app-container">
                    <h2 class="detail-promo__heading">{{ detailPromo.name }}</h2>
                </div>
            </div>

            <div class="detail-promo__body">
                <sliderPromoDetail
                    :slides="detailPromo.detailContent"
                    :link="detailPromo.link"
                    @touchstart="stoppag"
                    @allSlidesShown="detailPromoClose"
                ></sliderPromoDetail>
            </div>
        </div>
    </swipe-modal>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--product"
        v-model="detailProduct"
        contents-height="90vh"
        border-top-radius="30px"
        ref="detailProductModal"
    >
        <div class="detail-product">
            <div class="detail-product__slider-container">
                <SliderDetailProduct
                    :slides="detailProduct.gallery"
                    @touchstart="stoppag"
                ></SliderDetailProduct>
            </div>

            <div
                class="detail-product__content-container"
                :style="detailEnergy || detailComposition ? '' : 'height: ' + productTextInitHeight + 'px;'"
                @touchstart="stoppag"
            >
                <div
                    class="detail-product__head"
                    :class="favDialog ? 'detail-product__head--fav-dialog' : ''"
                >
                    <p class="detail-product__name-heart">
                        <h2
                            class="detail-product__name"
                            ref="productName"
                        >
                            {{ detailProduct.name }}
                        </h2>

                        <button
                            class="detail-product__heart"
                            :class="detailProduct.fav ? 'detail-product__heart--active' : ''"
                            @click="favDialog = !favDialog"
                        ></button>
                    </p>

                    <p class="detail-product__price-bonus">
                        <span class="detail-product__price">{{ detailProduct.price }} руб</span>
                        
                        <span class="detail-product__bonus">+{{ detailProduct.bonusPoints }}</span>
                    </p>

                    <div
                        class="detail-product__fav-dialog"
                        v-if="favDialog"
                    >
                        <!-- В новой версии дизайна тут разные тексты в зависимости от параметров: есть ли уже любимый продукт и когда он в последний раз менялся -->
                        <!-- Каките-то части текста могут быть выделены красным цветом, в стилях прописал, чтобы красным выделялись <span> внутри этого параграфа VVV -->
                        <!-- Логику же вывода текста надо уже при натяжке делать, как я понимаю из дизайне -->
                        <p class="detail-product__fav-dialog-text">Вы уверены, что хотите сделать {{ detailProduct.name }} любимым продуктом?</p>

                        <button
                            class="detail-product__fav-dialog-btn btn btn--fav"
                            @click="favTheProduct($event, detailProduct)"
                        >
                            Сделать любимым
                        </button>
                    </div>
                </div>

                <div class="detail-product__description-wrapper">
                    <div class="detail-product__description" v-html="detailProduct.detailText"></div>
                </div>

                <div class="detail-product__composition-energy">
                    <div class="detail-product__composition-energy-toggles">
                        <button
                            class="detail-product__composition-energy-toggle btn"
                            v-if="!detailEnergy"
                            @click="detailComposition = true"
                        >
                            Состав
                        </button>

                        <button
                            class="detail-product__composition-energy-toggle detail-product__composition-energy-toggle--hide btn btn--hide"
                            v-if="detailComposition || detailEnergy"
                            @click="detailComposition = false; detailEnergy = false;"
                        >
                            Свернуть
                        </button>

                        <button
                            class="detail-product__composition-energy-toggle btn"
                            v-if="!detailComposition"
                            @click="detailEnergy = true"
                        >
                            Кбжу
                        </button>
                    </div>

                    <div
                        class="detail-product__composition-energy-text-wrapper"
                        v-if="detailComposition"
                    >
                        <div
                            class="detail-product__composition-energy-text detail-product__composition-energy-text--composition"
                            v-html="detailProduct.composition"
                        ></div>
                    </div>

                    <div
                        class="detail-product__composition-energy-text-wrapper"
                        v-if="detailEnergy"
                    >
                        <div
                            class="detail-product__composition-energy-text detail-product__composition-energy-text--energy"
                            v-html="detailProduct.energy"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </swipe-modal>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--map"
        v-model="map"
        contents-height="90vh"
        border-top-radius="30px"
        ref="map"
    >
        <div class="app-container">
            map
        </div>
    </swipe-modal>

    <swipe-modal
        class="swipe-modal swipe-modal--visit swipe-modal--blur"
        v-model="visitModal"
        contents-height="363px"
        border-top-radius="30px"
        backgroundColor="transparent"
        ref="visitSwipe"
    >
        <div class="app-container">
            <div class="visit">
                <div class="visit__text">
                    <p class="visit__text-paragraph">
                        Сегодня в {{ visitData.time }} вы посетили булочную ВСЕ ДОМА на {{ visitData.address }}
                    </p>
                    <p class="visit__text-paragraph">
                        Оцените ваше посещение
                    </p>
                </div>

                <div class="visit__rate smiley-rating">
                    <input
                        class="smiley-rating__input hidden-input"
                        id="visitPositive"
                        name="visit"
                        type="radio"
                        value="positive"
                        @change="addRating('positive')"
                    >
                    <label
                        class="smiley-rating__btn smiley-rating__btn--positive"
                        for="visitPositive"
                    ></label>
                    <input
                        class="smiley-rating__input hidden-input"
                        id="visitNeutral"
                        name="visit"
                        type="radio"
                        value="neutral"
                        @change="addRating('neutral')"
                    >
                    <label
                        class="smiley-rating__btn smiley-rating__btn--neutral"
                        for="visitNeutral"
                    ></label>
                    <input
                        class="smiley-rating__input hidden-input"
                        id="visitNegative"
                        name="visit"
                        type="radio"
                        value="negative"
                        @change="addRating('negative')"
                    >
                    <label
                        class="smiley-rating__btn smiley-rating__btn--negative"
                        for="visitNegative"
                    ></label>
                </div>

                <button
                    class="visit__btn btn"
                    @click="visitModalCancel"
                >
                    Больше не спрашивать
                </button>
            </div>
        </div>
    </swipe-modal>
</template>

<script>
import Preloader from '../components/app/Preloader';
import SliderPromos from '../components/app/SliderPromos';
import SliderPromoDetail from '../components/app/SliderPromoDetail';
import SliderProducts from '../components/app/SliderProducts';
import SliderFav from '../components/app/SliderFav';
import SliderDetailProduct from '../components/app/SliderDetailProduct';
import Footer from '../components/app/Footer';
import Header from '../components/app/Header';
import {swipeModal} from '@takuma-ru/vue-swipe-modal';

export default {
    data: () => ({
        map: false,
        pageLoading: true,
        modalQR: false,
        headerClass: 'header--index',
        isIndex: true,
        pageName: '',
        returnLink: '',
        userCounterName: 'Денис',
        userCounterCount: '5',
        userCounterTotal: '6',
        favProductHeading: 'Любимый продукт',
        favProductInitialState: 0,
        detailPromo: false,
        detailPromoClosing: false,
        sliderPromosHeading: 'Акции',
        //надо делить акции по страницам так, чтобы контент 1 страницы влезал на экран
        //это и требование дизайнера, и избавляет от неустранимых (или трудноустранимых) проблем с версткой:
        slidesPromos : [
            {
                id: 1,
                name: 'Акция 1',
                img: '/img/1.png',
                link: '#',
                detailContent: [
                    {
                        detailText: 
                            '<p>\n'+
                            'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице. Это простой текст на странице\n'+
                            '</p>\n'+
                            '<p>\n'+
                                'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице.\n'+
                            '</p>\n'+
                            '<p>\n'+
                                '<b>Этот текст выделен жирным.</b>\n'+
                            '</p>\n'+
                            '<h3>Заголовок.</h3>\n'+
                            '<h4>Подзаголовок.</h4>\n'+
                            '<p>\n'+
                                'Гиперссылка (<a href="https://vsedoma-family.ru">https://vsedoma-family.ru</a>).\n'+
                            '</p>\n'+
                            '<ol>\n'+
                                '<li>Первый элемент нумерованного списка</li>\n'+
                                '<li>Второй элемент нумерованного списка</li>\n'+
                                '<li>Третий элемент нумерованного списка</li>\n'+
                            '</ol>\n'+
                            '<ul>\n'+
                                '<li>Первый элемент ненумерованного списка</li>\n'+
                                '<li>Второй элемент ненумерованного списка</li>\n'+
                                '<li>Третий элемент ненумерованного списка</li>\n'+
                            '</ul>',
                        detailImg: '',
                    },
                    {
                        detailText: 
                            '<table>\n'+
                                '<tbody>\n'+
                                '<tr>\n'+
                                    '<td>\n'+
                                    'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa necessitatibus excepturi ratione optio, \n'+
                                    'et porro illo impedit sit, sequi sunt maxime quas officia repellat, hic distinctio reiciendis. Impedit, saepe sed!\n'+
                                    '</td>\n'+
                                    '<td>Test 2</td>\n'+
                                    '<td>Test 3</td>\n'+
                                '</tr>\n'+
                                '<tr>\n'+
                                    '<td>Test 1</td>\n'+
                                    '<td>Test 2</td>\n'+
                                    '<td>Test 3</td>\n'+
                                '</tr>\n'+
                                '<tr>\n'+
                                    '<td>Test 1</td>\n'+
                                    '<td>Test 2</td>\n'+
                                    '<td>Test 3</td>\n'+
                                '</tr>\n'+
                                '<tr>\n'+
                                    '<td>Test 1</td>\n'+
                                    '<td>Test 2</td>\n'+
                                    '<td>Test 3</td>\n'+
                                '</tr>\n'+
                                '<tr>\n'+
                                    '<td>Test 1</td>\n'+
                                    '<td>Test 2</td>\n'+
                                    '<td>Test 3</td>\n'+
                                '</tr>\n'+
                                '</tbody>\n'+
                            '</table>',
                        detailImg: '',
                    },
                    {
                        detailText:
                            '<p>\n'+
                            'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице. Это простой текст на странице\n'+
                            '</p>\n'+
                            '<p>\n'+
                                'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице.\n'+
                            '</p>\n'+
                            '<p>\n'+
                                '<b>Этот текст выделен жирным.</b>\n'+
                            '</p>',
                        detailImg: 'https://via.placeholder.com/500x950.png',
                    },
                    {
                        detailText: '',
                        detailImg: 'https://via.placeholder.com/600x800.png',
                    },
                ],
            },
            {
                id: 2,
                name: 'Акция 2',
                img: '/img/2.png',
                detailContent: [
                    {
                        detailText:
                            '<p>\n'+
                                'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице. Это простой текст на странице\n'+
                            '</p>\n'+
                            '<p>\n'+
                                'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице.\n'+
                            '</p>\n'+
                            '<p>\n'+
                                '<b>Этот текст выделен жирным.</b>\n'+
                            '</p>\n'+
                            '<h3>Заголовок.</h3>\n'+
                            '<h4>Подзаголовок.</h4>',
                        detailImg: '',
                    },
                ],
            },
            {
                id: 3,
                name: 'Акция 3',
                img: '/img/3.png',
                detailContent: [
                    {
                        detailText:
                            '<p>\n'+
                                'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице. Это простой текст на странице\n'+
                            '</p>\n'+
                            '<p>\n'+
                                'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице.\n'+
                            '</p>\n'+
                            '<p>\n'+
                                '<b>Этот текст выделен жирным.</b>\n'+
                            '</p>',
                        detailImg: '',
                    }
                ],
            },
            {
                id: 4,
                name: 'Акция 4',
                img: '/img/4.png',
                detailContent: [
                    {
                        detailText:
                            '<p>\n'+
                                'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице. Это простой текст на странице\n'+
                            '</p>\n'+
                            '<p>\n'+
                                'Это простой текст на странице. Это простой текст на странице. Это простой текст на странице.\n'+
                            '</p>',
                        detailImg: '',
                    }
                ],
            },
        ],
        detailProduct: false,
        detailProductClosing: false,
        sliderNewHeading: 'Новинки',
        slidesNew : [
            {
                name: 'Багет Баррорустик',
                img: '/img/1.png',
                id: 1,
                fav: true,
                favTomorrow: false,
                filters: [
                    'menuAll',
                    'menuTopMonth',
                    'menuTopRating',
                ],
                bonusPoints: 6,
                price: 149,
                measure: '1 шт',
                gallery: [ //нужно кропать картинки для вывода в слайдер в карточке товара в соотношении сторон 1:1, иначе слайдер будет ломаться. Если надо будет - объясню лично, почему
                    '/img/1.png',
                    '/img/2.png',
                ],
                detailText: '<p>Нежный кофейный мусс на шоколадном бисквите, покрытый сверху хрустящим шоколадом с карамелью</p>',
                composition: '<p>Сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар</p>',
                energy: '<p>Белки: 0 г.</p><p>Жиры: 1 г.</p><p>Углеводы: 20 г.</p><p>Энегретическая ценность: 100 кКал</p>',
            },
            {
                name: 'Имбирно-пряничный латте на молоке',
                img: '/img/2.png',
                id: 2,
                fav: false,
                favTomorrow: true,
                filters: [
                    'menuAll',
                    'menuTopMonth',
                ],
                bonusPoints: 5,
                price: 149,
                measure: '300 мл',
                gallery: [
                    '/img/2.png',
                ],
                detailText: '<p>Нежный кофейный мусс на шоколадном бисквите, покрытый сверху хрустящим шоколадом с карамелью</p>',
                composition: '<p>Сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар</p>',
                energy: '<p>Белки: 0 г.</p><p>Жиры: 1 г.</p><p>Углеводы: 20 г.</p><p>Энегретическая ценность: 100 кКал</p>',
            },
            {
                name: 'Имбирно-пряничный латте на растительном молоке',
                img: '/img/3.png',
                id: 3,
                fav: false,
                favTomorrow: false,
                filters: [
                    'menuAll',
                    'menuTopRating',
                ],
                bonusPoints: 6,
                price: 149,
                measure: '300 мл',
                gallery: [
                    '/img/1.png',
                    '/img/2.png',
                ],
                detailText: '<p>Нежный кофейный мусс на шоколадном бисквите, покрытый сверху хрустящим шоколадом с карамелью</p>',
                composition: '<p>Сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар</p>',
                energy: '<p>Белки: 0 г.</p><p>Жиры: 1 г.</p><p>Углеводы: 20 г.</p><p>Энегретическая ценность: 100 кКал</p>',
            },
            {
                name: 'Багет',
                img: '/img/4.png',
                id: 4,
                fav: false,
                favTomorrow: false,
                filters: [
                    'menuAll',
                ],
                bonusPoints: 6,
                price: 149,
                measure: '1 шт',
                gallery: [
                    '/img/1.png',
                    '/img/2.png',
                ],
                detailText: '<p>Нежный кофейный мусс на шоколадном бисквите, покрытый сверху хрустящим шоколадом с карамелью</p>',
                composition: '<p>Сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар</p>',
                energy: '<p>Белки: 0 г.</p><p>Жиры: 1 г.</p><p>Углеводы: 20 г.</p><p>Энегретическая ценность: 100 кКал</p>',
            },
        ],
        visitModal: false,
        visitData: {},
    }),
    watch: {
        map: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.map.$el);
        },
        modalQR: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.qr.$el);
        },
        detailPromo: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.detailPromoModal.$el);

            if (value === false) {
                this.detailPromoClosing = true;
            } else {
                this.detailPromoClosing = false;
            }
        },
        detailProduct: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.detailProductModal.$el);

            if (value === false) {
                this.detailProductClosing = true;
            } else {
                this.detailProductClosing = false;
            }
        },
        visitModal: function(value) {
            this.controlModalBackdropBlur(value, this.$refs.visitSwipe.$el);
        },
    },
    methods: {
        showMap: function() {
            this.map = true;
        },
        controlModalBackdropOpacity: function(isOn, ref) {
            if (ref.classList.contains('swipe-modal--qr')) {
                let arrowBtn = this.$refs.qrArrow,
                    rotateDegrees = isOn ? 270 : 90,
                    degreesCounter = arrowBtn.style.transform === '' ? 0 : Number(arrowBtn.style.transform.replace(/\D/g, ''));

                degreesCounter += rotateDegrees;
                arrowBtn.style.transform = 'rotate(' + degreesCounter + 'deg)';
            }

            if (isOn) {
                let modalWindow = ref;

                this.$nextTick(() => {
                    let modalBlock = modalWindow.querySelector('.modal-contents'),
                        modalBlockHeight = modalBlock.getBoundingClientRect().height,
                        pageContent = document.querySelectorAll('header, main > *, footer');

                    let observer = new MutationObserver((mutations) => {
                        mutations.forEach((mutation) => {
                            let modalBlockBottom = Math.abs(Number((mutation.target.style.bottom).slice(0, -2))),
                                backdropOpacity = 1 - (modalBlockHeight - modalBlockBottom) / modalBlockHeight;

                            if (pageContent != undefined) {
                                for (let element in Object.entries(pageContent)) {
                                    pageContent[element].style.opacity = backdropOpacity;
                                }
                            }
                        });
                    });

                    let observerConfig = {
                        attributes: true,
                        childList: false,
                        characterData: false,
                        attributeOldValue: true
                    };

                    observer.observe(modalBlock, observerConfig);
                });
            }
        },
        controlModalBackdropBlur: function(isOn, ref) {
            if (isOn) {
                let modalWindow = ref;

                this.$nextTick(() => {
                    let blurValue = 4,
                        modalBlock = modalWindow.querySelector('.modal-contents'),
                        modalBlockHeight = modalBlock.getBoundingClientRect().height,
                        backdrop = modalWindow.querySelector('.modal-background');

                    let observer = new MutationObserver((mutations) => {
                        mutations.forEach((mutation) => {
                        let modalBlockBottom = Math.abs(Number((mutation.target.style.bottom).slice(0, -2))),
                            backdropBlur = ((modalBlockHeight - modalBlockBottom) / modalBlockHeight) * blurValue;

                        if (backdrop !== undefined)
                            backdrop.style.backdropFilter = 'blur(' + backdropBlur + 'px)';
                        });
                    });

                    let observerConfig = {
                        attributes: true,
                        childList: false,
                        characterData: false,
                        attributeOldValue: true
                    };

                    observer.observe(modalBlock, observerConfig);
                });
            }
        },
        showDetailPromo: function(i) {
            this.detailPromo = this.slidesPromos[i];
        },
        showDetailProduct: function(i) {
            this.detailProduct = this.slidesNew[i];
        },
        visitModalCancel: function() {
            //перед закрытием дописать скрипт, чтобы где-то фиксировалось, что больше модалку показывать не надо

            let ref = this,
                visitSwipe = ref.$refs.visitSwipe.$el,
                visitModal = visitSwipe.querySelector('.modal-contents'),
                visitModalBg = visitSwipe.querySelector('.modal-background'),
                blurValue = 4,
                visitModalHeight = visitModal.getBoundingClientRect().height;

            let visitBackdropAnimation = visitModalBg.animate([
                {
                    backdropFilter: 'blur(' + blurValue + 'px)',
                },
                {
                    backdropFilter: 'blur(0)',
                },
            ], {
                duration: 150,
                easing: 'linear',
                iterations: 1,
                fill: 'forwards',
            });

            visitBackdropAnimation.finished.then(function() {
                visitBackdropAnimation.cancel();
            });

            let visitAnimation = visitModal.animate([
                {
                    bottom: 0,
                },
                {
                    bottom: '-' + visitModalHeight + 'px',
                },
            ], {
                duration: 150,
                easing: 'linear',
                iterations: 1,
                fill: 'forwards',
            });
            
            visitAnimation.finished.then(function() {
                ref.visitModal = false;
                visitAnimation.cancel();
            });
        },
        addRating: function(rating) {
            //надо дописать, чтобы оценка где-то зафиксировалась. Потом происходить закрытие, его скрип уже написан:

            setTimeout(() => {
                this.visitModalCancel();
            }, 500);
        },
        stoppag: function(e) {
            e.stopPropagation();
        },
        detailPromoClose: function() {
            let ref = this,
                detailPromoSwipe = ref.$refs.detailPromoModal.$el,
                detailPromoModal = detailPromoSwipe.querySelector('.modal-contents'),
                pageContent = document.querySelectorAll('header, main > *, footer'),
                detailPromoModalHeight = detailPromoModal.getBoundingClientRect().height;

            pageContent.forEach(function(elem) {
                let backdropAnimation = elem.animate([
                    {
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                    },
                ], {
                    duration: 150,
                    easing: 'linear',
                    iterations: 1,
                    fill: 'forwards',
                });

                backdropAnimation.finished.then(function() {
                    elem.removeAttribute('style');
                    backdropAnimation.cancel();
                });
            });

            let modalAnimation = detailPromoModal.animate([
                {
                    bottom: 0,
                },
                {
                    bottom: '-' + detailPromoModalHeight + 'px',
                },
            ], {
                duration: 150,
                easing: 'linear',
                iterations: 1,
                fill: 'forwards',
            });

            modalAnimation.finished.then(function() {
                ref.detailPromo = false;
                modalAnimation.cancel();
            });
        },
    },
    mounted() {
        let current = this.userCounterCount,
            total = this.userCounterTotal,
            line = this.$refs.visualCounterLine;

        line.style.width = 'calc(' + current + ' / ' + total + ' * 100% - 20px)';

        setTimeout(() => {
            this.pageLoading = false;
        }, 2000);

        if (this.visitToRate !== undefined) {
            let visitJson = JSON.parse(this.visitToRate);
            
            if (typeof visitJson === 'object') {
                this.visitData = visitJson;
                this.visitModal = true;
            }
        }
    },
    computed: {
        visitToRate() {
            return this.$route.query.visit;
        },
    },
    components: {Preloader, Header, SliderPromos, SliderFav, SliderProducts, SliderPromoDetail, SliderDetailProduct, Footer, swipeModal}
}
</script>

