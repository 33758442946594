<template>
  <Header
    :pageName="pageName"
    :isIndex="isIndex"
    :returnLink="returnLink"
  ></Header>

  <main class="main main--catalog">
    <CatalogSectionList :sections="sections"></CatalogSectionList>
  </main>

  <Footer
    @call-map="showMap"
  ></Footer>

  <swipe-modal
    class="swipe-modal swipe-modal--full-screen swipe-modal--map"
    v-model="map"
    contents-height="90vh"
    border-top-radius="30px"
    ref="map"
  >
    <div class="app-container">
      map
    </div>
  </swipe-modal>
</template>

<script>
import Footer from '../components/app/Footer';
import Header from '../components/app/Header';
import CatalogSectionList from '../components/app/CatalogSectionList';

import {swipeModal} from '@takuma-ru/vue-swipe-modal'

export default {
  data: () => ({
    map: false,
    isIndex: false,
    pageName: 'Каталог',
    returnLink: '/',
    sections : [
      {
        name: 'Хлеб',
        img: '/img/1.png',
        link: '/catalog-section',
      },
      {
        name: 'Выпечка',
        img: '/img/2.png',
        link: '/catalog-section',
      },
      {
        name: 'Пироги',
        img: '/img/3.png',
        link: '/catalog-section',
      },
      {
        name: 'Десерты',
        img: '/img/4.png',
        link: '/catalog-section',
      },
      {
        name: 'Печенье',
        img: '/img/1.png',
        link: '/catalog-section',
      },
      {
        name: 'Напитки',
        img: '/img/2.png',
        link: '/catalog-section',
      },
      {
        name: 'Завтраки',
        img: '/img/3.png',
        link: '/catalog-section',
      },
      {
        name: 'Обеды',
        img: '/img/4.png',
        link: '/catalog-section',
      },
      {
        name: 'Еда',
        img: '/img/1.png',
        link: '/catalog-section',
      },
    ],
  }),
  watch: {
    map: function(value) {
      this.controlModalBackdropOpacity(value, this.$refs.map.$el);
    },
  },
  methods: {
    showMap: function() {
      this.map = true;
    },
    controlModalBackdropOpacity: function(isOn, ref) {
      if (isOn) {
        let modalWindow = ref;

        this.$nextTick(() => {
          let modalBlock = modalWindow.querySelector('.modal-contents'),
              modalBlockHeight = modalBlock.getBoundingClientRect().height,
              pageContent = document.querySelectorAll('header, main > *, footer');

          let observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              let modalBlockBottom = Math.abs(Number((mutation.target.style.bottom).slice(0, -2))),
                  backdropOpacity = 1 - (modalBlockHeight - modalBlockBottom) / modalBlockHeight;

              if (pageContent != undefined) {
                for (let element in Object.entries(pageContent)) {
                  pageContent[element].style.opacity = backdropOpacity;
                }
              }
            });
          });

          let observerConfig = {
            attributes: true,
            childList: false,
            characterData: false,
            attributeOldValue: true
          };

          observer.observe(modalBlock, observerConfig);
        });
      }
    },
  },
  metaInfo() {
    return { 
        meta: [
            {
                property: 'viewport',
                content: 'width=430,user-scalable=no,initial-scale=1.0'
            },
            // { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
        ]
    }
  },
  components: {Header, Footer, CatalogSectionList, swipeModal}
}
</script>

