<template>
    <section class="catalog-section">
        <div class="app-container catalog-section__container">
            <BtnMenu
                :additionalClass="menuAdditionalClass"
                :items="menuItems"
                :currentFilter="menuFilter"
                @set-child-data="updateFilterValue"
            ></BtnMenu>

            <div class="catalog-section__products-list-wrapper">
                <div class="catalog-section__products-list">
                    <button
                        class="catalog-section__product catalog-item"
                        v-for="product in filteredProducts"
                        :key="product.id"
                        :v-model="`product` + product.id"
                        :data-id="`product` + product.id"
                        @click="detailProduct($event, product.id)"
                    >
                        <div class="catalog-item__img-block">
                            <img
                                class="catalog-item__img"
                                :src="product.img"
                                :alt="product.name"
                            >
                        </div>

                        <div class="catalog-item__text-block">
                            <div class="catalog-item__name-measure-heart">
                                <div class="catalog-item__name-block">
                                    <span class="catalog-item__name">{{ product.name }}</span>
                                </div>
                                
                                <p class="catalog-item__measure-heart">
                                    <span class="catalog-item__measure">{{ product.measure }}</span>

                                    <span
                                        class="catalog-item__heart"
                                        :class="product.favTomorrow ? 'catalog-item__heart--tomorrow' : ''"
                                        v-if="product.fav || product.favTomorrow"
                                    ></span>
                                </p>
                            </div>

                            <span class="catalog-item__bonus">+{{ product.bonusPoints }}</span>
                        </div>

                        <span class="catalog-item__arrow"></span>

                        <span class="catalog-item__price">{{ product.price }}&nbsp;р</span>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import BtnMenu from '@/components/app/BtnMenu';

export default {
    props: {
        products: {
            type: Array
        },
    },
    data: () => ({
        menuAdditionalClass: 'catalog-section__menu',
        menuItems: [
            {
                text: 'Все десерты',
                id: 'menuAll',
            },
            {
                text: 'Топ месяца',
                id: 'menuTopMonth',
            },
            {
                text: 'Топ рейтинга',
                id: 'menuTopRating',
            },
        ],
        menuFilter: 'menuAll',
    }),
    emits: ['detail-product'],
    watch: {
        menuFilter() {
            this.changeNamesSizes();
        }
    },
    methods: {
        updateFilterValue: function(newValue) {
            this.menuFilter = newValue;
        },
        detailProduct: function(e, id) {
            let catalogItem,
                arrow,
                degreesCounter;

            if (e.target.classList.contains('catalog-item'))
                catalogItem = e.target;
            else
                catalogItem = e.target.closest('.catalog-item');
            
            arrow = catalogItem.querySelector('.catalog-item__arrow');
            degreesCounter = arrow.style.transform === '' ? 0 : Number(arrow.style.transform.replace(/\D/g, ''));

            arrow.classList.add('catalog-item__arrow--open');
            degreesCounter += 270;
            arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';

            this.$emit('detail-product', id);
        },
        changeNamesSizes: function() {
            this.$nextTick(() => {
                let productNames = this.$el.querySelectorAll('.catalog-item__name');

                productNames.forEach(function(name) {
                    let nameSpan = name,
                        nameBlock = nameSpan.parentNode,
                        nameLength = nameSpan.innerHTML.length,
                        productTextBlock = nameSpan.closest('.catalog-item__text-block'),
                        defaultFontSize = 16;
                    
                    if (nameLength > 25 && nameLength <= 35)
                        productTextBlock.classList.add('catalog-item__text-block--long');
                    else if (nameLength > 35)
                        productTextBlock.classList.add('catalog-item__text-block--very-long');

                    nameBlock.style.fontSize = defaultFontSize + 'px';
                    while (nameSpan.offsetHeight > nameBlock.offsetHeight) {
                        nameBlock.style.fontSize = defaultFontSize - 1 + 'px';
                        defaultFontSize--;

                        if (defaultFontSize <= 0) break;
                    }
                });
            });
        }
    },
    mounted() {
        this.changeNamesSizes();
    },
    computed: {
        filteredProducts() {
            return this.$props.products.filter(
                (product) => product.filters.includes(this.menuFilter)
            );
        }
    },
    components: {BtnMenu}
}
</script>