<template>
  <main class="main main--intro">
    <IntroConfirm></IntroConfirm>
  </main>
</template>

<script>
import IntroConfirm from "../components/app/IntroConfirm";

export default {
  data: () => ({

  }),
  components: {IntroConfirm}
}
</script>
