(function(){ try {var elementStyle = document.createElement('style'); elementStyle.appendChild(document.createTextNode(".swipe-modal-takumaru-vue-swipe-modal{position:fixed;scrollbar-width:none;z-index:15}.swipe-modal-takumaru-vue-swipe-modal .modal-background{position:fixed;z-index:11;width:100vw;height:100vh;top:50%;left:50%;transform:translate(-50%,-50%)}.swipe-modal-takumaru-vue-swipe-modal .modal-contents{position:fixed;z-index:12;max-height:100vh;left:50%;transform:translate(-50%) translateY(0);overflow-y:scroll;-ms-overflow-style:none;scrollbar-width:none;backface-visibility:hidden;-webkit-backface-visibility:hidden;filter:drop-shadow(0px 16px 40px rgba(0,37,80,.2))}.swipe-modal-takumaru-vue-swipe-modal .modal-contents::-webkit-scrollbar{width:0px}.swipe-modal-takumaru-vue-swipe-modal .modal-contents-chip-wrapper{z-index:12;display:flex;justify-items:center;align-items:center;justify-content:center;align-content:center;position:relative;top:0px;height:4px;width:100%;padding-top:8px;padding-bottom:8px;cursor:s-resize}.swipe-modal-takumaru-vue-swipe-modal .modal-contents-chip{--tip-color: #c8c8c8;width:40px;height:100%;border-radius:4px;background-color:var(--tip-color)}")); document.head.appendChild(elementStyle);} catch(e) {console.error('vite-plugin-css-injected-by-js', e);} })();
import { ref, unref, getCurrentScope, onScopeDispose, computed, watch, install, defineComponent, toRefs, h } from "vue-demi";
var _a;
const isClient = typeof window !== "undefined";
const isFunction = (val) => typeof val === "function";
const isNumber = (val) => typeof val === "number";
const clamp = (n, min, max) => Math.min(max, Math.max(min, n));
const noop = () => {
};
isClient && ((_a = window == null ? void 0 : window.navigator) == null ? void 0 : _a.userAgent) && /iP(ad|hone|od)/.test(window.navigator.userAgent);
function identity(arg) {
    return arg;
}
function tryOnScopeDispose(fn) {
    if (getCurrentScope()) {
        onScopeDispose(fn);
        return true;
    }
    return false;
}
function useTimeoutFn(cb, interval, options = {}) {
    const {
        immediate = true
    } = options;
    const isPending = ref(false);
    let timer = null;
    function clear() {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
    }
    function stop() {
        isPending.value = false;
        clear();
    }
    function start(...args) {
        clear();
        isPending.value = true;
        timer = setTimeout(() => {
            isPending.value = false;
            timer = null;
            cb(...args);
        }, unref(interval));
    }
    if (immediate) {
        isPending.value = true;
        if (isClient)
            start();
    }
    tryOnScopeDispose(stop);
    return {
        isPending,
        start,
        stop
    };
}
function unrefElement(elRef) {
    var _a2;
    const plain = unref(elRef);
    return (_a2 = plain == null ? void 0 : plain.$el) != null ? _a2 : plain;
}
const defaultWindow = isClient ? window : void 0;
const _global = typeof globalThis !== "undefined" ? globalThis : typeof window !== "undefined" ? window : typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : {};
const globalKey = "__vueuse_ssr_handlers__";
_global[globalKey] = _global[globalKey] || {};
_global[globalKey];
function useCssVar(prop, target, { window: window2 = defaultWindow, initialValue = "" } = {}) {
    const variable = ref(initialValue);
    const elRef = computed(() => {
        var _a2;
        return unrefElement(target) || ((_a2 = window2 == null ? void 0 : window2.document) == null ? void 0 : _a2.documentElement);
    });
    watch([elRef, () => unref(prop)], ([el, prop2]) => {
        var _a2;
        if (el && window2) {
            const value = (_a2 = window2.getComputedStyle(el).getPropertyValue(prop2)) == null ? void 0 : _a2.trim();
            variable.value = value || initialValue;
        }
    }, { immediate: true });
    watch(variable, (val) => {
        var _a2;
        if ((_a2 = elRef.value) == null ? void 0 : _a2.style)
            elRef.value.style.setProperty(unref(prop), val);
    });
    return variable;
}
function useRafFn(fn, options = {}) {
    const {
        immediate = true,
        window: window2 = defaultWindow
    } = options;
    const isActive = ref(false);
    let rafId = null;
    function loop() {
        if (!isActive.value || !window2)
            return;
        fn();
        rafId = window2.requestAnimationFrame(loop);
    }
    function resume() {
        if (!isActive.value && window2) {
            isActive.value = true;
            loop();
        }
    }
    function pause() {
        isActive.value = false;
        if (rafId != null && window2) {
            window2.cancelAnimationFrame(rafId);
            rafId = null;
        }
    }
    if (immediate)
        resume();
    tryOnScopeDispose(pause);
    return {
        isActive,
        pause,
        resume
    };
}
var SwipeDirection;
(function(SwipeDirection2) {
    SwipeDirection2["UP"] = "UP";
    SwipeDirection2["RIGHT"] = "RIGHT";
    SwipeDirection2["DOWN"] = "DOWN";
    SwipeDirection2["LEFT"] = "LEFT";
    SwipeDirection2["NONE"] = "NONE";
})(SwipeDirection || (SwipeDirection = {}));
function createEasingFunction([p0, p1, p2, p3]) {
    const a = (a1, a2) => 1 - 3 * a2 + 3 * a1;
    const b = (a1, a2) => 3 * a2 - 6 * a1;
    const c = (a1) => 3 * a1;
    const calcBezier = (t, a1, a2) => ((a(a1, a2) * t + b(a1, a2)) * t + c(a1)) * t;
    const getSlope = (t, a1, a2) => 3 * a(a1, a2) * t * t + 2 * b(a1, a2) * t + c(a1);
    const getTforX = (x) => {
        let aGuessT = x;
        for (let i = 0; i < 4; ++i) {
            const currentSlope = getSlope(aGuessT, p0, p2);
            if (currentSlope === 0)
                return aGuessT;
            const currentX = calcBezier(aGuessT, p0, p2) - x;
            aGuessT -= currentX / currentSlope;
        }
        return aGuessT;
    };
    return (x) => p0 === p1 && p2 === p3 ? x : calcBezier(getTforX(x), p1, p3);
}
function useTransition(source, options = {}) {
    const {
        delay = 0,
        disabled = false,
        duration = 1e3,
        onFinished = noop,
        onStarted = noop,
        transition = identity
    } = options;
    const currentTransition = computed(() => {
        const t = unref(transition);
        return isFunction(t) ? t : createEasingFunction(t);
    });
    const sourceValue = computed(() => {
        const s = unref(source);
        return isNumber(s) ? s : s.map(unref);
    });
    const sourceVector = computed(() => isNumber(sourceValue.value) ? [sourceValue.value] : sourceValue.value);
    const outputVector = ref(sourceVector.value.slice(0));
    let currentDuration;
    let diffVector;
    let endAt;
    let startAt;
    let startVector;
    const { resume, pause } = useRafFn(() => {
        const now = Date.now();
        const progress = clamp(1 - (endAt - now) / currentDuration, 0, 1);
        outputVector.value = startVector.map((val, i) => {
            var _a2;
            return val + ((_a2 = diffVector[i]) != null ? _a2 : 0) * currentTransition.value(progress);
        });
        if (progress >= 1) {
            pause();
            onFinished();
        }
    }, { immediate: false });
    const start = () => {
        pause();
        currentDuration = unref(duration);
        diffVector = outputVector.value.map((n, i) => {
            var _a2, _b;
            return ((_a2 = sourceVector.value[i]) != null ? _a2 : 0) - ((_b = outputVector.value[i]) != null ? _b : 0);
        });
        startVector = outputVector.value.slice(0);
        startAt = Date.now();
        endAt = startAt + currentDuration;
        resume();
        onStarted();
    };
    const timeout = useTimeoutFn(start, delay, { immediate: false });
    watch(sourceVector, () => {
        if (unref(disabled)) {
            outputVector.value = sourceVector.value.slice(0);
        } else {
            if (unref(delay) <= 0)
                start();
            else
                timeout.start();
        }
    }, { deep: true });
    return computed(() => {
        const targetVector = unref(disabled) ? sourceVector : outputVector;
        return isNumber(sourceValue.value) ? targetVector.value[0] : targetVector.value;
    });
}
const useTouchEvent = () => {
    const touchPosition = ref({
        isTouch: false,
        touchStart: 0,
        touchDistance: 0,
        touchX: 0,
        touchY: 0,
        plusHeight: 0
    });
    const initTouchPosition = () => {
        touchPosition.value = {
            isTouch: false,
            touchStart: 0,
            touchDistance: 0,
            touchX: 0,
            touchY: 0,
            plusHeight: 0
        };
    };
    const touchStart = (payload) => {
       //payload.preventDefault();
        payload.stopPropagation();
        if (payload.currentTarget.classList.contains("noClose")) {
            //return;
        }
        touchPosition.value.isTouch = true;
        touchPosition.value.touchStart = payload.touches[0].pageY;
    };
    const touchMove = (payload) => {
       // payload.preventDefault();
        payload.stopPropagation();
        if (payload.currentTarget.classList.contains("noClose")) {
          //  return;
        }
        if (touchPosition.value.isTouch) {
            touchPosition.value.touchY = payload.touches[0].pageY;
            touchPosition.value.touchDistance = touchPosition.value.touchStart - touchPosition.value.touchY;
            if (touchPosition.value.touchDistance > 0) {
                touchPosition.value.plusHeight = touchPosition.value.touchStart - touchPosition.value.touchY;
                //touchPosition.value.touchDistance = 0;
            }
            // console.log(touchPosition.value.touchDistance)
        }
    };
    const touchEnd = () => {
        touchPosition.value.isTouch = false;
    };
    return {
        touchPosition,
        initTouchPosition,
        touchStart,
        touchMove,
        touchEnd
    };
};
function useMouseEvent() {
    const mousePosition = ref({
        isMouseDown: false,
        downStart: 0,
        mouseDistance: 0,
        mouseX: 0,
        mouseY: 0
    });
    const initMousePosition = () => {
        mousePosition.value = {
            isMouseDown: false,
            downStart: 0,
            mouseDistance: 0,
            mouseX: 0,
            mouseY: 0
        };
    };
    const mouseDown = (payload) => {
        mousePosition.value.isMouseDown = true;
        mousePosition.value.downStart = payload.pageY;
    };
    const mouseMove = (payload) => {
        if (mousePosition.value.isMouseDown) {
            mousePosition.value.mouseY = payload.pageY;
            mousePosition.value.mouseDistance = mousePosition.value.downStart - mousePosition.value.mouseY;
            if (mousePosition.value.mouseDistance > 0) {
               mousePosition.value.mouseDistance = 0;
            }
        }
    };
    const mouseUp = () => {
        mousePosition.value.isMouseDown = false;
    };
    return {
        mousePosition,
        initMousePosition,
        mouseDown,
        mouseMove,
        mouseUp
    };
}
const toPixel = (payload) => {
    if (payload.indexOf("px") > 0) {
        const px = Number(payload.slice(0, payload.indexOf("px")));
        return px;
    } else if (payload.indexOf("vh") > 0) {
        const vh = Number(payload.slice(0, payload.indexOf("vh")));
        const px = window.innerHeight * (vh / 100);
        return px;
    } else if (payload.indexOf("vw") > 0) {
        const vw = Number(payload.slice(0, payload.indexOf("vw")));
        const px = window.innerWidth * (vw / 100);
        return px;
    } else {
        return 0;
    }
};
var swipeMy$1 = "";
install();
var swipeMy = defineComponent({
    name: "swipeMy",
    model: {
        prop: "modelValue",
        event: "update:modelValue"
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        dark: {
            type: Boolean,
            default: false
        },
        persistent: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: "#80808080"
        },
        fullscreen: {
            type: Boolean,
            default: false
        },
        noTip: {
            type: Boolean,
            default: false
        },
        contentsWidth: {
            type: String,
            default: "100%"
        },
        contentsHeight: {
            type: String,
            default: "30vh"
        },
        contentsHeightTop: {
            type: String,
            default: "0"
        },
        type: {
            type: String,
            default: "0"
        },
        contentsHeightMiddle: {
            type: String,
            default: "0"
        },
        borderTopRadius: {
            type: String,
            default: null
        },
        borderTopLeftRadius: {
            type: String,
            default: "0px"
        },
        borderTopRightRadius: {
            type: String,
            default: "0px"
        },
        contentsColor: {
            type: String,
            default: "white"
        },
        tipColor: {
            type: String,
            default: "#c8c8c8"
        },
        darkContentsColor: {
            type: String,
            default: "#1E1E1E"
        },
        noClose: false
    },
    emits: ["update:modelValue", "open", "close", "type"],
    setup(props, context) {
        const {
            mousePosition,
            initMousePosition,
            mouseDown,
            mouseMove,
            mouseUp
        } = useMouseEvent();
        const {
            touchPosition,
            initTouchPosition,
            touchStart,
            touchMove,
            touchEnd,
            plusHeight
        } = useTouchEvent();
        const propsRef = toRefs(props);
        const modal = computed({
            get: () => propsRef.modelValue.value,
            set: (value) => context.emit("update:modelValue", value)
        });
        const contentsBottomPositionTransitionDuration = ref(250);
        const el = ref(null);
        const tipColor = useCssVar("--tip-color", el);
        tipColor.value = propsRef.tipColor.value;
        const backgroundColor = ref([
            parseInt(propsRef.backgroundColor.value.slice(1, 3), 16),
            parseInt(propsRef.backgroundColor.value.slice(3, 5), 16),
            parseInt(propsRef.backgroundColor.value.slice(5, 7), 16),
            0
        ]);
        const backgroundColorEnterTransition = useTransition(backgroundColor, {
            duration: 250,
            transition: [0.25, 0.8, 0.25, 1]
        });
        const color = computed(() => {
            const [r, g, b, a] = backgroundColorEnterTransition.value;
            return `rgba(${r}, ${g}, ${b}, ${a})`;
        });
        const contentsBottomPosition = ref(-1 * toPixel(propsRef.contentsHeight.value));
        const contentsBottomPositionTransition = useTransition(contentsBottomPosition, {
            duration: contentsBottomPositionTransitionDuration.value,
            transition: [0.25, 0.8, 0.25, 1]
        });
        const contentsBottomDistance = computed(() => {
            const distance = /*contentsBottomPositionTransition.value + */(Math.abs(touchPosition.value.touchDistance) > Math.abs(mousePosition.value.mouseDistance) ? touchPosition.value.touchDistance : mousePosition.value.mouseDistance);
            // console.log(contentsBottomPositionTransition.value)
            // console.log(distance)
            return distance;
        });
        const init = () => {
            document.documentElement.style.overflowY = "auto";
            modal.value = false;
        };
        const open = () => {
            context.emit("open");
            contentsBottomPosition.value = 0;
            backgroundColor.value = [
                parseInt(propsRef.backgroundColor.value.slice(1, 3), 16),
                parseInt(propsRef.backgroundColor.value.slice(3, 5), 16),
                parseInt(propsRef.backgroundColor.value.slice(5, 7), 16),
                parseInt(propsRef.backgroundColor.value.slice(7, 9), 16) / 255
            ];
        };
        const close = () => {
            contentsBottomPosition.value = -1 * toPixel(propsRef.contentsHeight.value) - (Math.abs(touchPosition.value.touchDistance) > Math.abs(mousePosition.value.mouseDistance) ? touchPosition.value.touchDistance : mousePosition.value.mouseDistance);
            backgroundColor.value = [
                parseInt(propsRef.backgroundColor.value.slice(1, 3), 16),
                parseInt(propsRef.backgroundColor.value.slice(3, 5), 16),
                parseInt(propsRef.backgroundColor.value.slice(5, 7), 16),
                0
            ];
            setTimeout(() => {
                initMousePosition();
                initTouchPosition();
                init();
                context.emit("close");
            }, contentsBottomPositionTransitionDuration.value);
        };
        let type = ref(propsRef.type.value);
        let dopheight = ref(0);
        const contentsHeight = computed(() => {

            if (type.value == 1) {
                if (propsRef.contentsHeightTop.value != 0) {
                    return parseInt(toPixel(propsRef.contentsHeightMiddle.value)) + parseInt(dopheight.value)+'px';
                }
                else
                    return propsRef.contentsHeightMiddle.value;
            }
            else if (type.value == 2) {
                return propsRef.contentsHeightTop.value;
            }
            else {
                const px = parseInt(toPixel(propsRef.contentsHeight.value)) + parseInt(dopheight.value);
                return px+'px';
            }
        });
       const ontouchMove = (payload) => {

           touchMove(payload);
          // debugger;
        //    console.log(typeof propsRef.contentsHeightTop.value)
            if (propsRef.contentsHeightMiddle.value == 0) {
                if (touchPosition.value.touchDistance > 0) {
                    touchPosition.value.touchDistance = 0;
                }
            }

            else {
                if (touchPosition.value.touchDistance > 0) {

                    dopheight.value = touchPosition.value.touchDistance;
                    touchPosition.value.touchDistance = 0;
                }


                if (type.value > 0 && touchPosition.value.touchDistance > 0) {
                    touchPosition.value.touchDistance = 0;
                }
            }
       }
        const onTouchEnd = (payload) => {
          //  payload.preventDefault();
            payload.stopPropagation();
            touchEnd();
           // debugger;
            // console.log(type.value)
            if (type.value > 0 && touchPosition.value.touchDistance < 0) {

                let heightNow = window.innerHeight - touchPosition.value.touchY;
                if (propsRef.contentsHeightTop.value != 0 && type.value == 2) {
                    type.value = 1;
                }
                else {
                    type.value = 0;
                }

                dopheight.value = 0;
                touchPosition.value.touchDistance = 0;
                context.emit("type", type.value)
            }
            else if (dopheight.value > 0) {
                // console.log(toPixel(propsRef.contentsHeight.value))
                //plusHeight = toPixel(propsRef.contentsHeight.value) * 1.5;

                let heightNow = window.innerHeight - touchPosition.value.touchY;
                if (propsRef.contentsHeightMiddle.value != 0 && toPixel(propsRef.contentsHeightMiddle.value) > heightNow) {
                    type.value = 1;
                }
                else if (propsRef.contentsHeightTop.value != 0) {
                    type.value = 2;
                }
                dopheight.value = 0;
                touchPosition.value.touchDistance = 0;
                context.emit("type", type.value)
            }
            else if (-1 * touchPosition.value.touchDistance > toPixel(propsRef.contentsHeight.value) / 8
                && propsRef.noClose.value != true) {
                close();
            } else {
                touchPosition.value.touchDistance = 0;
            }
        };
        const onMouseUp = () => {
            mouseUp();
            if (-1 * mousePosition.value.mouseDistance > toPixel(propsRef.contentsHeight.value) / 8) {
                close();
            } else {
                mousePosition.value.mouseDistance = 0;
            }
        };
        watch(modal, (newVal, oldVal) => {
            if (modal.value) {
                open();
            }
        });
        return () => {
            var _a2, _b;
            return h("div", {
                class: "swipe-modal-takumaru-vue-swipe-modal",
                on: {
                    mousemove: mouseMove,
                    mouseup: onMouseUp
                },
                onMousemove: mouseMove,
                onMouseup: onMouseUp
            }, [
                propsRef.modelValue.value ? h("div", {
                    class: "modal-background",
                    style: {
                        backgroundColor: color.value
                    },
                    on: {
                        onMouseup: () => propsRef.persistent.value ? () => null : close(),
                        click: () => propsRef.persistent.value ? () => null : close()
                    },
                    onMouseup: () => {
                        propsRef.persistent.value ? () => null : close();
                    },
                    onClick: () => {
                        propsRef.persistent.value ? () => null : close();
                    }
                }) : null,
                propsRef.modelValue.value ? h("div", {
                    class: "modal-contents"+(propsRef.noClose.value?" noClose":""),
                    style: {
                        width: propsRef.contentsWidth.value,
                        //minHeight: propsRef.contentsHeight.value,
                        minHeight: `${contentsHeight.value}`,
                        borderTopLeftRadius: propsRef.borderTopRadius.value ? propsRef.borderTopRadius.value : propsRef.borderTopLeftRadius.value,
                        borderTopRightRadius: propsRef.borderTopRadius ? propsRef.borderTopRadius.value : propsRef.borderTopRightRadius.value,
                        backgroundColor: propsRef.dark.value ? propsRef.darkContentsColor.value : propsRef.contentsColor.value,
                        color: propsRef.dark.value ? "white" : "black",
                        bottom: `${contentsBottomDistance.value}px`
                    },
                    on: {
                        touchstart: touchStart,
                        touchmove: ontouchMove,
                        touchend: onTouchEnd
                    },
                    onTouchstart: touchStart,
                    onTouchmove: ontouchMove,
                    onTouchend: onTouchEnd
                }, [
                    !propsRef.noTip.value ? h("div", {
                        class: "modal-contents-chip-wrapper",
                        on: {
                            mousedown: mouseDown
                        },
                        onMousedown: mouseDown
                    }, [
                        h("div", {
                            class: "modal-contents-chip"
                        })
                    ]) : null,
                    (_b = (_a2 = context.slots).default) == null ? void 0 : _b.call(_a2)
                ]) : null
            ]);
        };
    }
});
export { swipeMy as default, swipeMy };
