<template>
  <header
    class="header"
    :class="headerClass"
  >
    <div class="app-container">
      <div
        class="header__index-wrapper"
        v-if="isIndex"
      >
        <span class="header__index-name header__index-name--left">Все Дома</span>
        <img class="header__index-logo" src="/img/logo-index.svg" alt="Логотип «Все Дома»" width="198" height="181">
        <span class="header__index-name header__index-name--right">Булочная</span>
      </div>

      <div
        class="header__inner-wrapper"
        v-if="!isIndex"
      >
        <router-link class="header__inner-return-link" :to="returnLink"></router-link>
        <h1 class="header__inner-heading" v-if="pageName.length">{{ pageName }}</h1>
      </div>
    </div>
  </header>
</template>
<script>

export default {
  props: {
    isIndex: {
      type: Boolean
    },
    headerClass: {
      type: String
    },
    pageName: {
      type: String
    },
    returnLink: {
      type: String
    },
  },
  data: () => ({

  }),
}
</script>