<template>
    <Header
        :pageName="pageName"
        :isIndex="isIndex"
        :returnLink="returnLink"
    ></Header>

    <main class="main">
        <section class="activity">
            <div class="app-container">
                <ActivityMenu
                    :additionalClass="menuAdditionalClass"
                    :items="menuItems"
                    :currentFilter="menuFilter"
                    @set-menu="updateMenu"
                ></ActivityMenu>
            </div>

            <swipe-my
                class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-main
                        swipe-modal--activity-bonus-main"
                v-model="menuModels.bonusPoints.main"
                :contents-height="heightActivityMain"
                :contents-height-middle="heightActivityMainTop"
                :no-close="true"
                border-top-radius="30px"
                ref="activityBonusPointsMain"
                @type="changeTopBonusPoints"
            >
                <div class="app-container">
                    <div class="coffee-widget">
                        <div class="coffee-widget__top-block coffee-widget__top-block--alt">
                            <div class="coffee-widget__top-block-row">
                                <span class="coffee-widget__top-block-row-text">Вы накопили</span>
                            </div>
                            <div class="coffee-widget__top-block-counter-wrapper">
                                <p class="coffee-widget__top-block-text-counter coffee-widget__top-block-text-counter--alt">
                                    <span class="coffee-widget__top-block-text-counter-count coffee-widget__top-block-text-counter-count--fav">{{ bonusPointsCurrent }}</span>
                                    <span class="coffee-widget__top-block-text-counter-total
                                                coffee-widget__top-block-text-counter-total--fav
                                                coffee-widget__top-block-text-counter-total--points"
                                    >
                                        баллов
                                    </span>
                                </p>
                            </div>

                            <div class="coffee-widget__top-block-pale-text">
                                <p>
                                    1 балл равен 1 рублю<br>
                                    * Оплачивайте баллами до 90% покупки
                                </p>
                            </div>
                        </div>

                        <div class="coffee-widget__bottom-block">
                            <p class="coffee-widget__bottom-block-text-wrapper">
                                <span>
                                    <b>Все Дома</b>
                                </span>
                                <span>булочная</span>
                            </p>
                        </div>
                    </div>
                </div>

                <swipe-my
                    class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-inner
                        swipe-modal--activity-bonus-inner"
                    :class="activityBonusPointsMainType == 0 && activityBonusPointsInnerType == 0 ? 'swipe-modal--activity-overflow-hidden' : ''"
                    v-model="menuModels.bonusPoints.inner"
                    :contents-height="bonusPointsHeight2"
                    :contents-height-middle="bonusPointsHeight1"
                    border-top-radius="30px"
                    ref="activityBonusPointsInner"
                    :no-close="true"
                    @type="bonusPointsInnerType"
                >
                    <div
                        class="app-container"
                        @touchstart="stoppag"
                    >
                        <div class="activity__bonus-list">
                            <div
                                class="activity__bonus-item bonus-item"
                                v-for="item in bonusPointsItems"
                            >
                                <p class="bonus-item__top-row">
                                    <span class="bonus-item__top-row-text">
                                        Чек на сумму<br>
                                        {{ item.sum }} рублей
                                    </span>

                                    <span class="bonus-item__top-row-text">
                                        Всего баллов после покупки
                                    </span>
                                </p>

                                <p class="bonus-item__bottom-row">
                                    <span class="bonus-item__bottom-row-text">
                                        {{ item.dateTime }}<br>
                                        {{ item.address }}
                                    </span>

                                    <span class="bonus-item__bottom-row-text">
                                        {{ item.balance }} баллов
                                    </span>
                                </p>

                                <span
                                    class="bonus-item__points"
                                    :class="item.withdraw ? 'bonus-item__points--withdraw' : ''"
                                >
                                    <span class="bonus-item__points-amount">
                                        {{ item.withdraw ? '-' + item.points : '+' + item.points }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </swipe-my>
            </swipe-my>

            <swipe-my
                class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-main
                        swipe-modal--activity-favorite-main"
                v-model="menuModels.favorite.main"
                :contents-height="heightActivityMain"
                :contents-height-middle="heightActivityMainTop"
                :no-close="true"
                border-top-radius="30px"
                ref="activityFavoriteMain"
                @type="changeTopFavorite"
            >
                <div class="app-container">
                    <div class="coffee-widget">
                        <div class="coffee-widget__top-block coffee-widget__top-block--alt">
                            <div class="coffee-widget__top-block-row">
                                <span class="coffee-widget__top-block-row-text">Сумма покупок за 5 дней</span>

                                <span class="coffee-widget__top-block-row-text">20%</span>
                            </div>
                            <div class="coffee-widget__top-block-counter-wrapper">
                                <p class="coffee-widget__top-block-text-counter coffee-widget__top-block-text-counter--alt">
                                    <span class="coffee-widget__top-block-text-counter-count coffee-widget__top-block-text-counter-count--fav">{{ favoriteCurrentCount }} руб</span>
                                    <span class="coffee-widget__top-block-text-counter-divider coffee-widget__top-block-text-counter-divider--fav">/</span>
                                    <span class="coffee-widget__top-block-text-counter-total coffee-widget__top-block-text-counter-total--fav">{{ favoriteTotalCount }} руб</span>
                                </p>
                            </div>

                            <div
                                class="coffee-widget__top-block-pale-text"
                                v-if="favoriteCurrentCount < favoriteTotalCount"
                            >
                                <p>
                                    Вы сможете выбрать любимый продукт,
                                    когда совершите покупки на общую сумму 500 руб
                                    в течение 5 дней и покупать его со скидкой 20%
                                </p>

                                <p>
                                    * Cтоимость купленного кофе в сумме не учитывается
                                </p>
                            </div>

                            <div
                                class="coffee-widget__top-block-pale-text"
                                v-if="favoriteCurrentCount === favoriteTotalCount && favoriteSelected === false"
                            >
                                <p style="text-align: center;">
                                    Поздравляем!
                                </p>

                                <p style="text-align: center;">
                                    Вам доступен выбор любимого продукта!
                                </p>
                            </div>

                            <div
                                class="coffee-widget__top-block-pale-text"
                                v-if="favoriteCurrentCount === favoriteTotalCount && favoriteSelected !== false"
                            >
                                <p>
                                    Вы можете поменять любимый продукт
                                </p>
                            </div>

                            <button
                                class="coffee-widget__select-product btn btn--accent"
                                v-if="favoriteCurrentCount === favoriteTotalCount && favoriteSelected === false"
                            >
                                Выбрать любимый продукт
                            </button>

                            <button
                                class="coffee-widget__change-product btn"
                                v-if="favoriteCurrentCount === favoriteTotalCount && favoriteSelected !== false"
                            >
                                Поменять любимый продукт
                            </button>
                        </div>

                        <div
                            class="coffee-widget__bottom-block"
                            v-if="favoriteCurrentCount < favoriteTotalCount"
                        >
                            <p class="coffee-widget__bottom-block-text-wrapper">
                                <span>
                                    <b>Все Дома</b>
                                </span>
                                <span>булочная</span>
                            </p>
                        </div>
                    </div>
                </div>

                <swipe-my
                    class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-inner
                        swipe-modal--activity-favorite-inner"
                    :class="activityFavoriteMainType == 0 && activityFavoriteInnerType == 0 ? 'swipe-modal--activity-overflow-hidden' : ''"
                    v-model="menuModels.favorite.inner"
                    v-if="favoriteCurrentCount === favoriteTotalCount && favoriteSelected !== false"
                    :contents-height="favoriteHeight2"
                    :contents-height-middle="favoriteHeight1"
                    border-top-radius="30px"
                    ref="activityFavoriteInner"
                    :no-close="true"
                    @type="favoriteInnerType"
                >
                    <div class="app-container" @touchstart="stoppag">
                        <div class="activity__coffee-list">
                            <button
                                class="activity__product catalog-item catalog-item--high"
                                v-for="product in favoriteItems"
                                :key="product.id"
                            >
                                <div class="catalog-item__img-block">
                                    <img
                                        class="catalog-item__img"
                                        :src="product.img"
                                        :alt="product.name"
                                    >
                                </div>

                                <div class="catalog-item__text-block">
                                    <p class="catalog-item__name-measure-heart">
                                        <span class="catalog-item__name">{{ product.name }}</span>

                                        <p class="catalog-item__measure-heart">
                                            <span class="catalog-item__measure">{{ product.measure }}</span>

                                            <span
                                            class="catalog-item__heart"
                                            v-if="product.fav"
                                            ></span>
                                        </p>
                                    </p>

                                    <span class="catalog-item__comment">Закончится 9 апреля</span>

                                    <span class="catalog-item__bonus">+{{ product.bonusPoints }}</span>
                                </div>

                                <span class="catalog-item__arrow"></span>

                                <span class="catalog-item__price">
                                    <span
                                        class="catalog-item__price-old"
                                        v-if="product.priceOld"
                                    >
                                        {{ product.priceOld }}&nbsp;р
                                    </span>
                                    {{ product.price }}&nbsp;р
                                </span>
                            </button>
                        </div>
                    </div>
                </swipe-my>
            </swipe-my>

            <swipe-my
                class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-main
                        swipe-modal--activity-checks-main
                        swipe-modal--activity-overflow-hidden"
                v-model="menuModels.checks.main"
                :contents-height="heightActivityMain"
                :contents-height-middle="heightActivityMainTop"
                :no-close="true"
                border-top-radius="30px"
                ref="activityChecksMain"
            >
                <div class="app-container activity__container" @touchstart="stoppag">
                    <p class="activity__intro-text">
                        {{ checksMessage === false ? checksDefaultMessage : checksMessage }}
                    </p>

                    <div class="activity-swipe activity-swipe--checks">
                        <div class="activity-swipe__level">
                            <div
                                class="activity-swipe__parent-block"
                                v-for="checksBlock in checksArray"
                                :key="checksBlock.id"
                                :data-id="checksBlock.id"
                            >
                                <div class="activity-swipe__swiper activity-swipe__swiper--main">
                                    <div class="activity-swipe__swiper-back">
                                        <span class="activity-swipe__swiper-back-text activity-swipe__swiper-back-text--left activity-swipe__swiper-back-text--uppercase">
                                            сумма<br>
                                            {{ checksBlock.backText }}
                                        </span>
                                        <span class="activity-swipe__swiper-back-text activity-swipe__swiper-back-text--right activity-swipe__swiper-back-text--uppercase">
                                            сумма<br>
                                            {{ checksBlock.backText }}
                                        </span>
                                    </div>

                                    <input
                                        type="range"
                                        class="activity-swipe__swiper-range"
                                        min="0"
                                        max="100"
                                        v-model="checksBlock.hideCoefficient"
                                        @input="rangeToggleStyles($event)"
                                        @change="stickToRangeEdge($event)"
                                        data-tab="checks"
                                        data-tab-inner="false"
                                    >

                                    <span class="activity-swipe__sum">{{ checksBlock.sum }} руб</span>
                                </div>

                                <div class="activity-swipe__child-block" style="display: none;">
                                    <div class="activity__purchases-list activity-swipe__level">
                                        <div
                                            class="activity__purchase activity-swipe__parent-block"
                                            v-for="buy in checksBlock.items"
                                            :key="buy.id"
                                            :data-id="buy.id"
                                        >
                                            <div class="activity-swipe__swiper activity-swipe__swiper--main">
                                                <div class="activity-swipe__swiper-back activity-swipe__swiper-back--alt">
                                                    <span class="activity-swipe__swiper-back-text activity-swipe__swiper-back-text--left">
                                                        <span class="activity-swipe__swiper-back-text-heading">покупка {{ buy.id }}</span><br>
                                                        <span class="activity-swipe__swiper-back-text-small">{{ buy.dateTime }},</span><br>
                                                        <span class="activity-swipe__swiper-back-text-small">{{ buy.address }}</span>
                                                    </span>
                                                    <span class="activity-swipe__swiper-back-text activity-swipe__swiper-back-text--right">
                                                        <span class="activity-swipe__swiper-back-text-heading">покупка {{ buy.id }}</span><br>
                                                        <span class="activity-swipe__swiper-back-text-small">{{ buy.dateTime }},</span><br>
                                                        <span class="activity-swipe__swiper-back-text-small">{{ buy.address }}</span>
                                                    </span>
                                                </div>

                                                <input
                                                    type="range"
                                                    class="activity-swipe__swiper-range activity-swipe__swiper-range--alt"
                                                    min="0"
                                                    max="100"
                                                    v-model="buy.hideCoefficient"
                                                    @input="rangeToggleStyles($event)"
                                                    @change="stickToRangeEdge($event)"
                                                    data-tab="checks"
                                                    data-tab-inner="true"
                                                >

                                                <span class="activity-swipe__sum activity-swipe__sum--alt">{{ buy.sum }} руб</span>
                                            </div>

                                            <div class="activity-swipe__child-block" style="display: none;">
                                                <div class="activity__check-products-list">
                                                    <div
                                                        class="activity__check-product check-product"
                                                        v-for="product in buy.items"
                                                        :key="product.id"
                                                    >
                                                        <div class="check-product__img-block">
                                                            <img
                                                                class="check-product__img"
                                                                :src="product.img"
                                                                :alt="product.name"
                                                            >
                                                        </div>

                                                        <div class="check-product__text-block">
                                                            <span class="check-product__name">{{ product.name }}</span>

                                                            <p class="check-product__date-address">
                                                                <span class="check-product__date">{{ buy.dateTime }},</span>

                                                                <span class="check-product__address">{{ buy.address }}</span>
                                                            </p>

                                                            <p class="check-product__measure-price">
                                                                <span class="check-product__measure">{{ product.measure }}</span>

                                                                <span class="check-product__price">{{ product.price }}&nbsp;р</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swipe-my>

            <swipe-my
                class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-main
                        swipe-modal--activity-purchases-main
                        swipe-modal--activity-overflow-hidden"
                v-model="menuModels.purchases.main"
                :contents-height="heightActivityMain"
                :contents-height-middle="heightActivityMainTop"
                :no-close="true"
                border-top-radius="30px"
                ref="activityPurchasesMain"
            >
                <div class="app-container activity__container" @touchstart="stoppag">
                    <p class="activity__intro-text">
                        {{ purchasesMessage === false ? purchasesDefaultMessage : 'Твои покупки на ' + purchasesMessage }}
                    </p>

                    <div class="activity-swipe activity-swipe--purchases">
                        <div class="activity-swipe__level">
                            <div
                                class="activity-swipe__parent-block"
                                v-for="purchasesBlock in purchasesArray"
                                :key="purchasesBlock.id"
                                :data-id="purchasesBlock.id"
                            >
                                <div class="activity-swipe__swiper activity-swipe__swiper--main">
                                    <div class="activity-swipe__swiper-back">
                                        <span class="activity-swipe__swiper-back-text activity-swipe__swiper-back-text--left">{{ purchasesBlock.backText }}</span>
                                        <span class="activity-swipe__swiper-back-text activity-swipe__swiper-back-text--right">{{ purchasesBlock.backText }}</span>
                                    </div>

                                    <input
                                        type="range"
                                        class="activity-swipe__swiper-range"
                                        min="0"
                                        max="100"
                                        v-model="purchasesBlock.hideCoefficient"
                                        @input="rangeToggleStyles($event)"
                                        @change="stickToRangeEdge($event)"
                                        data-tab="purchases"
                                        data-tab-inner="false"
                                    >

                                    <span class="activity-swipe__sum">{{ purchasesBlock.sum }} руб</span>
                                </div>
                                <div class="activity-swipe__child-block" style="display: none;">
                                    <div class="activity__products-list">
                                        <button
                                            class="activity__product catalog-item"
                                            v-for="product in purchasesBlock.items"
                                            :key="product.id"
                                        >
                                            <div class="catalog-item__img-block">
                                            <img
                                                class="catalog-item__img"
                                                :src="product.img"
                                                :alt="product.name"
                                            >
                                            </div>

                                            <div class="catalog-item__text-block">
                                            <p class="catalog-item__name-measure-heart">
                                                <span class="catalog-item__name">{{ product.name }}</span>

                                                <p class="catalog-item__measure-heart">
                                                <span class="catalog-item__measure">{{ product.measure }}</span>

                                                <span
                                                    class="catalog-item__heart"
                                                    v-if="product.fav"
                                                ></span>
                                                </p>
                                            </p>

                                            <span class="catalog-item__bonus">+{{ product.bonusPoints }}</span>
                                            </div>

                                            <span class="catalog-item__arrow"></span>

                                            <span class="catalog-item__price">{{ product.price }}&nbsp;р</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swipe-my>

            <swipe-my
                class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-main
                        swipe-modal--activity-coffee-main"
                v-model="menuModels.coffee.main"
                :contents-height="heightActivityMain"
                :contents-height-middle="heightActivityMainTop"
                :no-close="true"
                border-top-radius="30px"
                ref="activityCoffeeMain"
                @type="changeTopCoffee"
            >
                <div class="app-container">
                    <div class="coffee-widget">
                        <div class="coffee-widget__top-block coffee-widget__top-block--alt">
                            <h2 class="coffee-widget__top-block-heading">
                                {{ coffeeCountCurrent === coffeeCountTotal ? coffeeDoneMsg : coffeeLeftToBuyMsg + ' до кофе за 1 рубль' }}
                            </h2>
                            <router-link class="coffee-widget__top-block-counter-wrapper" to="#">
                            <p class="coffee-widget__top-block-text-counter coffee-widget__top-block-text-counter--alt">
                                <span class="coffee-widget__top-block-text-counter-count coffee-widget__top-block-text-counter-count--alt">{{ coffeeCountCurrent }}</span>
                                <span class="coffee-widget__top-block-text-counter-divider coffee-widget__top-block-text-counter-divider--alt">/</span>
                                <span class="coffee-widget__top-block-text-counter-total coffee-widget__top-block-text-counter-total--alt">{{ coffeeCountTotal }}</span>
                                <span class="coffee-widget__top-block-text-counter-desc coffee-widget__top-block-text-counter-desc--alt">чашек кофе</span>
                            </p>

                            <div class="coffee-widget__top-block-icon-counter coffee-icon-counter">
                                <svg class="coffee-icon-counter__svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 328 69" style="enable-background:new 0 0 328 69;" xml:space="preserve">
                                    <g>
                                        <path class="coffee-icon-counter__liquid" :class="coffeeCountCurrent !== coffeeCountTotal && coffeeCountCurrent >= 1 ? 'coffee-icon-counter__liquid--full' : ''" d="M1.7,52.3c0-3,2.4-5.4,5.4-5.4h14.2c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4H7.1C4.1,57.7,1.7,55.2,1.7,52.3z"/>
                                        <path fill="#1E1E1E" d="M0,49.3v-5.8h3.8v2h20.8v-2h3.8v5.8C28.3,55.8,23.3,61,17,61h-5.7C5.1,61,0,55.8,0,49.3z M17,57.1
                                        c4.2,0,7.5-3.5,7.5-7.8H3.8c0,4.3,3.4,7.8,7.5,7.8H17z"/>
                                        <path fill="#1E1E1E" d="M33.3,46H30v3.3h3.3V46z"/>
                                        <path fill="#1E1E1E" d="M9.2,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C10.9,42.3,9.2,41.5,9.2,39.4z"/>
                                        <path fill="#1E1E1E" d="M14.6,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C16.3,42.3,14.6,41.5,14.6,39.4L14.6,39.4z"/>
                                    </g>
                                    <g>
                                        <path class="coffee-icon-counter__liquid" :class="coffeeCountCurrent !== coffeeCountTotal && coffeeCountCurrent >= 2 ? 'coffee-icon-counter__liquid--full' : ''" d="M44.7,52.3c0-3,2.4-5.4,5.4-5.4h14.2c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4H50.1
                                        C47.1,57.7,44.7,55.2,44.7,52.3z"/>
                                        <path fill="#1E1E1E" d="M43,49.3v-5.8h3.8v2h20.8v-2h3.8v5.8C71.3,55.8,66.3,61,60,61h-5.7C48.1,61,43,55.8,43,49.3z M60,57.1
                                        c4.2,0,7.5-3.5,7.5-7.8H46.8c0,4.3,3.4,7.8,7.5,7.8H60z"/>
                                        <path fill="#1E1E1E" d="M76.3,46H73v3.3h3.3V46z"/>
                                        <path fill="#1E1E1E" d="M52.2,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C53.9,42.3,52.2,41.5,52.2,39.4z"/>
                                        <path fill="#1E1E1E" d="M57.6,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C59.3,42.3,57.6,41.5,57.6,39.4L57.6,39.4z"/>
                                    </g>
                                    <g>
                                        <path class="coffee-icon-counter__liquid" :class="coffeeCountCurrent !== coffeeCountTotal && coffeeCountCurrent >= 3 ? 'coffee-icon-counter__liquid--full' : ''" d="M87.7,52.3c0-3,2.4-5.4,5.4-5.4h14.2c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4H93.1
                                        C90.1,57.7,87.7,55.2,87.7,52.3z"/>
                                        <path fill="#1E1E1E" d="M86,49.3v-5.8h3.8v2h20.8v-2h3.8v5.8c0,6.4-5.1,11.7-11.3,11.7h-5.7C91.1,61,86,55.8,86,49.3z M103,57.1
                                        c4.2,0,7.5-3.5,7.5-7.8H89.8c0,4.3,3.4,7.8,7.5,7.8H103z"/>
                                        <path fill="#1E1E1E" d="M119.3,46H116v3.3h3.3V46z"/>
                                        <path fill="#1E1E1E" d="M95.2,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C96.9,42.3,95.2,41.5,95.2,39.4z"/>
                                        <path fill="#1E1E1E" d="M100.6,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C102.3,42.3,100.6,41.5,100.6,39.4L100.6,39.4z"/>
                                    </g>
                                    <g>
                                        <path class="coffee-icon-counter__liquid" :class="coffeeCountCurrent !== coffeeCountTotal && coffeeCountCurrent >= 4 ? 'coffee-icon-counter__liquid--full' : ''" d="M130.7,52.3c0-3,2.4-5.4,5.4-5.4h14.2c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4h-14.2
                                        C133.1,57.7,130.7,55.2,130.7,52.3z"/>
                                        <path fill="#1E1E1E" d="M129,49.3v-5.8h3.8v2h20.8v-2h3.8v5.8c0,6.4-5.1,11.7-11.3,11.7h-5.7C134.1,61,129,55.8,129,49.3z M146,57.1
                                        c4.2,0,7.5-3.5,7.5-7.8h-20.8c0,4.3,3.4,7.8,7.5,7.8H146z"/>
                                        <path fill="#1E1E1E" d="M162.3,46H159v3.3h3.3V46z"/>
                                        <path fill="#1E1E1E" d="M138.2,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C139.9,42.3,138.2,41.5,138.2,39.4z"/>
                                        <path fill="#1E1E1E" d="M143.6,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C145.3,42.3,143.6,41.5,143.6,39.4L143.6,39.4z"/>
                                    </g>
                                    <g>
                                        <path class="coffee-icon-counter__liquid" :class="coffeeCountCurrent !== coffeeCountTotal && coffeeCountCurrent >= 5 ? 'coffee-icon-counter__liquid--full' : ''" d="M173.7,52.3c0-3,2.4-5.4,5.4-5.4h14.2c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4h-14.2
                                        C176.1,57.7,173.7,55.2,173.7,52.3z"/>
                                        <path fill="#1E1E1E" d="M172,49.3v-5.8h3.8v2h20.8v-2h3.8v5.8c0,6.4-5.1,11.7-11.3,11.7h-5.7C177.1,61,172,55.8,172,49.3z M189,57.1
                                        c4.2,0,7.5-3.5,7.5-7.8h-20.8c0,4.3,3.4,7.8,7.5,7.8H189z"/>
                                        <path fill="#1E1E1E" d="M205.3,46H202v3.3h3.3V46z"/>
                                        <path fill="#1E1E1E" d="M181.2,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C182.9,42.3,181.2,41.5,181.2,39.4z"/>
                                        <path fill="#1E1E1E" d="M186.6,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C188.3,42.3,186.6,41.5,186.6,39.4L186.6,39.4z"/>
                                    </g>
                                    <g>
                                        <path class="coffee-icon-counter__liquid" :class="coffeeCountCurrent !== coffeeCountTotal && coffeeCountCurrent >= 6 ? 'coffee-icon-counter__liquid--full' : ''" d="M216.7,52.3c0-3,2.4-5.4,5.4-5.4h14.2c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4h-14.2
                                        C219.1,57.7,216.7,55.2,216.7,52.3z"/>
                                        <path fill="#1E1E1E" d="M215,49.3v-5.8h3.8v2h20.8v-2h3.8v5.8c0,6.4-5.1,11.7-11.3,11.7h-5.7C220.1,61,215,55.8,215,49.3z M232,57.1
                                        c4.2,0,7.5-3.5,7.5-7.8h-20.8c0,4.3,3.4,7.8,7.5,7.8H232z"/>
                                        <path fill="#1E1E1E" d="M248.3,46H245v3.3h3.3V46z"/>
                                        <path fill="#1E1E1E" d="M224.2,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C225.9,42.4,224.2,41.5,224.2,39.4z"/>
                                        <path fill="#1E1E1E" d="M229.6,39.4c0-2.9,3-3,3-4.3c0-1-1.7-1.8-2.4-2.3l1.5-1.8c1.6,1.2,3.3,2,3.3,4.1c0,2.9-3,2.9-3,4.3
                                        c0,1,1.7,1.8,2.3,2.3l-1.5,1.9C231.3,42.3,229.6,41.5,229.6,39.4L229.6,39.4z"/>
                                    </g>
                                    <g>
                                        <path class="coffee-icon-counter__liquid" :class="coffeeCountCurrent === coffeeCountTotal ? 'coffee-icon-counter__liquid--full' : ''" d="M279.9,52.9c0-3,2.4-5.4,5.4-5.4h14.2c3,0,5.4,2.4,5.4,5.4s-2.4,5.4-5.4,5.4h-14.2
                                        C282.3,58.3,279.9,55.9,279.9,52.9z"/>
                                        <path fill="#1E1E1E" d="M277.9,50.1v-5.6h3.9v1.9H303v-1.9h3.9v5.6c0,6.1-5.2,11.1-11.6,11.1h-5.8C283.1,61.2,277.9,56.2,277.9,50.1z
                                        M295.3,57.5c4.3,0,7.7-3.4,7.7-7.4h-21.2c0,4.1,3.4,7.4,7.7,7.4H295.3z"/>
                                        <path fill="#1E1E1E" d="M313.5,46.7H309v4.5h4.5V46.7z"/>
                                        <path fill="#1E1E1E" d="M293.5,40.1c0-3.1,3.1-3.2,3.1-4.6c0-1-1.8-1.9-2.4-2.4l1.5-2c1.7,1.2,3.4,2.1,3.4,4.4c0,3.1-3,3.1-3,4.6
                                        c0,1,1.7,1.9,2.4,2.4l-1.5,2C295.2,43.3,293.5,42.4,293.5,40.1L293.5,40.1z"/>
                                        <path fill="#1E1E1E" d="M286.8,40.1c0-3.1,3.1-3.2,3.1-4.6c0-1-1.8-1.9-2.4-2.4l1.5-2c1.7,1.2,3.4,2.1,3.4,4.4c0,3.1-3,3.1-3,4.6
                                        c0,1,1.7,1.9,2.4,2.4l-1.5,2C288.6,43.3,286.8,42.4,286.8,40.1L286.8,40.1z"/>
                                    </g>
                                    <g transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
                                        <path fill="none" stroke="#1E1E1E" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" d="M244.4,492.3h-48.6v9.1h48.6V492.3z"/>
                                        <path fill="none" stroke="#1E1E1E" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" d="M241.3,492.3v-30.5h-42.5v30.5"/>
                                        <path fill="none" stroke="#1E1E1E" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" d="M212.5,501.3c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.7,4.5,4.5c1.7-0.1,3.2-0.8,4-2.4"/>
                                        <path fill="none" stroke="#1E1E1E" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" d="M223.7,508c0.9,1.6,2.4,2.3,4,2.4c2.5,0.1,4.5-2,4.5-4.5s-2-4.5-4.5-4.5"/>
                                        <path fill="none" stroke="#1E1E1E" stroke-width="3.5" stroke-linecap="round" stroke-linejoin="round" d="M220.1,501.3l-3.6,6.7 M220.1,501.3l3.6,6.7"/>
                                    </g>
                                </svg>
                            </div>
                            </router-link>
                        </div>

                        <div class="coffee-widget__bottom-block">
                            <p class="coffee-widget__bottom-block-text-wrapper">
                            <span>
                                <b>Все Дома</b>
                            </span>
                            <span>булочная</span>
                            </p>
                        </div>
                    </div>
                </div>

                <swipe-my
                    class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-inner
                        swipe-modal--activity-coffee-inner"
                    :class="activityCoffeeMainType == 0 && activityCoffeeInnerType == 0 ? 'swipe-modal--activity-overflow-hidden' : ''"
                    v-model="menuModels.coffee.inner"
                    :contents-height="coffeeHeight2"
                    :contents-height-middle="coffeeHeight1"
                    border-top-radius="30px"
                    ref="activityCoffeeInner"
                    :no-close="true"
                    @type="coffeeInnerType"
                >
                    <div class="app-container" @touchstart="stoppag">
                        <div class="activity__coffee-list">
                            <button
                                class="activity__product catalog-item catalog-item--high"
                                v-for="product in coffeeItems"
                                :key="product.id"
                            >
                                <div class="catalog-item__img-block">
                                <img
                                    class="catalog-item__img"
                                    :src="product.img"
                                    :alt="product.name"
                                >
                                </div>

                                <div class="catalog-item__text-block">
                                <p class="catalog-item__name-measure-heart">
                                    <span class="catalog-item__name">{{ product.name }}</span>

                                    <p class="catalog-item__measure-heart">
                                    <span class="catalog-item__measure">{{ product.measure }}</span>

                                    <span
                                        class="catalog-item__heart"
                                        v-if="product.fav"
                                    ></span>
                                    </p>
                                </p>

                                <span class="catalog-item__comment">Бесплатная чашка кофе</span>

                                <span class="catalog-item__bonus">+{{ product.bonusPoints }}</span>
                                </div>

                                <span class="catalog-item__arrow"></span>

                                <span class="catalog-item__price">{{ product.price }}&nbsp;р</span>
                            </button>
                        </div>
                    </div>
                </swipe-my>
            </swipe-my>

            <swipe-my
                class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-main
                        swipe-modal--activity-stats-main"
                v-model="menuModels.stats.main"
                :contents-height="heightActivityMain"
                :contents-height-middle="heightActivityMainTop"
                :no-close="true"
                border-top-radius="30px"
                ref="activityStatsMain"
                @type="changeTopStats"
            >
                <div class="app-container">
                    <div class="coffee-widget">
                        <div class="coffee-widget__top-block coffee-widget__top-block--alt">
                            <div
                                class="coffee-widget__top-block-row"
                                @click="statsShowMonthSelect = !statsShowMonthSelect"
                            >
                                <span class="coffee-widget__top-block-row-text coffee-widget__top-block-row-text--big">Статистика за {{ statsShowMonthSelect === false ? statsCurrentMonth.name : '' }}</span>

                                <span
                                    class="coffee-widget__arrow"
                                    ref="statsArrow"
                                ></span>
                            </div>

                            <div
                                class="coffee-widget__diagram"
                                ref="statsDiagram"
                            >
                                <DonutChart
                                    :statsData="statsCurrentMonth"
                                ></DonutChart>
                            </div>

                            <div
                                class="coffee-widget__months"
                                ref="statsMonths"
                                style="display: none; opacity: 0;"
                            >
                                <div
                                    class="coffee-widget__month"
                                    v-for="month in statsArray"
                                >
                                    <input
                                        class="coffee-widget__month-radio hidden-input"
                                        type="radio"
                                        name="statsMonths"
                                        :id="month.id"
                                        :value="month.id"
                                        :checked="month.id === statsCurrentMonth.id ? true : false"
                                        @change="changeStatsMonth($event, month.id)"
                                    >
                                    <label
                                        class="coffee-widget__month-label"
                                        :for="month.id"
                                    >
                                        {{ month.name }}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="coffee-widget__bottom-block">
                            <p class="coffee-widget__bottom-block-text-wrapper">
                                <span>
                                    <b>Все Дома</b>
                                </span>
                                <span>булочная</span>
                            </p>

                            <p class="coffee-widget__bottom-block-points-wrapper">
                                <span class="coffee-widget__bottom-block-points">{{ statsCurrentMonth.spent.total }}</span>
                                <span class="coffee-widget__bottom-block-points-text">рублей</span>
                            </p>
                        </div>
                    </div>
                </div>

                <swipe-my
                    class="swipe-modal
                        swipe-modal--activity
                        swipe-modal--activity-inner
                        swipe-modal--activity-stats-inner"
                    :class="activityStatsMainType == 0 && activityStatsInnerType == 0 ? 'swipe-modal--activity-overflow-hidden' : ''"
                    v-model="menuModels.stats.inner"
                    :contents-height="statsHeight2"
                    :contents-height-middle="statsHeight1"
                    border-top-radius="30px"
                    ref="activityStatsInner"
                    :no-close="true"
                    @type="statsInnerType"
                >
                    <div class="app-container activity__container" @touchstart="stoppag($event, menuModels.stats.main, menuModels.stats.inner)">
                        <p class="activity__intro-text">
                            {{ statsMessage === false ? statsDefaultMessage : statsMessage }}
                        </p>

                        <div class="activity-swipe activity-swipe--stats">
                            <div class="activity__purchases-list activity-swipe__level">
                                <div
                                    class="activity__purchase activity-swipe__parent-block"
                                    v-for="category in statsCurrentMonth.spent.categories"
                                    :key="category.id"
                                    :data-id="category.id"
                                >
                                    <div class="activity-swipe__swiper activity-swipe__swiper--main">
                                        <div class="activity-swipe__swiper-back activity-swipe__swiper-back--stats">
                                            <span class="activity-swipe__swiper-back-text activity-swipe__swiper-back-text--left">
                                                <span class="activity-swipe__swiper-back-text-big">{{ category.sum }} р</span><br>
                                            </span>
                                            <span class="activity-swipe__swiper-back-text activity-swipe__swiper-back-text--center">
                                                <span class="activity-swipe__swiper-back-text-big">{{ category.name }}</span><br>
                                            </span>
                                            <span class="activity-swipe__swiper-back-text activity-swipe__swiper-back-text--right">
                                                <span class="activity-swipe__swiper-back-text-big">{{ category.sum }} р</span><br>
                                            </span>
                                        </div>

                                        <input
                                            type="range"
                                            class="activity-swipe__swiper-range activity-swipe__swiper-range--stats"
                                            min="0"
                                            max="100"
                                            v-model="category.hideCoefficient"
                                            @input="rangeToggleStyles($event)"
                                            @change="stickToRangeEdge($event)"
                                            data-tab="stats"
                                            data-tab-inner="false"
                                            :style="'--thumb-color: ' + category.color"
                                        >

                                        <span class="activity-swipe__sum activity-swipe__sum--stats">{{ category.percent }}%</span>
                                    </div>

                                    <div class="activity-swipe__child-block" style="display: none;">
                                        <div class="activity__check-products-list activity__check-products-list--stats">
                                            <div
                                                class="activity__check-product check-product"
                                                v-for="product in category.items"
                                                :key="product.id"
                                            >
                                                <div class="check-product__img-block">
                                                    <img
                                                        class="check-product__img"
                                                        :src="product.img"
                                                        :alt="product.name"
                                                    >
                                                </div>

                                                <div class="check-product__text-block">
                                                    <span class="check-product__name">{{ product.name }}</span>

                                                    <p class="check-product__date-address">
                                                        <span class="check-product__date">{{ product.dateTime }},</span>

                                                        <span class="check-product__address">{{ product.address }}</span>
                                                    </p>

                                                    <p class="check-product__measure-price">
                                                        <span class="check-product__measure">{{ product.measure }}</span>

                                                        <span class="check-product__price">{{ product.price }}&nbsp;р</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swipe-my>
            </swipe-my>
        </section>
    </main>

    <Footer
        @call-map="showMap"
    ></Footer>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--map"
        v-model="map"
        contents-height="90vh"
        border-top-radius="30px"
        ref="map"
    >
        <div class="map">
            <button
              class="map__location-btn"
            ></button>

            <div class="app-container map__form-container">
                <h2 class="map__heading">Адреса булочных ({{ mapItems.length }})</h2>

                <form class="map__form" action="#" method="get">
                    <input class="map__form-input" type="text" placeholder="Найти булочную...">
                    <button class="btn map__form-submit"></button>
                </form>
            </div>

            <div class="map__map-wrapper" @touchstart="stoppag">
                <YandexMap
                    :coordinates="mapCenter"
                    :controls="mapControls"
                    :settings="settings"
                    @created="onMapInit"
                >
                    <YandexMarker
                        v-for="pin in mapItems"
                        :coordinates="[pin.mapLon, pin.mapLat]"
                        :marker-id="pin.id"
                        :options="mapPinOptions"
                        :key="pin.id"
                    >
                        <template #component>
                            <button
                                class="map__balloon"
                                @click="detailMap($event, pin.id)"
                                :ref="'balloon_' + pin.id"
                            >
                                <p class="map__balloon-text">
                                    <span class="map__balloon-address">{{ pin.address }}</span>
                                    <span class="map__balloon-schedule">{{ pin.schedule }}</span>
                                </p>
                                <span class="map__balloon-arrow"></span>
                            </button>
                        </template>
                    </YandexMarker>
                </YandexMap>
            </div>
        </div>

        <swipe-my
            class="swipe-modal swipe-modal--map-inner swipe-modal--activity-overflow-hidden"
            v-model="mapInner"
            contents-height="14.37vh"
            contents-height-middle="47.1vh"
            contents-height-top="calc(100% - 180px)"
            border-top-radius="30px"
            ref="mapInner"
            :no-close="true"
            @type="locationButtonAnimation"
            @touchstart="stoppag"
        >
            <div class="map-list">
                <div class="app-container">
                    <BtnMenu
                        :additionalClass="mapMenuAdditionalClass"
                        :items="mapMenuItems"
                        :currentFilter="mapMenuFilter"
                        @set-child-data="updateMapFilter"
                    ></BtnMenu>

                    <div class="map-list__item-wrapper">
                        <div class="map-list__items">
                            <button
                                class="map-list__item map-item"
                                v-for="item in filteredMapItems"
                                :key="item.id"
                                @click="detailMap($event, item.id)"
                                :ref="item.id"
                            >
                                <div class="map-item__img-block">
                                    <img
                                        class="map-item__img"
                                        :src="item.gallery[0]"
                                        :alt="item.address"
                                    >
                                </div>

                                <div class="map-item__text-block">
                                    <div class="map-item__text-block-top">
                                        <span class="map-item__address">{{ item.address }}</span>

                                        <span class="map-item__phone">{{ item.phone }}</span>
                                    </div>

                                    <div class="map-item__text-block-bottom">
                                        <span class="map-item__schedule">{{ item.schedule }}</span>

                                        <span class="map-item__rating">{{ item.rating }}</span>
                                    </div>
                                </div>

                                <span class="map-item__arrow"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </swipe-my>
        
        <swipe-modal
            class="swipe-modal swipe-modal--full-screen swipe-modal--map-detail"
            v-model="mapDetail"
            contents-height="86.8vh"
            border-top-radius="30px"
            ref="mapDetail"
            @touchstart="stoppag"
        >
            <div class="map-detail" @touchstart="stoppag">
                <div class="map-detail__slider-container">
                    <div class="app-container">
                        <h2 class="map-detail__heading">{{ mapDetail.address }}</h2>
                    </div>

                    <SliderDetailProduct
                        :slides="mapDetail.gallery"
                        additionalClass="detail-product-slider--map"
                    ></SliderDetailProduct>
                </div>

                <div class="map-detail__content">
                    <div class="map-detail__content-container">
                        <div class="map-detail__content-text">
                            <p class="map-detail__schedule-rating">
                                <span class="map-detail__span map-detail__span--schedule">{{ mapDetail.schedule }}</span>
                                <span class="map-detail__span map-detail__span--rating">{{ mapDetail.rating }}</span>
                            </p>

                            <span class="map-detail__span map-detail__span--phone">{{ mapDetail.phone }}</span>

                            <span class="map-detail__span map-detail__span--subway">{{ mapDetail.subway }}</span>
                        </div>

                        <button class="map-detail__content-btn btn">Построить маршрут</button>
                    </div>
                </div>
            </div>
        </swipe-modal>
    </swipe-modal>
</template>

<script>
import Footer from '../components/app/Footer';
import Header from '../components/app/Header';
import ActivityMenu from '../components/app/ActivityMenu';
import BtnMenu from '../components/app/BtnMenu';
import SliderDetailProduct from '../components/app/SliderDetailProduct';
import DonutChart from '../components/app/DonutChart';

import {swipeModal} from '@takuma-ru/vue-swipe-modal'
import {swipeMy} from '@/swipe-my.js'
import {YandexMap} from 'vue-yandex-maps'

export default {
    data: () => ({
        activityBonusPointsMainType: 0,
        activityBonusPointsInnerType: 0,
        activityFavoriteMainType: 0,
        activityFavoriteInnerType: 0,
        activityCoffeeMainType: 0,
        activityCoffeeInnerType: 0,
        activityStatsMainType: 0,
        activityStatsInnerType: 0,

        mapMenuFilter: 'mapClosest',
        mapMenuAdditionalClass: 'btn-menu__menu--border',
        mapMenuItems: [
            {
                text: 'Рядом',
                id: 'mapClosest',
            },
            {
                text: 'Последние',
                id: 'mapLast',
            },
        ],
        settings: {
          apiKey: '', // Индивидуальный ключ API
          lang: 'ru_RU', // Используемый язык
          coordorder: 'latlong', // Порядок задания географических координат
          debug: false, // Режим отладки
          version: '2.1' // Версия Я.Карт
        },
        mapCenter: ['59.933385', '30.361019'],
        mapControls: [],
        mapPinOptions: {
            iconLayout: 'default#image',
            iconImageHref: '/img/map-pin.svg',
            iconImageSize: [60, 60],
            iconImageOffset: [-30, -30],
        },
        map: false,
        mapInner: false,
        mapDetail: false,
        mapItems: [
            {
                id: 'map1',
                mapLon: '59.856894',
                mapLat: '30.380818',
                address: 'Пр. Славы, 16',
                phone: '+7 921 555-67-09',
                schedule: '08:00 - 23:00',
                rating: '4.8',
                subway: 'Чкаловская',
                gallery: [
                    '/img/1.png',
                    '/img/2.png',
                    '/img/3.png',
                    '/img/4.png',
                ],
                filters: [
                    'mapClosest',
                ],
            },
            {
                id: 'map2',
                mapLon: '59.871953',
                mapLat: '30.319627',
                address: 'Пр. Славы, 17',
                phone: '+7 921 555-67-09',
                schedule: '08:00 - 23:00',
                rating: '4.8',
                subway: 'Чкаловская',
                gallery: [
                    '/img/1.png',
                    '/img/2.png',
                    '/img/3.png',
                    '/img/4.png',
                ],
                filters: [
                    'mapClosest',
                    'mapLast',
                ],
            },
            {
                id: 'map3',
                mapLon: '59.914665',
                mapLat: '30.311650',
                address: 'Пр. Славы, 18',
                phone: '+7 921 555-67-09',
                schedule: '08:00 - 23:00',
                rating: '4.8',
                subway: 'Чкаловская',
                gallery: [
                    '/img/1.png',
                    '/img/2.png',
                    '/img/3.png',
                    '/img/4.png',
                ],
                filters: [
                    'mapClosest',
                ],
            },
            {
                id: 'map4',
                mapLon: '59.956893',
                mapLat: '30.353618',
                address: 'Пр. Славы, 19',
                phone: '+7 921 555-67-09',
                schedule: '08:00 - 23:00',
                rating: '4.8',
                subway: 'Чкаловская',
                gallery: [
                    '/img/1.png',
                    '/img/2.png',
                    '/img/3.png',
                    '/img/4.png',
                ],
                filters: [
                    'mapClosest',
                ],
            },
        ],

        isIndex: false,
        pageName: 'Активность',
        returnLink: '/',
        menuAdditionalClass: 'activity__menu',
        menuItems: [
            {
                text: 'Баллы',
                id: 'bonusPoints',
            },
            {
                text: 'Любимый продукт',
                id: 'favorite',
            },
            {
                text: 'Чеки',
                id: 'checks',
            },
            {
                text: 'Покупки',
                id: 'purchases',
            },
            {
                text: 'Кофе',
                id: 'coffee',
            },
            {
                text: 'Статистика',
                id: 'stats',
            },
        ],
        menuModels: {
            bonusPoints: {
                main: false,
                inner: false,
            },
            favorite: {
                main: false,
                inner: false,
            },
                checks: {
                main: false,
            },
                purchases: {
                main: false,
            },
            coffee: {
                main: false,
                inner: false,
            },
            stats: {
                main: false,
                inner: false,
            },
        },
        // menuFilter: 'bonusPoints',

        //баллы:
        bonusPointsHeight1: '1vh',
        bonusPointsHeight2: '1vh',
        bonusPointsCurrent: 209,
        bonusPointsItems: [
            {
                withdraw: false,
                sum: 289,
                dateTime: '10 ноя 19:36',
                address: 'Малый пр. П.С., 58',
                balance: 300,
                points: 6,
            },
            {
                withdraw: true,
                sum: 289,
                dateTime: '10 ноя 19:36',
                address: 'Малый пр. П.С., 58',
                balance: 300,
                points: 6,
            },
            {
                withdraw: false,
                sum: 289,
                dateTime: '10 ноя 19:36',
                address: 'Малый пр. П.С., 58',
                balance: 300,
                points: 6,
            },
        ],
        //любимый продукт:
        favoriteHeight1: '1vh',
        favoriteHeight2: '1vh',
        favoriteSelected: false,
        favoriteCurrentCount: 209,
        favoriteTotalCount: 500,
        favoriteItems: [
            {
                name: 'Багет Баррорустик',
                img: '/img/1.png',
                id: 1,
                price: 139,
                priceOld: 149,
                measure: '1 шт',
                bonusPoints: 6,
            },
            {
                name: 'Имбирно-пряничный латте на молоке',
                img: '/img/2.png',
                id: 2,
                price: 139,
                priceOld: 149,
                measure: '300 мл',
                bonusPoints: 6,
            },
            {
                name: 'Имбирно-пряничный латте на растительном молоке',
                img: '/img/3.png',
                id: 3,
                price: 139,
                priceOld: 149,
                measure: '300 мл',
                bonusPoints: 6,
            },
        ],
        //чеки:
        checksDefaultMessage: 'Смахни коричневый прямоугольник влево, чтобы посмотреть покупки за период!',
        checksMessage: false,
        checksArray: [
            {
                id: 'checksPeriod1',
                backText: 'от 1 до 31 декабря',
                sum: 238,
                message: 'Смахни оранжевый прямоугольник влево, чтобы посмотреть содержание покупки!',
                hideCoefficient: 100,
                hideCoefficientPrev: 100,
                items: [
                    {
                        id: '345666',
                        dateTime: '9 ноя 18:37',
                        address: 'Малый пр. П.С., 58',
                        sum: '101',
                        message: 'Твои покупки 9 ноября.',
                        hideCoefficient: 100,
                        hideCoefficientPrev: 100,
                        items: [
                            {
                                name: 'Багет Баррорустик',
                                img: '/img/1.png',
                                id: 1,
                                price: 149,
                                measure: '1 шт',
                            },
                            {
                                name: 'Имбирно-пряничный латте на молоке',
                                img: '/img/2.png',
                                id: 2,
                                price: 149,
                                measure: '300 мл',
                            },
                            {
                                name: 'Имбирно-пряничный латте на растительном молоке',
                                img: '/img/3.png',
                                id: 3,
                                price: 149,
                                measure: '300 мл',
                            },
                        ]
                    },
                    {
                    id: '999788',
                    dateTime: '9 ноя 18:37',
                    address: 'Малый пр. П.С., 58',
                    sum: '101',
                    message: 'Твои покупки 9 ноября.',
                    hideCoefficient: 100,
                    hideCoefficientPrev: 100,
                    items: [
                        {
                            name: 'Багет Баррорустик',
                            img: '/img/1.png',
                            id: 1,
                            price: 149,
                            measure: '1 шт',
                        },
                        {
                            name: 'Имбирно-пряничный латте на молоке',
                            img: '/img/2.png',
                            id: 2,
                            price: 149,
                            measure: '300 мл',
                        },
                        {
                            name: 'Имбирно-пряничный латте на растительном молоке',
                            img: '/img/3.png',
                            id: 3,
                            price: 149,
                            measure: '300 мл',
                        },
                    ]
                    },
                ],
            },
            {
                id: 'checksPeriod2',
                backText: 'от 1 до 30 ноября',
                sum: 239,
                message: 'Смахни оранжевый прямоугольник влево, чтобы посмотреть содержание покупки!',
                hideCoefficient: 100,
                hideCoefficientPrev: 100,
                items: [
                    {
                        id: '345678',
                        dateTime: '9 ноя 18:37',
                        address: 'Малый пр. П.С., 58',
                        sum: '101',
                        message: 'Твои покупки 9 ноября.',
                        hideCoefficient: 100,
                        hideCoefficientPrev: 100,
                        items: [
                            {
                                name: 'Багет Баррорустик',
                                img: '/img/1.png',
                                id: 1,
                                price: 149,
                                measure: '1 шт',
                            },
                            {
                                name: 'Имбирно-пряничный латте на молоке',
                                img: '/img/2.png',
                                id: 2,
                                price: 149,
                                measure: '300 мл',
                            },
                            {
                                name: 'Имбирно-пряничный латте на растительном молоке',
                                img: '/img/3.png',
                                id: 3,
                                price: 149,
                                measure: '300 мл',
                            },
                        ]
                    },
                    {
                        id: '012345',
                        dateTime: '9 ноя 18:37',
                        address: 'Малый пр. П.С., 58',
                        sum: '101',
                        message: 'Твои покупки 9 ноября.',
                        hideCoefficient: 100,
                        hideCoefficientPrev: 100,
                        items: [
                            {
                                name: 'Багет Баррорустик',
                                img: '/img/1.png',
                                id: 1,
                                price: 149,
                                measure: '1 шт',
                            },
                            {
                                name: 'Имбирно-пряничный латте на молоке',
                                img: '/img/2.png',
                                id: 2,
                                price: 149,
                                measure: '300 мл',
                            },
                            {
                                name: 'Имбирно-пряничный латте на растительном молоке',
                                img: '/img/3.png',
                                id: 3,
                                price: 149,
                                measure: '300 мл',
                            },
                        ]
                    },
                ],
            }
        ],
        //покупки:
        purchasesDefaultMessage: 'Смахни коричневый прямоугольник влево, чтобы посмотреть покупки на точке!',
        purchasesMessage: false,
        purchasesArray: [
            {
                id: 'purAddr1',
                backText: 'Арсенальная ул., д.6, Новое Девяткино',
                message: 'Арсенальная ул., д.6, Новое Девяткино',
                sum: 238,
                hideCoefficient: 100,
                hideCoefficientPrev: 100,
                items: [
                    {
                        name: 'Багет Баррорустик',
                        img: '/img/1.png',
                        id: 1,
                        fav: true,
                        bonusPoints: 6,
                        price: 149,
                        measure: '1 шт',
                    },
                    {
                        name: 'Имбирно-пряничный латте на молоке',
                        img: '/img/2.png',
                        id: 2,
                        fav: false,
                        bonusPoints: 5,
                        price: 149,
                        measure: '300 мл',
                    },
                    {
                        name: 'Имбирно-пряничный латте на растительном молоке',
                        img: '/img/3.png',
                        id: 3,
                        fav: false,
                        bonusPoints: 6,
                        price: 149,
                        measure: '300 мл',
                    },
                ],
            },
            {
                id: 'purAddr2',
                backText: 'Пр. Славы, 16',
                message: 'Пр. Славы, 16',
                sum: 239,
                hideCoefficient: 100,
                hideCoefficientPrev: 100,
                items: [
                    {
                        name: 'Имбирно-пряничный латте на растительном молоке',
                        img: '/img/3.png',
                        id: 3,
                        fav: false,
                        bonusPoints: 6,
                        price: 149,
                        measure: '300 мл',
                    },
                    {
                        name: 'Багет',
                        img: '/img/4.png',
                        id: 4,
                        fav: false,
                        bonusPoints: 6,
                        price: 149,
                        measure: '1 шт',
                    },
                ],
            }
        ],
        //кофе:
        coffeeHeight1: '1vh',
        coffeeHeight2: '1vh',
        coffeeLeftToBuyMsg: '1 чашка',
        coffeeDoneMsg: 'Вам доступна чашка кофе за 1 рубль!',
        coffeeCountCurrent: 5,
        coffeeCountTotal: 6,
        coffeeItems: [
            {
                name: 'Капучино большой',
                img: '/img/1.png',
                id: 'coffee1',
                fav: false,
                bonusPoints: 6,
                price: 149,
                measure: '1 шт',
            },
            {
                name: 'Капучино средний',
                img: '/img/2.png',
                id: 'coffee2',
                fav: false,
                bonusPoints: 6,
                price: 149,
                measure: '1 шт',
            },
            {
                name: 'Капучино маленький',
                img: '/img/3.png',
                id: 'coffee3',
                fav: false,
                bonusPoints: 6,
                price: 149,
                measure: '1 шт',
            },
        ],
        //статистика:
        statsHeight1: '1vh',
        statsHeight2: '1vh',
        statsShowMonthSelect: false,
        statsDefaultMessage: 'Смахни цветной прямоугольник влево, чтобы посмотреть покупки по категории!',
        statsMessage: false,
        statsCurrentMonth: {
            id: 'month6',
            name: 'июнь',
            spent: {
                total: 567,
                categories: [
                    {
                        name: 'Категория 1',
                        id: 'cat61',
                        sum: 149,
                        percent: 26,
                        color: '#333333',
                        hideCoefficient: 100,
                        hideCoefficientPrev: 100,
                        message: 'Ваши покупки в категории 2',
                        items: [
                            {
                                name: 'Багет Баррорустик',
                                img: '/img/1.png',
                                id: 1,
                                price: 149,
                                measure: '1 шт',
                                dateTime: '9 ноя 18:37',
                                address: 'Малый пр. П.С., 58',
                            },
                            {
                                name: 'Имбирно-пряничный латте на молоке',
                                img: '/img/2.png',
                                id: 2,
                                price: 149,
                                measure: '300 мл',
                                dateTime: '9 ноя 18:37',
                                address: 'Малый пр. П.С., 58',
                            },
                            {
                                name: 'Имбирно-пряничный латте на растительном молоке',
                                img: '/img/3.png',
                                id: 3,
                                price: 149,
                                measure: '300 мл',
                                dateTime: '9 ноя 18:37',
                                address: 'Малый пр. П.С., 58',
                            },
                        ],
                    },
                    {
                        name: 'Категория 2',
                        id: 'cat62',
                        sum: 149,
                        percent: 26,
                        color: '#D16E07',
                        hideCoefficient: 100,
                        hideCoefficientPrev: 100,
                        message: 'Ваши покупки в категории 2',
                        items: [
                            {
                                name: 'Багет Баррорустик',
                                img: '/img/1.png',
                                id: 1,
                                price: 149,
                                measure: '1 шт',
                                dateTime: '9 ноя 18:37',
                                address: 'Малый пр. П.С., 58',
                            },
                            {
                                name: 'Имбирно-пряничный латте на молоке',
                                img: '/img/2.png',
                                id: 2,
                                price: 149,
                                measure: '300 мл',
                                dateTime: '9 ноя 18:37',
                                address: 'Малый пр. П.С., 58',
                            },
                            {
                                name: 'Имбирно-пряничный латте на растительном молоке',
                                img: '/img/3.png',
                                id: 3,
                                price: 149,
                                measure: '300 мл',
                                dateTime: '9 ноя 18:37',
                                address: 'Малый пр. П.С., 58',
                            },
                        ],
                    },
                    {
                        name: 'Категория 3',
                        id: 'cat63',
                        sum: 269,
                        percent: 48,
                        color: '#523A28',
                        hideCoefficient: 100,
                        hideCoefficientPrev: 100,
                        message: 'Ваши покупки в категории 3',
                        items: [
                            {
                                name: 'Багет Баррорустик',
                                img: '/img/1.png',
                                id: 1,
                                price: 149,
                                measure: '1 шт',
                                dateTime: '9 ноя 18:37',
                                address: 'Малый пр. П.С., 58',
                            },
                            {
                                name: 'Имбирно-пряничный латте на молоке',
                                img: '/img/2.png',
                                id: 2,
                                price: 149,
                                measure: '300 мл',
                                dateTime: '9 ноя 18:37',
                                address: 'Малый пр. П.С., 58',
                            },
                            {
                                name: 'Имбирно-пряничный латте на растительном молоке',
                                img: '/img/3.png',
                                id: 3,
                                price: 149,
                                measure: '300 мл',
                                dateTime: '9 ноя 18:37',
                                address: 'Малый пр. П.С., 58',
                            },
                        ],
                    },
                ],
            },
        },
        statsArray: [
            {
                id: 'month6',
                name: 'июнь',
                message: 'Смахни цветной прямоугольник влево, чтобы посмотреть покупки по категории!',
                spent: {
                    total: 567,
                    categories: [
                        {
                            name: 'Категория 1',
                            id: 'cat61',
                            sum: 149,
                            percent: 26,
                            color: '#333333',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 2',
                            id: 'cat62',
                            sum: 149,
                            percent: 26,
                            color: '#D16E07',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 2',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 3',
                            id: 'cat63',
                            sum: 269,
                            percent: 48,
                            color: '#523A28',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 3',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                    ],
                },
            },
            {
                id: 'month5',
                name: 'май',
                spent: {
                    total: 320,
                    categories: [
                        {
                            name: 'Тест 1',
                            id: 'cat51',
                            sum: 100,
                            percent: 31,
                            color: '#333333',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории тест 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Тест 2',
                            id: 'cat52',
                            sum: 220,
                            percent: 69,
                            color: '#D16E07',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории тест 2',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                    ],
                },
            },
            {
                id: 'month4',
                name: 'апрель',
                spent: {
                    total: 100,
                    categories: [
                        {
                            name: 'Категория 1',
                            id: 'cat41',
                            sum: 1,
                            percent: 1,
                            color: '#333333',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 2',
                            id: 'cat42',
                            sum: 10,
                            percent: 10,
                            color: '#D16E07',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 2',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 3',
                            id: 'cat43',
                            sum: 20,
                            percent: 20,
                            color: '#523A28',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 3',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 4',
                            id: 'cat44',
                            sum: 30,
                            percent: 30,
                            color: '#ed10dc',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 4',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 5',
                            id: 'cat45',
                            sum: 5,
                            percent: 5,
                            color: '#52ff28',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 5',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 6',
                            id: 'cat46',
                            sum: 6,
                            percent: 6,
                            color: '#523fff',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 6',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 7',
                            id: 'cat47',
                            sum: 7,
                            percent: 7,
                            color: '#523000',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 7',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 8',
                            id: 'cat48',
                            sum: 8,
                            percent: 8,
                            color: '#321A28',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 8',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 9',
                            id: 'cat49',
                            sum: 13,
                            percent: 13,
                            color: '#123A28',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 9',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                    ],
                },
            },
            {
                id: 'month3',
                name: 'март',
                spent: {
                    total: 567,
                    categories: [
                        {
                            name: 'Категория 1',
                            id: 'cat31',
                            sum: 149,
                            percent: 26,
                            color: '#333333',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 2',
                            id: 'cat32',
                            sum: 149,
                            percent: 26,
                            color: '#D16E07',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 2',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 3',
                            id: 'cat33',
                            sum: 269,
                            percent: 48,
                            color: '#523A28',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 3',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                    ],
                },
            },
            {
                id: 'month2',
                name: 'февраль',
                spent: {
                    total: 1000,
                    categories: [
                        {
                            name: 'Категория 1',
                            id: 'cat21',
                            sum: 111,
                            percent: 11,
                            color: '#333333',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 2',
                            id: 'cat22',
                            sum: 111,
                            percent: 11,
                            color: '#a313d3',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 3',
                            id: 'cat23',
                            sum: 111,
                            percent: 11,
                            color: '#cad333',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 4',
                            id: 'cat24',
                            sum: 111,
                            percent: 11,
                            color: '#11133c',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 5',
                            id: 'cat25',
                            sum: 111,
                            percent: 11,
                            color: '#33cf31',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 6',
                            id: 'cat26',
                            sum: 111,
                            percent: 11,
                            color: '#bba333',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 7',
                            id: 'cat27',
                            sum: 111,
                            percent: 11,
                            color: '#03a33a',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 8',
                            id: 'cat28',
                            sum: 111,
                            percent: 11,
                            color: '#fb013a',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 9',
                            id: 'cat29',
                            sum: 112,
                            percent: 11,
                            color: '#3df3a3',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                    ],
                },
            },
            {
                id: 'month1',
                name: 'январь',
                spent: {
                    total: 1000,
                    categories: [
                        {
                            name: 'Категория 1',
                            id: 'cat11',
                            sum: 990,
                            percent: 90,
                            color: '#333333',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 1',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                        {
                            name: 'Категория 2',
                            id: 'cat12',
                            sum: 110,
                            percent: 10,
                            color: '#D16E07',
                            hideCoefficient: 100,
                            hideCoefficientPrev: 100,
                            message: 'Ваши покупки в категории 2',
                            items: [
                                {
                                    name: 'Багет Баррорустик',
                                    img: '/img/1.png',
                                    id: 1,
                                    price: 149,
                                    measure: '1 шт',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на молоке',
                                    img: '/img/2.png',
                                    id: 2,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                                {
                                    name: 'Имбирно-пряничный латте на растительном молоке',
                                    img: '/img/3.png',
                                    id: 3,
                                    price: 149,
                                    measure: '300 мл',
                                    dateTime: '9 ноя 18:37',
                                    address: 'Малый пр. П.С., 58',
                                },
                            ],
                        },
                    ],
                },
            },
        ],
    }),
    methods: {
        showMap: function() {
            this.map = true;
            this.$nextTick(() => {
                this.mapInner = true;

                this.$nextTick(() => {
                    this.setBasicModalHeight(this.$refs.mapInner.$el);
                });
            });
        },
        onMapInit: function(inst) {
            inst.events.add('balloonopen', function (e) {
                inst.events.add('click', function (e) {
                    if (e.get('target') === inst) {
                        inst.balloon.close();
                    }
                });
            });
        },
        controlModalBackdropOpacity: function(isOn, ref) {
            if (isOn) {
                let modalWindow = ref;

                this.$nextTick(() => {
                    console.log(modalWindow.querySelector('.modal-contents'));
                    let modalBlock = modalWindow.querySelector(':scope > .modal-contents'),
                        modalBlockHeight = modalBlock.getBoundingClientRect().height,
                        pageContent = document.querySelectorAll('header, main > *, footer');

                    let observer = new MutationObserver((mutations) => {
                        mutations.forEach((mutation) => {
                            let modalBlockBottom = Math.abs(Number((mutation.target.style.bottom).slice(0, -2))),
                                backdropOpacity = 1 - (modalBlockHeight - modalBlockBottom) / modalBlockHeight;

                            if (pageContent != undefined) {
                                for (let element in Object.entries(pageContent)) {
                                    pageContent[element].style.opacity = backdropOpacity;
                                }
                            }
                        });
                    });

                    let observerConfig = {
                        attributes: true,
                        childList: false,
                        characterData: false,
                        attributeOldValue: true
                    };

                    observer.observe(modalBlock, observerConfig);
                });
            }
        },
        controlMapBackdropOpacity: function(isOn, ref) {
            if (isOn) {
                let modalWindow = ref;

                this.$nextTick(() => {
                    let modalBlock = modalWindow.querySelector('.modal-contents'),
                        modalBlockHeight = modalBlock.getBoundingClientRect().height,
                        parentSwipe = this.$refs.map.$el,
                        parentSwipeContent = document.querySelectorAll('.modal-contents > .map, .swipe-modal--map-inner .modal-contents');

                    let observer = new MutationObserver((mutations) => {
                        mutations.forEach((mutation) => {
                            let modalBlockBottom = Math.abs(Number((mutation.target.style.bottom).slice(0, -2))),
                                backdropOpacity = 1 - (modalBlockHeight - modalBlockBottom) / modalBlockHeight;

                            if (parentSwipeContent != undefined) {
                                for (let element in Object.entries(parentSwipeContent)) {
                                    parentSwipeContent[element].style.opacity = backdropOpacity;
                                }
                            }
                        });
                    });

                    let observerConfig = {
                        attributes: true,
                        childList: false,
                        characterData: false,
                        attributeOldValue: true
                    };

                    observer.observe(modalBlock, observerConfig);
                });
            }
        },
        updateMapFilter: function(newValue) {
            this.mapMenuFilter = newValue;
        },
        detailMap: function(e, id) {
            let selected = this.mapItems.filter(
                (item) => item.id === id
            );

            this.mapDetail = selected[0];
        },
        updateMenu: function(newValue) {
            this.menuFilter = newValue;
            this.menuModels[newValue].main = true;
            this.$nextTick(() => {
                if (this.menuModels[newValue].inner !== undefined) {
                    this.menuModels[newValue].inner = true;

                    this.$nextTick(() => {
                        let capitalized = newValue.charAt(0).toUpperCase() + newValue.slice(1),
                            ref = 'activity' + capitalized + 'Inner',
                            innerModal = this.$refs[ref].$el,
                            innerModalContent = innerModal.querySelectorAll(':scope > .modal-contents');

                        innerModalContent[0].style.bottom = 0;
                    });
                }
            });

            let setTheseModelsFalse = Object.fromEntries(Object.entries(this.menuModels).filter(([key]) => key !== newValue));
            for (let model in setTheseModelsFalse) {
                this.menuModels[model].main = false;
                this.$nextTick(() => {
                    if (this.menuModels[model].inner !== undefined)
                        this.menuModels[model].inner = false;
                });
            }

            //то, что ниже (до конца метода) можно бы в цикле:
            for (let checksItem in this.checksArray) {
                this.checksArray[checksItem].hideCoefficient = 100;
                for (let checksPeriod in this.checksArray[checksItem].items) {
                    this.checksArray[checksItem].items[checksPeriod].hideCoefficient = 100;
                }
            }
            this.checksMessage = false;

            for (let purItem in this.purchasesArray) {
                this.purchasesArray[purItem].hideCoefficient = 100;
            }
            this.purchasesMessage = false;
        },
        setBasicModalHeight: function(modal) {
            this.$nextTick(() => {
                let modalContent = modal.querySelectorAll(':scope > .modal-contents'),
                    currentHeight = modalContent[0].style.minHeight;

                modalContent[0].style.maxHeight = currentHeight;

                this.$nextTick(() => {
                    this.$nextTick(() => {
                        let innerModalContent = modalContent[0].querySelector('.modal-contents'),
                            innerCurrentHeight;

                        //в этом месте внутренняя модалка у карты все еще null, поэтому если родительскую открыть и закрыть, внутренняя не будет видна
                        //видимо, надо какой-то async/await прикручивать:
                        if (innerModalContent !== null) {
                            innerCurrentHeight = innerModalContent.style.minHeight;
                            innerModalContent.style.maxHeight = innerCurrentHeight;
                            innerModalContent.style.bottom = 0;
                        }
                    });
                });
            });
        },
        rangeToggleStyles: function(e) {
            // debugger
            let ref = this,
                tabName = e.target.getAttribute('data-tab'),
                statsTab = tabName === 'stats' ? true : false,
                value = e.target.value,
                childContent = e.target.closest('.activity-swipe__swiper').nextElementSibling,
                thisBlock = e.target.closest('.activity-swipe__parent-block'),
                thisBlockId = thisBlock.getAttribute('data-id'),
                tabInner = e.target.getAttribute('data-tab-inner') === 'true' ? true : false,
                parentBlockId = tabInner ? thisBlock.parentNode.closest('.activity-swipe__parent-block').getAttribute('data-id') : false,
                backTextLeft = e.target.closest('.activity-swipe__swiper').querySelector('.activity-swipe__swiper-back-text--left'),
                backTextRight = e.target.closest('.activity-swipe__swiper').querySelector('.activity-swipe__swiper-back-text--right'),
                sliderRect = e.target.closest('.activity-swipe__swiper').getBoundingClientRect(),
                sliderWidth = sliderRect.width,
                sum = e.target.closest('.activity-swipe__swiper').querySelector('.activity-swipe__sum'),
                sumRect = sum.getBoundingClientRect(),
                sumWidth = sumRect.width,
                allContent = e.target.closest('.activity-swipe__level').children,
                siblingContent = Array.prototype.filter.call(allContent, function(node) {
                    return node.getAttribute('data-id') !== thisBlockId;
                });

            if (value > 0) {
                childContent.style.display = 'none';
                siblingContent.forEach(function(sibling) {
                    // sibling.style.display = 'block';
                    sibling.style.opacity = value / 100;

                    if (sibling.style.overflowY == 'hidden') {
                        let siblingContent = sibling.querySelector('.activity-swipe__swiper--main'),
                            siblingContentHeight = siblingContent.getBoundingClientRect().height;

                        sibling.style.overflowY = 'hidden'; 
                        let animation = sibling.animate([
                            {
                                maxHeight: '0px',
                                marginBottom: '0px',
                            },
                            {
                                maxHeight: siblingContentHeight + 'px',
                                marginBottom: '22px',
                            },
                        ], {
                            duration: 60,
                            easing: 'linear',
                            iterations: 1,
                            fill: 'forwards',
                        });

                        animation.finished.then(function() {
                            sibling.style.removeProperty('overflow-y');
                            sibling.style.removeProperty('max-height');
                        });
                    }
                });
            } else {               
                childContent.style.display = 'block';
                siblingContent.forEach(function(sibling) {
                    // sibling.style.display = 'none';

                    let siblingContent = sibling.querySelector('.activity-swipe__swiper--main'),
                        siblingContentHeight = siblingContent.getBoundingClientRect().height;

                    sibling.style.overflowY = 'hidden';

                    let animation = sibling.animate([
                        {
                            maxHeight: siblingContentHeight + 'px',
                            marginBottom: '22px',
                        },
                        {
                            maxHeight: '0px',
                            marginBottom: '0px',
                        },
                    ], {
                        duration: 60,
                        easing: 'linear',
                        iterations: 1,
                        fill: 'forwards',
                    });
                });
            }

            backTextLeft.style.opacity = value / 100;
            backTextRight.style.opacity = (100 - value) / 100;
            sum.style.right = ((sliderWidth * ((100 - value) / 100)) - sumWidth * ((100 - value) / 100)) + 'px';

            ref.toggleMessages(value, tabName, thisBlockId, parentBlockId, statsTab);
        },
        stickToRangeEdge: function(e) {
            let ref = this,
                value = Number(e.target.value);

            if (value !== 0 && value !== 100) {
                if (value <= 50)
                    ref.stickToRangeEdgeInterval('left', e);
                else
                    ref.stickToRangeEdgeInterval('right', e);
            }
        },
        stickToRangeEdgeInterval(direction, e) {
            let ref = this,
                interval;

            interval = setInterval(function() {
                if (direction === 'left' ? e.target.value > 0 : e.target.value < 100) {
                    for (let i = 0; i < 10; i++) {
                        direction === 'left' ? e.target.value-- : e.target.value++;
                        e.target.dispatchEvent(new Event('input'), {'bubbles': true});
                    }
                    ref.rangeToggleStyles(e);
                } else {
                    clearInterval(interval);
                }
            }, 10);
        
        },
        toggleMessages: function(value, tab, id, parentId, statsTab) {
            if (value == 0) {
                let currentBlock,
                    parentBlock;

                if (parentId === false) {
                    if (statsTab)
                        currentBlock = this.statsCurrentMonth.spent.categories.filter((block) => block.id == id);
                    else
                        currentBlock = this[tab + 'Array'].filter((block) => block.id == id);

                    this[tab + 'Message'] = currentBlock[0].message;
                } else {
                    parentBlock = this[tab + 'Array'].filter((block) => block.id == parentId);
                    currentBlock = parentBlock[0].items.filter((block) => block.id == id);
                    this[tab + 'Message'] = currentBlock[0].message;
                }
            } else if (value == 100) {
                this[tab + 'Message'] = false;
            }
        },
        changeTopBonusPoints: function(type) {
            this.activityBonusPointsMainType = type;

            if (type == 1) {
                this.bonusPointsHeight1 = (parseInt(this.heightActivityMainTop) - 38) + 'px';
                this.bonusPointsHeight2 = (parseInt(this.heightActivityMainTop) - 269) + 'px';
            } else {
                this.bonusPointsHeight1 = (parseInt(this.heightActivityMain) - 38) + 'px';
                this.bonusPointsHeight2 = (parseInt(this.heightActivityMain) - 269) + 'px';
            }
        },
        changeTopFavorite: function(type) {
            this.activityFavotiteMainType = type;

            if (type == 1) {
                this.favoriteHeight1 = (parseInt(this.heightActivityMainTop) - 38) + 'px';
                this.favoriteHeight2 = (parseInt(this.heightActivityMainTop) - 287) + 'px';
            } else {
                this.favoriteHeight1 = (parseInt(this.heightActivityMain) - 38) + 'px';
                this.favoriteHeight2 = (parseInt(this.heightActivityMain) - 287) + 'px';
            }
        },
        changeTopCoffee: function(type) {
            this.activityCoffeeMainType = type;

            if (type == 1) {
                this.coffeeHeight1 = (parseInt(this.heightActivityMainTop) - 38) + 'px';
                this.coffeeHeight2 = (parseInt(this.heightActivityMainTop) - 287) + 'px';
            } else {
                this.coffeeHeight1 = (parseInt(this.heightActivityMain) - 38) + 'px';
                this.coffeeHeight2 = (parseInt(this.heightActivityMain) - 287) + 'px';
            }
        },
        changeTopStats: function(type) {
            this.activityStatsMainType = type;

            if (type == 1) {
                this.statsHeight1 = (parseInt(this.heightActivityMainTop) - 38) + 'px';
                this.statsHeight2 = (parseInt(this.heightActivityMainTop) - 355) + 'px';
            } else {
                this.statsHeight1 = (parseInt(this.heightActivityMain) - 38) + 'px';
                this.statsHeight2 = (parseInt(this.heightActivityMain) - 355) + 'px';
            }
        },
        bonusPointsInnerType: function(type) {
            this.animateParentSwipeOpacity(this.$refs.activityBonusPointsMain.$el, type);
            this.activityBonusPointsInnerType = type;
        },
        favoriteInnerType: function(type) {
            this.animateParentSwipeOpacity(this.$refs.activityFavoriteMain.$el, type);
            this.activityFavoriteInnerType = type;
        },
        coffeeInnerType: function(type) {
            this.animateParentSwipeOpacity(this.$refs.activityCoffeeMain.$el, type);
            this.activityCoffeeInnerType = type;
        },
        statsInnerType: function(type) {
            this.animateParentSwipeOpacity(this.$refs.activityStatsMain.$el, type);
            this.activityStatsInnerType = type;
        },
        stoppag: function(e) {
            e.stopPropagation();
        },
        changeStatsMonth: function(e, id) {
            this.statsCurrentMonth = this.statsArray.filter((month) => month.id === id)[0];
            this.statsShowMonthSelect = false;
        },
        resetSwipeTypes: function() {
            this.activityBonusPointsMainType = 0;
            this.activityBonusPointsInnerType = 0;
            this.activityFavoriteMainType = 0;
            this.activityFavoriteInnerType = 0;
            this.activityCoffeeMainType = 0;
            this.activityCoffeeInnerType = 0;
            this.activityStatsMainType = 0;
            this.activityStatsInnerType = 0;
        },
        animateParentSwipeOpacity: function(swipe, state) {
            let parentSwipeContent = swipe.querySelector('.modal-contents > .app-container'),
                opacityStart = state ? 1 : 0,
                opacityEnd = state ? 0 : 1;

            parentSwipeContent.animate([
                {
                    opacity: opacityStart,
                },
                {
                    opacity: opacityEnd,
                },
            ], {
                duration: 150,
                easing: 'linear',
                iterations: 1,
                fill: 'forwards',
            });
        },
        locationButtonAnimation: function(type) {
            let button = this.$refs.map.$el.querySelector('.map__location-btn'),
                opacityStart = type == 0 ? 0 : 1,
                opacityEnd = type == 0 ? 1 : 0;

            button.animate([
                {
                    opacity: opacityStart,
                },
                {
                    opacity: opacityEnd,
                },
            ], {
                duration: 150,
                easing: 'linear',
                iterations: 1,
                fill: 'forwards',
            });
        },
    },
    watch: {
        map: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.map.$el);
        },
        mapInner: function(value) {
            // if (value) {
            //     this.$nextTick(() => {
            //         this.$nextTick(() => {
            //             let locationBtn = this.$refs.map.$el.querySelector('.map__location-btn'),
            //                 innerMapModal = this.$refs.mapInner.$el.querySelector('.modal-contents');

            //             locationBtn.style.display = 'block';

            //             let observer = new MutationObserver((mutations) => {
            //                 mutations.forEach((mutation) => {
            //                     let innerMapModalBottom = Math.abs(Number((mutation.target.style.bottom).slice(0, -2))),
            //                         innerMapModalTop = innerMapModal.getBoundingClientRect().top,
            //                         innerMapModalHeight = innerMapModal.getBoundingClientRect().height;

            //                     console.log('bottom: '+innerMapModalBottom);
            //                     console.log('top: '+innerMapModalTop);
            //                     console.log('height: '+innerMapModalHeight);
            //                     console.log('____________');
            //                 });
            //             });

            //             let observerConfig = {
            //                 attributes: true,
            //                 childList: false,
            //                 characterData: false,
            //                 attributeOldValue: true
            //             };

            //             observer.observe(innerMapModal, observerConfig);
            //         });
            //     });
            // }
        },
        mapDetail: function(endValue, startValue) {
            let opening = startValue === false ? true : false,
                rotateDegrees = opening ? 270 : 90,
                refId = opening ? endValue.id : startValue.id,
                item = this.$refs[refId][0],
                arrow = item.querySelector('.map-item__arrow'),
                balloon = this.$refs['balloon_' + refId],
                balloonArrow = balloon === undefined ? false : balloon[0].querySelector('.map__balloon-arrow'),
                degreesCounter = arrow.style.transform === '' ? 0 : Number(arrow.style.transform.replace(/\D/g, ''));

            degreesCounter += rotateDegrees;
            arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';

            if (balloonArrow !== false) {
                balloonArrow.style.transform = 'rotate(' + degreesCounter + 'deg)';
                balloon[0].classList.toggle('map__balloon--open');
            }

            this.controlMapBackdropOpacity(this.mapDetail, this.$refs.mapDetail.$el);
        },
        //по идее это надо задавать в цикле в отдельном методе с помощью this.$watch, но у меня не заработало:
        'menuModels.bonusPoints.main'(value) {
            if (value) {
                this.setBasicModalHeight(this.$refs.activityBonusPointsMain.$el);

                // this.$nextTick(() => {
                //     let innerSwipeWindow = this.$refs.activityBonusPointsInner.$el,
                //         mainSwipeWindow = this.$refs.activityBonusPointsMain.$el;

                //     this.$nextTick(() => {
                //         this.$nextTick(() => {
                //             let minDifference = 72,
                //                 modalBlock = innerSwipeWindow.querySelector('.modal-contents'),
                //                 mainModalBlock = mainSwipeWindow.querySelector('.modal-contents'),
                //                 mainModalContent = mainSwipeWindow.querySelector('.modal-contents > :not(.modal-contents-chip-wrapper)'),
                //                 initialHeightDifference = mainModalBlock.getBoundingClientRect().height - modalBlock.getBoundingClientRect().height;

                //             let observer = new MutationObserver((mutations) => {
                //                 console.log(mutations);
                //                 mutations.forEach((mutation) => {
                //                     let modalBlockHeight = modalBlock.getBoundingClientRect().height,
                //                         modalBlockBottom = Math.abs(Number((mutation.target.style.bottom).slice(0, -2))),
                //                         mainModalBlockHeight = mainModalBlock.getBoundingClientRect().height,
                //                         backdropOpacity = (mainModalBlockHeight - modalBlockHeight - minDifference) / (initialHeightDifference - minDifference);

                //                     if (mainModalContent != undefined) {
                //                         console.log(modalBlockHeight);
                //                         console.log(modalBlockBottom);

                //                         mainModalContent.style.opacity = backdropOpacity;
                //                     }
                //                 });
                //             });

                //             let observerConfig = {
                //                 attributes: true,
                //                 childList: false,
                //                 characterData: false,
                //                 attributeOldValue: true,
                //                 attributeFilter: ['style'],
                //             };

                //             observer.observe(modalBlock, observerConfig);
                //         });
                //     });
                // });
            }

            this.resetSwipeTypes();
        },
        'menuModels.favorite.main'(value) {
            if (value) {
                this.setBasicModalHeight(this.$refs.activityFavoriteMain.$el);
            }

            this.resetSwipeTypes();
        },
        'menuModels.checks.main'(value) {
            if (value) {
                this.setBasicModalHeight(this.$refs.activityChecksMain.$el);
            }

            this.resetSwipeTypes();
        },
        'menuModels.purchases.main'(value) {
            if (value) {
                this.setBasicModalHeight(this.$refs.activityPurchasesMain.$el);
            }

            this.resetSwipeTypes();
        },
        'menuModels.coffee.main'(value) {
            if (value) {
                this.setBasicModalHeight(this.$refs.activityCoffeeMain.$el);
            }

            this.resetSwipeTypes();
        },
        'menuModels.stats.main'(value) {
            if (value) {
                this.setBasicModalHeight(this.$refs.activityStatsMain.$el);
            }

            this.resetSwipeTypes();
        },
        statsShowMonthSelect(value) {
            let diagramBlock = this.$refs.statsDiagram,
                selectBlock = this.$refs.statsMonths,
                arrow = this.$refs.statsArrow,
                rotateDegrees = value ? 270 : 90,
                degreesCounter = arrow.style.transform === '' ? 0 : Number(arrow.style.transform.replace(/\D/g, ''));

            degreesCounter += rotateDegrees;
            arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';

            if (value) {
                diagramBlock.animate([
                    {
                        opacity: 1,
                    },
                    {
                        opacity: 0,
                    },
                ], {
                    duration: 150,
                    easing: 'linear',
                    iterations: 1,
                    fill: 'forwards',
                });

                setTimeout(function() {
                    diagramBlock.style.display = 'none';
                    selectBlock.style.display = 'block';

                    selectBlock.animate([
                        {
                            opacity: 0,
                        },
                        {
                            opacity: 1,
                        },
                    ], {
                        duration: 150,
                        easing: 'linear',
                        iterations: 1,
                        fill: 'forwards',
                    });
                }, 150);
            } else {
                selectBlock.animate([
                    {
                        opacity: 1,
                    },
                    {
                        opacity: 0,
                    },
                ], {
                    duration: 150,
                    easing: 'linear',
                    iterations: 1,
                    fill: 'forwards',
                });

                setTimeout(function() {
                    selectBlock.style.display = 'none';
                    diagramBlock.style.display = 'block';

                    diagramBlock.animate([
                        {
                            opacity: 0,
                        },
                        {
                            opacity: 1,
                        },
                    ], {
                        duration: 150,
                        easing: 'linear',
                        iterations: 1,
                        fill: 'forwards',
                    });
                }, 150);
            }
        }
    },
    mounted() {
        this.menuModels[this.menuFilter].main = true;
        this.$nextTick(() => {
            if (this.menuModels[this.menuFilter].inner !== undefined) {
                this.menuModels[this.menuFilter].inner = true;
            }
        });

        this.changeTopBonusPoints(0);

        this.bonusPointsHeight1 = (parseInt(this.heightActivityMain) - 38) + 'px';
        this.bonusPointsHeight2 = (parseInt(this.heightActivityMain) - 269) + 'px';
        this.favoriteHeight1 = (parseInt(this.heightActivityMain) - 38) + 'px';
        this.favoriteHeight2 = (parseInt(this.heightActivityMain) - 287) + 'px';
        this.coffeeHeight1 = (parseInt(this.heightActivityMain) - 38) + 'px';
        this.coffeeHeight2 = (parseInt(this.heightActivityMain) - 287) + 'px';
        this.statsHeight1 = (parseInt(this.heightActivityMain) - 38) + 'px';
        this.statsHeight2 = (parseInt(this.heightActivityMain) - 355) + 'px';

        //тут должна быть проверка и раздача классов товарам, как в mounted() в компоненте /components/app/CatalogSection.vue
        //(let productNames = this.$el.querySelectorAll('.catalog-item__name') и далее)
        //но у меня не заработало, потому что контент не весь на виду в начале, наверное

        if (this.$route.query.tab)
            this.menuFilter = this.$route.query.tab;
    },
    computed: {
        filteredMapItems() {
            return this.mapItems.filter(
                (item) => item.filters.includes(this.mapMenuFilter)
            );
        },
        heightActivityMain() {
            return (window.innerHeight * 0.647) + 'px';
        },
        heightActivityMainTop() {
            return (window.innerHeight - 132) + 'px';
        },
        menuFilter() {
            return this.$route.query.tab ? this.$route.query.tab : 'bonusPoints';
        },
    },
    components: { Header, Footer, swipeModal, ActivityMenu, BtnMenu, SliderDetailProduct, swipeMy, DonutChart }
}
</script>

