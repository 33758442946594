<template>
    <Header
        :pageName="pageName"
        :isIndex="isIndex"
        :returnLink="returnLink"
    ></Header>

    <main class="main main--catalog main--catalog-section">
        <CatalogSection
            :products="products"
            @detail-product="showDetailProduct"
        ></CatalogSection>
    </main>
  
    <Footer
        @call-map="showMap"
    ></Footer>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--product"
        v-model="detailProduct"
        contents-height="90vh"
        border-top-radius="30px"
        ref="detailProductModal"
    >
        <div class="detail-product">
            <div class="detail-product__slider-container">
                <SliderDetailProduct
                    :slides="detailProduct.gallery"
                    @touchstart="stoppag"
                ></SliderDetailProduct>
            </div>

            <div
                class="detail-product__content-container"
                :style="detailEnergy || detailComposition || favDialog || (window.ratio < 2 && !detailDescriptionHidden) ? 'min-height: auto' : 'min-height: ' + productTextInitHeight + 'px;'"
                @touchstart="stoppag"
            >
                <div
                    class="detail-product__head"
                    :class="favDialog ? 'detail-product__head--fav-dialog' : ''"
                >
                    <p class="detail-product__name-heart">
                        <h2
                            class="detail-product__name"
                            ref="productName"
                        >
                            {{ detailProduct.name }}
                        </h2>

                        <button
                            class="detail-product__heart"
                            :class="detailProduct.fav ? 'detail-product__heart--active' : ''"
                            @click="favDialog = !favDialog"
                        ></button>
                    </p>

                    <p class="detail-product__price-bonus">
                        <span class="detail-product__price">{{ detailProduct.price }} руб</span>
                        
                        <span class="detail-product__bonus">+{{ detailProduct.bonusPoints }}</span>
                    </p>

                    <div
                        class="detail-product__fav-dialog-wrapper"
                        :class="favDialog ? '' : 'detail-product__fav-dialog-wrapper--hidden'"
                    >
                        <div class="detail-product__fav-dialog">
                            <!-- В новой версии дизайна тут разные тексты в зависимости от параметров: есть ли уже любимый продукт и когда он в последний раз менялся -->
                            <!-- Каките-то части текста могут быть выделены красным цветом, в стилях прописал, чтобы красным выделялись <span> внутри этого параграфа VVV -->
                            <!-- Логику же вывода текста надо уже при натяжке делать, как я понимаю из дизайна -->
                            <p class="detail-product__fav-dialog-text">Вы уверены, что хотите сделать {{ detailProduct.name }} любимым продуктом?</p>

                            <button
                                class="detail-product__fav-dialog-btn btn btn--fav"
                                @click="favTheProduct($event, detailProduct)"
                            >
                                Сделать любимым
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    class="detail-product__description-wrapper"
                    :class="detailDescriptionHidden && window.ratio < 2 ? 'detail-product__description-wrapper--hidden' : ''"
                >
                    <div class="detail-product__description" v-html="detailProduct.detailText"></div>
                </div>
                <div
                    class="detail-product__description-toggle-wrapper"
                    v-if="window.ratio < 2"
                >
                    <button
                        class="detail-product__description-toggle btn btn--hide"
                        v-html="detailDescriptionHidden ? 'Описание' : 'Свернуть'"
                        @click="detailDescriptionHidden = !detailDescriptionHidden"
                    ></button>
                </div>

                <div class="detail-product__composition-energy">
                    <div class="detail-product__composition-energy-toggles">
                        <button
                            class="detail-product__composition-energy-toggle btn"
                            v-if="!detailEnergy"
                            @click="detailComposition = true"
                        >
                            Состав
                        </button>

                        <button
                            class="detail-product__composition-energy-toggle detail-product__composition-energy-toggle--hide btn btn--hide"
                            v-if="detailComposition || detailEnergy"
                            @click="detailComposition = false; detailEnergy = false;"
                        >
                            Свернуть
                        </button>

                        <button
                            class="detail-product__composition-energy-toggle btn"
                            v-if="!detailComposition"
                            @click="detailEnergy = true"
                        >
                            Кбжу
                        </button>
                    </div>

                    <div
                        class="detail-product__composition-energy-text-wrapper"
                        :class="detailComposition ? '' : 'detail-product__composition-energy-text-wrapper--hidden'"
                    >
                        <div
                            class="detail-product__composition-energy-text detail-product__composition-energy-text--composition"
                            ref="detailComposition"
                            v-html="detailProduct.composition"
                        ></div>
                    </div>

                    <div
                        class="detail-product__composition-energy-text-wrapper"
                        :class="detailEnergy ? '' : 'detail-product__composition-energy-text-wrapper--hidden'"
                    >
                        <div
                            class="detail-product__composition-energy-text detail-product__composition-energy-text--energy"
                            ref="detailEnergy"
                            v-html="detailProduct.energy"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </swipe-modal>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--map"
        v-model="map"
        contents-height="90vh"
        border-top-radius="30px"
        ref="map"
    >
        <div class="app-container">
            map
        </div>
    </swipe-modal>
</template>

<script>
import Footer from '../components/app/Footer';
import Header from '../components/app/Header';
import CatalogSection from '../components/app/CatalogSection';
import SliderDetailProduct from '../components/app/SliderDetailProduct';

import {swipeModal} from '@takuma-ru/vue-swipe-modal';

export default {
    data: () => ({
        window: {
            width: 0,
            height: 0,
            ratio: 0,
        },
        detailDescriptionHidden: false,
        map: false,
        isIndex: false,
        pageName: 'Десерты',
        returnLink: '/catalog',
        detailProduct: false,
        productTextInitHeight: 304,
        favDialog: false,
        detailComposition: false,
        detailEnergy: false,
        products : [
            {
                name: 'Багет Баррорустик',
                img: '/img/1.png',
                id: 1,
                fav: true,
                favTomorrow: false,
                filters: [
                    'menuAll',
                    'menuTopMonth',
                    'menuTopRating',
                ],
                bonusPoints: 6,
                price: 149,
                measure: '1 шт',
                gallery: [ //нужно кропать картинки для вывода в слайдер в карточке товара в соотношении сторон 1:1, иначе слайдер будет ломаться. Если надо будет - объясню лично, почему
                    '/img/1.png',
                    '/img/2.png',
                ],
                detailText: '<p>Нежный кофейный мусс на шоколадном бисквите, покрытый сверху хрустящим шоколадом с карамелью</p>',
                composition: '<p>Сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар</p>',
                energy: '<p>Белки: 0 г.</p><p>Жиры: 1 г.</p><p>Углеводы: 20 г.</p><p>Энегретическая ценность: 100 кКал</p>',
            },
            {
                name: 'Имбирно-пряничный латте на молоке',
                img: '/img/2.png',
                id: 2,
                fav: false,
                favTomorrow: true,
                filters: [
                    'menuAll',
                    'menuTopMonth',
                ],
                bonusPoints: 5,
                price: 149,
                measure: '300 мл',
                gallery: [
                    '/img/2.png',
                ],
                detailText: '<p>Нежный кофейный мусс на шоколадном бисквите, покрытый сверху хрустящим шоколадом с карамелью</p>',
                composition: '<p>Сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар</p>',
                energy: '<p>Белки: 0 г.</p><p>Жиры: 1 г.</p><p>Углеводы: 20 г.</p><p>Энегретическая ценность: 100 кКал</p>',
            },
            {
                name: 'Имбирно-пряничный латте на растительном молоке',
                img: '/img/3.png',
                id: 3,
                fav: false,
                favTomorrow: false,
                filters: [
                    'menuAll',
                    'menuTopRating',
                ],
                bonusPoints: 6,
                price: 149,
                measure: '300 мл',
                gallery: [
                    '/img/1.png',
                    '/img/2.png',
                ],
                detailText: '<p>Нежный кофейный мусс на шоколадном бисквите, покрытый сверху хрустящим шоколадом с карамелью</p>',
                composition: '<p>Сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар</p>',
                energy: '<p>Белки: 0 г.</p><p>Жиры: 1 г.</p><p>Углеводы: 20 г.</p><p>Энегретическая ценность: 100 кКал</p>',
            },
            {
                name: 'Багет',
                img: '/img/4.png',
                id: 4,
                fav: false,
                favTomorrow: false,
                filters: [
                    'menuAll',
                ],
                bonusPoints: 6,
                price: 149,
                measure: '1 шт',
                gallery: [
                    '/img/1.png',
                    '/img/2.png',
                ],
                detailText: '<p>Нежный кофейный мусс на шоколадном бисквите, покрытый сверху хрустящим шоколадом с карамелью</p>',
                composition: '<p>Сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар, сахар</p>',
                energy: '<p>Белки: 0 г.</p><p>Жиры: 1 г.</p><p>Углеводы: 20 г.</p><p>Энегретическая ценность: 100 кКал</p>',
            },
        ],
    }),
    watch: {
        map: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.map.$el);
        },
        detailProduct: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.detailProductModal.$el);

            if (!value) {
                this.favDialog = false;
                this.detailComposition = false;
                this.detailEnergy = false;
                this.detailEnergy = false;
                if (this.window.ratio < 2) {
                    this.detailDescriptionHidden = true;
                }
            }

            if (value) {
                this.$nextTick(() => {
                    let modalWindow = this.$refs.detailProductModal.$el.querySelector('.modal-contents'),
                        modalWindowHeight = modalWindow.getBoundingClientRect().height,
                        sliderBlock = modalWindow.querySelector('.detail-product__slider-container'),
                        sliderBlockHeight = sliderBlock.getBoundingClientRect().height,
                        margin = this.window.ratio < 2 ? 35 : 84,
                        textInitHeight;

                    textInitHeight = modalWindowHeight - sliderBlockHeight - margin;
                    this.productTextInitHeight = textInitHeight;
                });
            }

            this.$nextTick(() => {
                this.handleResize();

                let defaultNameFontSize = 22,
                    wordsThreshold = 2,
                    pixelsThreshold = 2,
                    name = this.detailProduct.name,
                    nameElement = this.$refs.productName,
                    nameLength = name.split(' ').length;

                if (nameLength > wordsThreshold) {
                    let wordsOverflow = nameLength - wordsThreshold,
                        fontSizeChangeIterations = Math.ceil(wordsOverflow / wordsThreshold),
                        fontSizeDifference = fontSizeChangeIterations * pixelsThreshold;

                    nameElement.style.fontSize = (defaultNameFontSize - fontSizeDifference) + 'px';
                }
            });
        },
    },
    methods: {
        showMap: function() {
            this.map = true;
        },
        showDetailProduct: function(id) {
            this.detailProduct = this.products.filter(
                (product) => product.id === id
            )[0];
        },
        controlModalBackdropOpacity: function(isOn, ref) {
            let arrow = document.querySelector('.catalog-item__arrow--open'),
                degreesCounter = arrow.style.transform === '' ? 0 : Number(arrow.style.transform.replace(/\D/g, ''));

            if (!isOn) {
                degreesCounter += 90;
                arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';

                setTimeout(function() {
                    arrow.classList.remove('catalog-item__arrow--open');
                }, 300);
            }

            if (isOn) {
                let modalWindow = ref;

                this.$nextTick(() => {
                    let modalBlock = modalWindow.querySelector('.modal-contents'),
                        modalBlockHeight = modalBlock.getBoundingClientRect().height,
                        pageContent = document.querySelectorAll('header, main > *, footer');

                    let observer = new MutationObserver((mutations) => {
                        mutations.forEach((mutation) => {
                            let modalBlockBottom = Math.abs(Number((mutation.target.style.bottom).slice(0, -2))),
                                backdropOpacity = 1 - (modalBlockHeight - modalBlockBottom) / modalBlockHeight;

                            if (pageContent != undefined) {
                                for (let element in Object.entries(pageContent)) {
                                pageContent[element].style.opacity = backdropOpacity;
                                }
                            }
                        });
                    });

                    let observerConfig = {
                        attributes: true,
                        childList: false,
                        characterData: false,
                        attributeOldValue: true
                    };

                    observer.observe(modalBlock, observerConfig);
                });
            }
        },
        favTheProduct: function(e, product) {
            product.fav = true;
            this.favDialog = false;
        },
        stoppag: function(e) {
            e.stopPropagation();
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.window.ratio = this.window.height / this.window.width;

            if (this.window.ratio < 2) {
                this.detailDescriptionHidden = true;
                this.productTextInitHeight = 271;
            } else {
                this.detailDescriptionHidden = false;
                this.productTextInitHeight = 304;
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    components: {
        Header,
        Footer,
        CatalogSection,
        swipeModal,
        SliderDetailProduct,
    },
}
</script>

