<template>
  <section class="template intro">
    <div class="app-container">
      <div class="intro__content intro__content--no-cat intro__content--no-pt">
        <UserForm
          :formClass="formClass"
          :userPhone="phone"
          :formFields="formFields"
          :showReturnBtn="showReturnBtn"
          :submitBtnIsAccent="submitBtnIsAccent"
        ></UserForm>
      </div>
    </div>
  </section>
</template>

<script>
import UserForm from '@/components/app/UserForm';

export default {
  data: () => ({
    formClass: 'intro__full-reg-form',
    phone: '9991234567',
    showReturnBtn: true,
    submitBtnIsAccent: false,
    formFields: {
      name: {
        model: 'name',
        textName: 'Имя',
        placeholder: 'Имя',
        type: 'string',
        isRequired: false,
      },
      sex: {
        model: 'sex',
        textName: 'Пол',
        // placeholder: 'Не указан',
        type: 'list',
        items: [
          {
            text: 'Не указан',
            id: 'none',
          },
          {
            text: 'Мужской',
            id: 'male',
          },
          {
            text: 'Женский',
            id: 'female',
          },
        ],
        isRequired: false,
      },
      email: {
        model: 'email',
        textName: 'Почта',
        placeholder: '...@...',
        type: 'email',
        isRequired: true,
        errorText: 'Укажите почту в\xA0верном формате: ...@...ru',
      },
      birthdate: {
        model: 'birthdate',
        textName: 'Дата рождения',
        placeholder: 'дд.мм.гггг.',
        type: 'date',
        isRequired: true,
        errorText: 'Укажите дату в\xA0верном формате: дд.мм.гггг',
      },
      avatar: {
        model: 'avatar',
        type: 'file',
        isRequired: false,
      },
    },
  }),
  components: {UserForm}
}
</script>