<template>
  <section class="template intro">
    <div class="app-container">
      <div class="intro__content">
        <img class="intro__head-logo" src="/img/logo-intro.svg" alt="Логотип «Все Дома»" width="317" height="91">

        <h2 class="intro__heading intro__heading--pt" v-if="screenNo === 1">МУРРР...</h2>

        <div class="intro__done-screen-text intro__done-screen-text--one" v-if="screenNo === 1">
          <p>
            Спасибо, что установили<br>
            мобильное приложение ВСЕ ДОМА!
          </p>
          <p>
            <b>
              Теперь вам доступны новые возможности!
            </b>
          </p>
        </div>

        <h2 class="intro__heading" v-if="screenNo === 2">
          Получайте баллы
        </h2>

        <div class="intro__done-screen-text intro__done-screen-text--two" v-if="screenNo === 2">
          <p>
            Баллы начисляются<br>
            за совершенные покупки
          </p>
          <p>
            <span>5%</span> от стоимости<br>
            покупки возвращается в виде баллов
          </p>
          <p>
            Вы можете оплачивать<br>
            до <span>90%</span> стоимости покупки баллами
          </p>
        </div>

        <img class="intro__percent-img" src="/img/icon-percent.svg" alt="Символ процентов" width="51" height="51" v-if="screenNo === 2">

        <h2 class="intro__heading" v-if="screenNo === 3">
          Смартфон вместо дисконтной карты
        </h2>

        <div class="intro__done-screen-text intro__done-screen-text--three" v-if="screenNo === 3">
          <p>
            Не надо приносить<br>
            с собой дисконтную карту.<br>
            Просто предъявляйте на кассе<br>
            QR-код из приложения для оплаты покупки.<br>
          </p>
        </div>

        <div
          class="coffee-widget coffee-widget--demo"
          v-if="screenNo === 3"
        >
          <div class="coffee-widget__qr-block">
            <div class="coffee-widget__qr-trigger">
              <span class="coffee-widget__qr-trigger-arrow"></span>
            </div>
          </div>

          <div class="coffee-widget__top-block">
            <h2 class="coffee-widget__top-block-greetings">
              Привет, <span class="coffee-widget__top-block-greetings-name">{{ userCounterName }}</span>!
            </h2>
            <div class="coffee-widget__top-block-counter-wrapper" to="#">
              <p class="coffee-widget__top-block-text-counter">
                <span class="coffee-widget__top-block-text-counter-count">{{ userCounterCount }}</span>
                <span class="coffee-widget__top-block-text-counter-divider">/</span>
                <span class="coffee-widget__top-block-text-counter-total">{{ userCounterTotal }}</span>
                <span class="coffee-widget__top-block-text-counter-desc">чашек кофе</span>
              </p>

              <div class="coffee-widget__top-block-visual-counter">
                <div
                  class="coffee-widget__top-block-visual-counter-current"
                  ref="visualCounterLine"
                ></div>
              </div>

              <p class="coffee-widget__top-block-remains-counter">
                <span class="coffee-widget__top-block-remains-counter-num">1 </span>
                <span class="coffee-widget__top-block-remains-counter-word">чашка </span>
                <span class="coffee-widget__top-block-remains-counter-res">до кофе за 1 рубль</span>
              </p>
            </div>
          </div>

          <div class="coffee-widget__bottom-block">
            <p class="coffee-widget__bottom-block-text-wrapper">
              <span>
                <b>Все Дома</b>
              </span>
              <span>булочная</span>
            </p>

            <router-link class="coffee-widget__bottom-block-points-wrapper" to="#">
              <span class="coffee-widget__bottom-block-points">209</span>
              <span class="coffee-widget__bottom-block-points-text">баллов</span>
            </router-link>
          </div>
        </div>
        
        <button class="intro__next-screen-btn btn" v-if="[1, 2].includes(screenNo)" @click="screenNo++">Дальше</button>
        <button class="intro__skip-link" v-if="screenNo !== 3" @click="screenNo = 3">Пропустить</button>
        <router-link class="intro__finish-reg-btn btn" v-if="screenNo === 3" to="/">Поехали</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    screenNo: 1,
    userCounterName: 'Денис',
    userCounterCount: '5',
    userCounterTotal: '6',
  }),
  watch: {
    screenNo: function(value) {
      if (value === 3) {
        this.$nextTick(() => {
          let current = this.userCounterCount,
          total = this.userCounterTotal,
          line = this.$refs.visualCounterLine;

          line.style.width = 'calc(' + current + ' / ' + total + ' * 100% - 14px)';
        });
      }
    },
  },
}
</script>