<template>
    <div class="slider">
        <div class="app-container">
            <h2
                class="slider__heading"
                v-if="sliderHeading"
                @click="startFirstItem"
            >
                {{ sliderHeading }}
                <span class="slider__heading-arrow" ref="promoSliderArrow"></span>
            </h2>
        </div>

        <div class="slider__wrapper">
            <Carousel
                :wrapAround="true"
                :itemsToShow="2.667"
                snapAlign="center-even"
            >
                <Slide
                    v-for="(slide, index) in slides"
                    :key="index"
                >
                    <button
                        class="slider__link"
                        @click="detailPromo(index)"
                    >
                        <img
                            class="slider__img"
                            :src="slide.img"
                            :alt="slide.name"
                        >
                    </button>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default {
    data: () => ({
        arrowTurned: false,
    }),
    props: {
        sliderHeading: {
            type: String,
        },
        slides: {
            type: Array,
        },
        isClosing: {
            type: Boolean,
        }
    },
    emits: ['detail-promo'],
    methods: {
        detailPromo: function(id) {
            this.$emit('detail-promo', id);
        },
        startFirstItem: function() {
            this.$emit('detail-promo', 0);
            this.rotateArrow(true);
        },
        rotateArrow: function(isOpening) {
            let arrow = this.$refs.promoSliderArrow,
                rotateDegrees = isOpening ? 270 : 90,
                degreesCounter = arrow.style.transform === '' ? 0 : Number(arrow.style.transform.replace(/\D/g, ''));

            degreesCounter += rotateDegrees;
            arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';

            isOpening ? this.arrowTurned = true : this.arrowTurned = false;
        },
    },
    watch: {
        isClosing(value) {
            if (value && this.arrowTurned) {
                this.rotateArrow(false);
            }
        }
    },
    components: {
        Carousel,
        Slide,
    },
}
</script>