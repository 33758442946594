<template>
  <section class="preloader">
    <div class="preloader__content">
      <svg class="preloader__img-cup" width="96" height="110" viewBox="0 0 96 110" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="preloader__img-cup-tea" d="M72.3211 71.1165H10.3184V99.767H72.3211V71.1165Z" fill="#D16E07" transform="scale(1, 0)"/>
        <path d="M95.6774 66.1096H85.5469V76.1234H95.6774V66.1096Z" fill="#1E1E1E"/>
        <path d="M16.6025 38.9425C16.6025 25.4981 30.0161 25.1272 30.0161 19.0077C30.0161 14.4644 22.2306 10.6628 19.4166 8.53027L25.9827 0C33.2054 5.37778 40.8033 9.08659 40.8033 19.0077C40.8033 32.4521 27.5773 32.6375 27.5773 38.7571C27.5773 43.3004 35.1752 47.1019 37.9893 49.2345L31.4232 57.9502C24.2943 52.5724 16.6025 48.8636 16.6025 38.9425Z" fill="#1E1E1E"/>
        <path d="M40.8975 38.9425C40.8975 25.4981 54.3111 25.1272 54.3111 19.0077C54.3111 14.4644 46.5255 10.6628 43.7115 8.53027L50.2776 0C57.5003 5.37778 65.0982 9.08659 65.0982 19.0077C65.0982 32.4521 51.8722 32.6375 51.8722 38.7571C51.8722 43.3004 59.4701 47.1019 62.2842 49.2345L55.7181 57.9502C48.4954 52.5724 40.8975 48.8636 40.8975 38.9425Z" fill="#1E1E1E"/>
        <path d="M0 77.2357V60.8242H11.0686V66.3874H71.6643V60.8242H82.7328V77.2357C82.7328 95.3162 67.9122 109.966 49.6209 109.966H33.0181C14.8206 109.966 0 95.3162 0 77.2357ZM49.6209 99.1177C61.8151 99.1177 71.6643 89.1966 71.6643 77.2357H11.0686C11.0686 89.1966 20.9177 99.1177 33.1119 99.1177H49.6209Z" fill="#1E1E1E"/>
      </svg>

      <img class="preloader__img-company" src="/img/preloader-company.svg" alt="Булочная «Все Дома»" width="284" height="101">
    </div>
  </section>
</template>
<script>

export default {
  data: () => ({
    
  }),
}
</script>