<template>
    <Header
        :pageName="pageName"
        :isIndex="isIndex"
        :returnLink="returnLink"
    ></Header>

    <main
        class="main main--profile"
        :class="editProfile ? ' main--profile-edit' : ''"
    >
        <section class="profile">
        <div class="app-container profile__container">
            <div class="profile__head">
            <div class="profile__avatar">
                <img
                class="profile__avatar-img"
                v-if="userImage.length"
                :src="userImage"
                :alt="userName"
                >
            </div>

            <h1 class="profile__name">{{ userName }}</h1>
            <span class="profile__phone">+7 {{ phoneFormatted }}</span>
            <button class="profile__change-btn btn btn--accent" @click="editProfile = !editProfile">Изменить профиль</button>
            </div>

            <div class="profile__body">
            <ul class="profile__menu">
                <li
                class="profile__menu-item"
                v-for="(item, key) in userMenu"
                :key="key"
                >
                <button
                    class="profile__menu-link"
                    v-if="typeof item.active == 'boolean'"
                    @click="toggleActive($event, item)"
                >
                    {{ item.name }}
                    <span class="profile__menu-link-arrow" :class="'profile__menu-link-arrow--' + key"></span>
                </button>

                <router-link
                    class="profile__menu-link"
                    v-if="item.link.length"
                    :to="item.link"
                >
                    {{ item.name }}
                    <span class="profile__menu-link-arrow"></span>
                </router-link>
                </li>
            </ul>

            <router-link class="profile__logout btn btn--non-target" to="/">Выйти из аккаунта</router-link>

            <div class="profile__dev">
                <p class="profile__dev-info profile__dev-info--left">
                App version<br>
                1.0
                </p>

                <p class="profile__dev-info profile__dev-info--right">
                Designed by<br>
                Egor Chumakov
                </p>
            </div>
            </div>
        </div>
        </section>
    </main>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--edit-profile"
        :class="window.ratio < 2 ? 'detail-product-slider--small-screen' : ''"
        v-model="editProfile"
        contents-height="90vh"
        border-top-radius="30px"
        ref="editProfileModal"
    >
        <div class="app-container profile-modal profile-modal--edit">
            <div class="profile-modal__head">
                <h2 class="profile-modal__head-heading">Изменить профиль</h2>
            </div>

            <div class="profile-modal__body">
                <UserForm
                    :formClass="editFormClass"
                    :userPhone="userPhone"
                    :userName="userName"
                    :formFields="profileFormFields"
                    :showReturnBtn="showReturnBtn"
                    :submitBtnIsAccent="submitBtnIsAccent"
                ></UserForm>
            </div>
        </div>
    </swipe-modal>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--review"
        :class="window.ratio < 2 ? 'detail-product-slider--small-screen' : ''"
        v-model="userMenu.review.active"
        contents-height="90vh"
        border-top-radius="30px"
        ref="addReviewModal"
    >
        <div class="app-container profile-modal profile-modal--review">
            <div class="profile-modal__head">
                <h2 class="profile-modal__head-heading">Отзыв</h2>

                <div class="profile-modal__head-text-wrapper">
                    <p>
                        Отправьте нам отзыв, сообщение<br>
                        или предложение
                    </p>
                </div>
            </div>

            <div class="profile-modal__body">
                <ReviewForm
                :formClass="editFormClass"
                :formFields="reviewFormFields"
                :userPhone="userPhone"
                ></ReviewForm>
            </div>
        </div>
    </swipe-modal>

    <Footer
        @call-map="showMap"
    ></Footer>

    <swipe-modal
        class="swipe-modal swipe-modal--full-screen swipe-modal--map"
        v-model="map"
        contents-height="90vh"
        border-top-radius="30px"
        ref="map"
    >
        <div class="app-container">
            map
        </div>
    </swipe-modal>
</template>

<script>
import Footer from '../components/app/Footer';
import Header from '../components/app/Header';
import UserForm from '@/components/app/UserForm';
import ReviewForm from '@/components/app/ReviewForm';

import {swipeModal} from '@takuma-ru/vue-swipe-modal'
import {swipeMy} from '@/swipe-my.js'

export default {
    data: () => ({
        window: {
            width: 0,
            height: 0,
            ratio: 0,
        },
        map: false,
        isIndex: false,
        pageName: '',
        returnLink: '/',
        editProfile: false,
        editFormClass: 'profile-modal__edit-form',
        userName: 'Денис',
        userPhone: '9218696580',
        // userImage: '/img/1.png',
        userImage: '',
        showReturnBtn: false,
        submitBtnIsAccent: true,
        profileFormFields: {
            avatar: {
                model: 'avatar',
                type: 'file',
                isRequired: false,
            },
            name: {
                model: 'name',
                textName: 'Имя',
                placeholder: 'Имя',
                type: 'string',
                isRequired: false,
            },
            sex: {
                model: 'sex',
                textName: 'Пол',
                // placeholder: 'Не указан',
                type: 'list',
                items: [
                    {
                        text: 'Не указан',
                        id: 'none',
                    },
                    {
                        text: 'Мужской',
                        id: 'male',
                    },
                    {
                        text: 'Женский',
                        id: 'female',
                    },
                ],
                isRequired: false,
            },
            email: {
                model: 'email',
                textName: 'Почта',
                placeholder: '...@...',
                type: 'email',
                isRequired: true,
                errorText: 'Укажите почту в\xA0верном формате: ...@...ru',
            },
            birthdate: {
                model: 'birthdate',
                textName: 'Дата рождения',
                placeholder: 'дд.мм.гггг.',
                type: 'date',
                isRequired: true,
                errorText: 'Укажите дату в\xA0верном формате: дд.мм.гггг',
            },
        },
        reviewFormFields: {
            name: {
                model: 'name',
                textName: 'Имя',
                placeholder: 'Имя',
                type: 'string',
                isRequired: false,
            },
            phone: {
                model: 'phone',
                textName: 'Телефон',
                placeholder: '+7 ... ... .. ..',
                type: 'phone',
                isRequired: true,
                errorText: 'Укажите телефон в\xA0верном формате: +7 ... ... .. ..',
            },
            message: {
                model: 'message',
                textName: 'Ваше сообщение',
                placeholder: 'Написать',
                type: 'textarea',
                isRequired: false,
            },
            file: {
                model: 'file',
                type: 'file',
                placeholder: 'Прикрепить файл',
                isRequired: false,
            },
        },
        userMenu: {
            review: {
                name: 'Оставить отзыв',
                link: '',
                active: false,
            },
            policy: {
                name: 'Конфиденциальность',
                link: '#',
            },
            notifications: {
                name: 'Уведомления и разрешения',
                link: '#',
            },
            regulations: {
                name: 'Регламент',
                link: '#',
            },
            rate: {
                name: 'Оценить приложение',
                link: '#',
            },
        },
    }),
    watch: {
        map: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.map.$el);
        },
        editProfile: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.editProfileModal.$el);
        },
        reviewActive: function(value) {
            this.controlModalBackdropOpacity(value, this.$refs.addReviewModal.$el, 'review');
        },
    },
    computed: {
        reviewActive() {
            return this.userMenu.review.active;
        },
        phoneFormatted() {
            return this.formatPhone(this.userPhone);
        },
    },
    methods: {
        showMap: function() {
            this.map = true;
        },
        controlModalBackdropOpacity: function(isOn, ref, className = false) {
            if (className) {
                //через $refs не дотянуться, как я понял, из-за того, что надо ref внутри v-for назначать, с этим какие-то проблемы     
                let arrow = document.querySelector('.profile__menu-link-arrow--' + className),
                    rotateDegrees = isOn ? 270 : 90,
                    degreesCounter = arrow.style.transform === '' ? 0 : Number(arrow.style.transform.replace(/\D/g, ''));

                degreesCounter += rotateDegrees;
                arrow.style.transform = 'rotate(' + degreesCounter + 'deg)';
            }

            if (isOn) {
                let modalWindow = ref;

                this.$nextTick(() => {
                    let modalBlock = modalWindow.querySelector('.modal-contents'),
                        modalBlockHeight = modalBlock.getBoundingClientRect().height,
                        pageContent = document.querySelectorAll('header, main > *, footer');

                    let observer = new MutationObserver((mutations) => {
                    mutations.forEach((mutation) => {
                        let modalBlockBottom = Math.abs(Number((mutation.target.style.bottom).slice(0, -2))),
                            backdropOpacity = 1 - (modalBlockHeight - modalBlockBottom) / modalBlockHeight;

                        if (pageContent != undefined) {
                            for (let element in Object.entries(pageContent)) {
                                pageContent[element].style.opacity = backdropOpacity;
                            }
                        }
                    });
                    });

                    let observerConfig = {
                        attributes: true,
                        childList: false,
                        characterData: false,
                        attributeOldValue: true
                    };

                    observer.observe(modalBlock, observerConfig);
                });
            }
        },
        formatPhone: function(phone) {
            let phoneRaw = phone,
                formatted = '',
                chunkSize = 3,
                chunks = 4;

            for (chunks; chunks > 0; chunks--) {
                if (chunks <= 2)
                    chunkSize = 2;

                formatted += phoneRaw.substring(chunkSize, 0) + '  ';
                phoneRaw = phoneRaw.substring(phoneRaw.length, chunkSize);
            }
            return formatted;
        },
        toggleActive: function(event, item) {
            if (item.active) {
                item.active = false;
            } else if (!item.active) {
                item.active = true;
            }
        },
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.window.ratio = this.window.height / this.window.width;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    components: { Header, Footer, swipeModal, swipeMy, UserForm, ReviewForm, ReviewForm }
}
</script>

