<template>
    <div
        class="btn-menu"
        @touchstart="followFingerStart"
        @touchmove="followFinger($event)"
        v-touch:swipe="swipeHandler"
        ref="btnMenu"
    >
        <ul
            class="btn-menu__menu"
            :class="additionalClass"
            @touchstart="stoppag"
        >
            <li
                class="btn-menu__item"
                v-for="(item, index) in items"
                :key="item.id"
            >
                <input
                    class="btn-menu__item-radio hidden-input"
                    type="radio"
                    name="btnMenu"
                    :value="item.id"
                    :id="item.id"
                    :checked="currentFilter === item.id ? true : (index === 0 ? true : false)"
                    @change="moveIndicator($event, item.id)"
                >
                <label
                    class="btn-menu__btn"
                    :for="item.id"
                >
                    <span class="btn-menu__btn-text">{{ item.text }}</span>
                </label>
            </li>
        </ul>

        <div class="btn-menu__indicator"></div>
    </div>
</template>
<script>
export default {
    data: () => ({
        elementToClick: false,
        lastMoveSuccessfull: false,
    }),
    props: {
        additionalClass: {
            type: String
        },
        currentFilter: {
            type: String
        },
        items: {
            type: Array
        },
    },
    methods: {
        stoppag: function(e) {
            e.stopPropagation();
        },
        moveIndicator: function(e = false, id = false) {
            let indicator = this.$el.querySelector('.btn-menu__indicator'),
                indicatorRect = indicator.getBoundingClientRect(),
                menuBlock = this.$el.querySelector('.btn-menu__menu'),
                indicatorLeft,
                checkedOptionInput,
                checkedOptionLabel,
                menuRect,
                labelRect,
                labelLeft;

            if (id) {
                this.updateFilter(id);

                checkedOptionInput = this.$el.querySelector('.btn-menu__item-radio[id="' + id + '"]');
                checkedOptionLabel = checkedOptionInput.parentNode.querySelector('.btn-menu__btn');
            } else {
                checkedOptionInput = this.$el.querySelector('.btn-menu__item-radio:checked');
                checkedOptionLabel = checkedOptionInput.parentNode.querySelector('.btn-menu__btn');
            }

            menuRect = menuBlock.getBoundingClientRect();
            labelRect = checkedOptionLabel.getBoundingClientRect();
            labelLeft = labelRect.left - menuRect.left;
            indicatorLeft = indicatorRect.left - menuRect.left;

            indicator.animate([
                {
                    left: indicatorLeft + 'px',
                    width: indicatorRect.width + 'px',
                    height: indicatorRect.height + 'px',
                },
                {
                    left: labelLeft + 'px',
                    width: labelRect.width + 'px',
                    height: labelRect.height + 'px',
                },
            ], {
                duration: 200,
                easing: 'ease-in-out',
                iterations: 1,
                fill: 'forwards',
            });
        },
        followFinger: function(e) {
            let clientX = e.clientX || e.changedTouches[0].clientX,
                clientY = e.clientY || e.changedTouches[0].clientY,
                currentElement = document.elementFromPoint(clientX, clientY),
                input,
                element;

            if (currentElement.classList.contains('btn-menu__btn') || currentElement.parentNode.classList.contains('btn-menu__btn')) {
                if (currentElement.parentNode.classList.contains('btn-menu__btn')) {
                    this.elementToClick = currentElement.parentNode;
                } else {
                    this.elementToClick = currentElement;
                }

                input = this.elementToClick.previousElementSibling;
            }

            if (typeof this.elementToClick !== 'boolean' && typeof input === 'object' && !input.checked) {
                element = this.elementToClick;
                element.click();
                this.elementToClick = false;
                this.lastMoveSuccessfull = true;
            }
        },
        followFingerStart: function() {
            this.lastMoveSuccessfull = false;
        },
        swipeHandler: function(e) {
            if (['left', 'right'].includes(e) && !this.lastMoveSuccessfull) {;
                let menu = this.$refs.btnMenu,
                    allOptions = menu.querySelectorAll('input[type="radio"]'),
                    checkedOption = menu.querySelector('input[type="radio"]:checked'),
                    newOption,
                    newIndex;

                if (e === 'right') {
                    newIndex = +1;
                } else {
                    newIndex = -1;
                }

                for (let i = 0; i < allOptions.length; i++) {
                    if (allOptions[i] == checkedOption) {
                        newOption = allOptions[i+newIndex];
                        break;
                    }
                }

                if (newOption !== undefined) {
                    newOption.nextElementSibling.click();
                }
            }
        },
        updateFilter: function(id) {
            this.$emit('set-child-data', id);
        },
    },
    emits: ['set-child-data'],
    mounted() {
        this.moveIndicator();
    },
}
</script>