<template>
  <section class="template intro">
    <div class="app-container">
      <div class="intro__content intro__content--no-pb">
        <img class="intro__head-logo" src="/img/logo-intro.svg" alt="Логотип «Все Дома»" width="317" height="91">

        <h2 class="intro__heading">Добро пожаловать!</h2>

        <div class="intro__instructions">
          <p>
            Авторизуйтесь<br> 
            в нашем приложении,<br> 
            чтобы участвовать в акциях<br> 
            и пользоваться скидками!
          </p>
          <p>
            Укажите номер мобильного телефон и мы<br> 
            пришлем вам СМС с кодом подтверждения.
          </p>
        </div>

        <div class="intro__reg-form">
          <label class="intro__reg-form-phone-label" for="phone">Номер мобильного телефона</label>
          <div class="intro__reg-form-phone-wrapper" data-before="+7">
            <input
              class="intro__reg-form-phone-input" 
              type="tel"  
              v-mask="'###  ###  ##  ##'" 
              id="phone" 
              placeholder="...  ...  ..  .."
              @focus="addActiveClass"
              @blur="checkActiveClass"
              v-model="phone"
            >
          </div>

          <input class="intro__reg-form-agreement-input hidden-input" type="checkbox" name="AGREEMENT" id="agreement" v-model="enabled">
          <label class="intro__reg-form-agreement-label" for="agreement">Я согласен на обработку персональных данных</label>

          <router-link
            class="intro__reg-form-submit btn"
            :class="canSubmit ? '' : 'btn--disabled'"
            :disabled="!canSubmit"
            :to="canSubmit ? {path: '/intro-confirm', query: {phone: this.phone}} : ''"
          >
            Войти
          </router-link>

          <button class="intro__reg-policy-link" type="button" @click="agreement = !agreement">Подробнее о политике конфиденциальности</button>
        </div>

        <div class="intro__agreement" v-if="agreement">
          <h2 class="intro__agreement-heading">Политика конфиденциальности</h2>

          <div class="intro__agreement-content static">
            <p>
              Это простой текст на странице. Это простой текст на странице. Это простой текст на странице. Это простой текст на странице
            </p>
            <p>
              Это простой текст на странице. Это простой текст на странице. Это простой текст на странице. 
            </p>
            <p>
              <b>Этот текст выделен жирным.</b>
            </p>
            <h3>Заголовок.</h3>
            <h4>Подзаголовок.</h4>
            <p>
              Гиперссылка (<router-link to="https://vsedoma-family.ru">https://vsedoma-family.ru</router-link>).
            </p>
            <ol>
              <li>Первый элемент нумерованного списка</li>
              <li>Второй элемент нумерованного списка</li>
              <li>Третий элемент нумерованного списка</li>
            </ol>
            <ul>
              <li>Первый элемент ненумерованного списка</li>
              <li>Второй элемент ненумерованного списка</li>
              <li>Третий элемент ненумерованного списка</li>
            </ul>
            <table>
              <tbody>
                <tr>
                  <td>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa necessitatibus excepturi ratione optio, 
                    et porro illo impedit sit, sequi sunt maxime quas officia repellat, hic distinctio reiciendis. Impedit, saepe sed!
                  </td>
                  <td>Test 2</td>
                  <td>Test 3</td>
                </tr>
                <tr>
                  <td>Test 1</td>
                  <td>Test 2</td>
                  <td>Test 3</td>
                </tr>
                <tr>
                  <td>Test 1</td>
                  <td>Test 2</td>
                  <td>Test 3</td>
                </tr>
                <tr>
                  <td>Test 1</td>
                  <td>Test 2</td>
                  <td>Test 3</td>
                </tr>
                <tr>
                  <td>Test 1</td>
                  <td>Test 2</td>
                  <td>Test 3</td>
                </tr>
              </tbody>
            </table>
          </div>

          <button class="intro__agreement-close btn" @click="agreement = !agreement">Вернуться назад</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mask} from 'vue-the-mask';

export default {
  data: () => ({
    enabled: false,
    phone: '',
    agreement: false,
  }),
  directives: {mask},
  methods: {
    addActiveClass(e) {
      let parentBlock = e.target.parentElement;

      parentBlock.classList.add('intro__reg-form-phone-wrapper--active');
    },
    checkActiveClass(e) {
      let parentBlock = e.target.parentElement,
          valueLength = e.target.value.replace(/ /g, '').length;

      if (valueLength < 10)
        parentBlock.classList.remove('intro__reg-form-phone-wrapper--active');
    },
  },
  computed: {
    canSubmit: function(){
      return this.enabled && this.phone && this.phone.replace(/ /g, '').length == 10;
    }
  }
}
</script>