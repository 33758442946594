import Main from './views/Main.vue'
import Intro from './views/Intro.vue'
import IntroConfirm from './views/IntroConfirm.vue'
import IntroFinish from './views/IntroFinish.vue'
import IntroReg from './views/IntroReg.vue'
import Catalog from './views/Catalog.vue'
import CatalogSection from './views/CatalogSection.vue'
import Profile from './views/Profile.vue'
import Activity from './views/Activity.vue'
import Notfound from './views/Notfound.vue'
import Routes from './views/Routes.vue'
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import store from './store'


export default new createRouter({
    mode: 'history',
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
    //base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: Main,
            name:'main',
        },
        {
            path: '/intro',
            component: Intro,
            name:'intro',
        },
        {
            path: '/intro-confirm',
            component: IntroConfirm,
            name:'intro-confirm',
        },
        {
            path: '/intro-reg',
            component: IntroReg,
            name:'intro-reg',
        },
        {
            path: '/intro-finish',
            component: IntroFinish,
            name:'intro-finish',
        },
        {
            path: '/catalog',
            component: Catalog,
            name:'catalog',
        },
        {
            path: '/catalog-section',
            component: CatalogSection,
            name:'catalog-section',
        },
        {
            path: '/profile',
            component: Profile,
            name:'profile',
        },
        {
            path: '/activity',
            component: Activity,
            name:'activity',
        },
        {
            path: '/routes',
            component: Routes,
            name:'routes',
        },
        {
            path: '/:pathMatch(.*)*',
            component: Notfound,
            name:'notfound',
            meta: {layout: 'page', title: 'Ошибка'}
        }
    ]
})