<template>
    <div class="slider slider--fav">
        <div class="app-container">
            <h2 class="slider__heading" v-if="sliderHeading">
                <router-link
                    v-if="sliderHeadingLink"
                    class="slider__heading-link"
                    :to="sliderHeadingLink"
                >
                    {{ sliderHeading }}
                    <span class="slider__heading-arrow"></span>
                </router-link>
                <span v-else>
                    {{ sliderHeading }}
                </span>

                <span v-else class="slider__heading-arrow"></span>
            </h2>
        </div>

        <div class="slider__wrapper">
            <Carousel
                :wrapAround="true"
                :itemsToShow="1.355"
                snapAlign="center-odd"
                :touchDrag="false"
                :mouseDrag="false"
            >
                <Slide
                    key="0"
                >
                    <div
                        class="slider__fav-tab"
                        :class="cardSwapped ? 'slider__fav-tab--swapped' : ''"
                    >
                        <div class="slider__fav-tab-content">
                            <div class="slider__fav-tab-text">
                                <p class="slider__fav-tab-text-top">
                                    <span class="slider__fav-tab-text-top-left">Сумма покупок за 5 дней</span>

                                    <span class="slider__fav-tab-text-top-right">20%</span>
                                </p>

                                <p class="slider__fav-tab-text-middle">
                                    <span class="slider__fav-tab-text-middle-current">290 руб</span>
                                    <span class="slider__fav-tab-text-middle-divider">/</span>
                                    <span class="slider__fav-tab-text-middle-total">500 руб</span>
                                </p>
                            </div>
                            <button class="slider__fav-tab-btn btn btn--accent" @click="swapCard">Условия</button>
                        </div>

                        <div class="slider__fav-tab-swapped">
                            <div class="slider__fav-tab-swapped-text">
                                <p class="slider__fav-tab-swapped-text-top">
                                    Вы сможете выбрать любимый продукт,<br>
                                    когда совершите покупки на общую сумму<br>
                                    500 руб в течение 5 дней и покупать его со скидкой 20%<br>
                                </p>

                                <p class="slider__fav-tab-swapped-text-bottom">
                                    * Cтоимость купленного кофе в сумме не учитывается
                                </p>
                            </div>

                            <button class="slider__fav-tab-swap-btn btn" @click="swapCard">Вернуться назад</button>
                        </div>
                    </div>
                </Slide>

                <Slide
                    key="1"
                >
                    <div class="slider__fav-tab">
                        <div class="slider__fav-tab-content">
                            <div class="slider__fav-tab-text">
                                <p class="slider__fav-tab-text-top">
                                    <span class="slider__fav-tab-text-top-left">Сумма покупок за 5 дней</span>
                                    
                                    <span class="slider__fav-tab-text-top-right">20%</span>
                                </p>

                                <p class="slider__fav-tab-text-middle">
                                    <span class="slider__fav-tab-text-middle-current">500 руб</span>
                                    <span class="slider__fav-tab-text-middle-divider">/</span>
                                    <span class="slider__fav-tab-text-middle-total">500 руб</span>
                                </p>

                                <p class="slider__fav-tab-text-bottom">
                                    Поздравляем!<br>
                                    Вам доступен выбор любимого продукта!
                                </p>
                            </div>
                            <router-link class="slider__fav-tab-btn btn btn--accent" to="#">Выбрать любимый продукт</router-link>
                        </div>
                    </div>
                </Slide>

                <Slide
                    key="2"
                >
                    <div class="slider__fav-tab">
                        <div class="slider__fav-tab-content">
                            <div class="slider__fav-tab-text">
                                <p class="slider__fav-tab-text-top">
                                    <span class="slider__fav-tab-text-top-left">Капучино большой на растительном молоке</span>
                                    
                                    <span class="slider__fav-tab-text-top-right">20%</span>
                                </p>
                            </div>
                            <router-link class="slider__fav-tab-btn btn" to="#">Поменять любимый продукт</router-link>
                        </div>
                    </div>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

export default {
    data: () => ({
        cardSwapped: false,
    }),
    props: {
        sliderHeading: {
            type: String,
        },
        sliderInitialState: {
            type: Number,
        },
        sliderHeadingLink: {
            type: String,
        },
    },
    methods: {
        swapCard(e) {
            let duration = 150,
                card = e.target.closest('.slider__fav-tab');

            card.classList.add('slider__fav-tab--animated');

            setTimeout(() => {
                this.cardSwapped = !this.cardSwapped;
                card.classList.remove('slider__fav-tab--animated');
            }, duration);
        }
    },
    components: {
        Carousel,
        Slide,
    }
}
</script>