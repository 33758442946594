<template>
  <main class="main main--intro">
    <IntroFinish></IntroFinish>
  </main>
</template>

<script>
import IntroFinish from "../components/app/IntroFinish";

export default {
  data: () => ({

  }),
  components: {IntroFinish}
}
</script>
