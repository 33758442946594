<template>
    <div
        class="swiper slider slider--detail-product detail-product-slider"
        :class="[additionalClass, window.ratio < 2 ? 'detail-product-slider--small-screen' : '']"
    >
        <div class="swiper-wrapper detail-product-slider__wrapper">
            <swiper
                :modules="modules"
                :pagination="true"
                :navigation="false"
                :loop="false"
                :autoplay="false"
                :space-between="24"
                :slides-per-view="1"
            >
                <swiper-slide v-for="slide in slides">
                    <div class="detail-product-slider__img-block">
                        <img
                            class="detail-product-slider__img"
                            :src="slide"
                        >
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';

export default {
    props: {
        additionalClass: {
            type: String,
        },
        slides: {
            type: Array,
        },
    },
    data: () => ({
        modules: [Pagination, Navigation],
        window: {
            width: 0,
            height: 0,
            ratio: 0,
        }
    }),
    methods: {
        handleResize: function() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            this.window.ratio = this.window.height / this.window.width;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    components: {
        Swiper,
        SwiperSlide
    }
}
</script>