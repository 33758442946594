<template>
  <div style="display: flex; flex-direction: column; padding: 20px; margin: auto;">
    <router-link
      v-for="route in routes"
      :to="route.link"
      style="margin-bottom: 10px; font-size: 16px;"
    >
      {{ route.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  data: () => ({
    routes : [
      {
        name: 'Страница первого входа: основная',
        link: '/intro',
      },
      {
        name: 'Страница первого входа: подтверждение номера',
        link: '/intro-confirm?phone=999++111++22++33',
      },
      {
        name: 'Страница первого входа: форма регистрации',
        link: '/intro-reg',
      },
      {
        name: 'Страница первого входа: завершение регистрации',
        link: '/intro-finish',
      },
      {
        name: 'Главная',
        link: '/',
      },
      {
        name: 'Модалка «Оцените ваше посещение»',
        link: '/?visit=%7B%22time%22%3A%2220%3A04%22%2C%22address%22%3A%22%D0%9F%D0%BB%D0%B5%D1%81%D0%B5%D1%86%D0%BA%D0%B0%D1%8F%20%D1%83%D0%BB.%20%D0%B4.10%22%7D',
      },
      {
        name: 'Каталог',
        link: '/catalog',
      },
      {
        name: 'Раздел и элемент каталога (чтобы открыть элемент - нажать на любой товар)',
        link: '/catalog-section',
      },
      {
        name: 'Профиль',
        link: '/profile',
      },
      {
        name: 'Активность и карта (чтобы открыть карту - нажать на «Адреса» в меню в подвале)',
        link: '/activity',
      },
      {
        name: '404',
        link: '/404',
      },
    ],
  }),
}
</script>

