import {createApp, defineAsyncComponent} from 'vue'
import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import router from './router'
import App from './App.vue'
import './style.css'
import '../src/assets/scss/styles.scss';
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import YmapPlugin from 'vue-yandex-maps'
import Select2 from 'vue3-select2-component'
import Vue3TouchEvents from 'vue3-touch-events'
import { createHead } from '@unhead/vue'

const head = createHead()

document.ontouchmove = function(event) {
    event.preventDefault();
}

let token = (new Date()).getTime();

if (localStorage.getItem('user-token'))
    token = localStorage.getItem('user-token');

if (token) {
    axios.defaults.headers.common['Authorization'] = token;
    axios.defaults.baseURL = 'https://hmel.alm.su';
}

document.addEventListener('deviceready', onDeviceReady, false);
function onDeviceReady() {
    let device_uuid = '';
    if (typeof device == 'object') {
        device_uuid = device.uuid;
        axios.defaults.headers.common['Authorization'] = device_uuid;
        axios.defaults.baseURL = 'https://hmel.alm.su';
    }
    const app2 = createApp(App)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
    app2.use(router)
    app2.use(store)
    app2.use(VueAxios, axios)
    const settings = {
        apiKey: '', // Индивидуальный ключ API
        lang: 'ru_RU', // Используемый язык
        coordorder: 'latlong', // Порядок задания географических координат
        debug: false, // Режим отладки
        version: '2.1' // Версия Я.Карт
    }
    app2.use(YmapPlugin, settings)
    app2.use(Vue3TouchEvents)
    app2.use(head)
    app2.component('Select2', Select2)
    app2.mount('#app')
}
//if (navigator.userAgent.indexOf("Windows") != -1)
    onDeviceReady();

import {useHead} from '@unhead/vue';
useHead({
    meta: [
        {
            name: 'viewport',
            content: 'width=430,user-scalable=no'
        },
    ]
})
